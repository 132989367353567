import React, { Component } from 'react';

class RateStar extends React.Component {
    constructor(props) {
      super(props)
      this.id = RateStar.id
      RateStar.id++
    }
  
    render() {
      const {
        size, strokeWidth, stroke, rate, frontColor, backgroundColor, ...rest
      } = this.props
      const id = `star_filter${this.id }`
   
      return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.005011 47" width={21} height={47}>
          <defs>
              <linearGradient id={id}>
                  <stop offset={`${rate}%`} stopColor={frontColor} />
                  <stop offset="0%" stopColor={backgroundColor} stopOpacity="1" />
              </linearGradient>
          </defs>
          <path fillRule="nonzero" d="m 10.501955,0.5 c -3.3018642,0 -6.0000002,2.6981362 -6.0000002,6 0,3.3018643 2.698136,6 6.0000002,6 3.301864,0 6,-2.6981357 6,-6 0,-3.3018638 -2.698136,-6 -6,-6 z m 0,2 c 2.220984,0 4,1.7790164 4,4 0,2.220984 -1.779016,4 -4,4 -2.2209842,0 -4.0000002,-1.779016 -4.0000002,-4 0,-2.2209836 1.779016,-4 4.0000002,-4 z m -4.5000002,11 c -3.024305,0 -5.49999996,2.476746 -5.49999996,5.5 v 11.3125 a 1.0001,1.0001 0 0 0 0,0.125 V 30.5 a 1.0001,1.0001 0 0 0 0.03125,0.28125 C 0.68073684,32.292924 1.9544438,33.5 3.5019548,33.5 c 0.352983,0 0.684242,-0.07324 1,-0.1875 V 43 c 0,1.921188 1.578812,3.5 3.5,3.5 0.975944,0 1.8628422,-0.409747 2.5000002,-1.0625 0.637158,0.652753 1.524056,1.0625 2.5,1.0625 1.921188,0 3.5,-1.578812 3.5,-3.5 v -9.6875 c 0.315758,0.114264 0.647017,0.1875 1,0.1875 1.591452,0 2.915789,-1.271641 3,-2.84375 a 1.0001,1.0001 0 0 0 0,-0.15625 1.0001,1.0001 0 0 0 0,-0.09375 V 19 c 0,-3.023254 -2.475695,-5.5 -5.5,-5.5 h -0.90625 -7.1875002 z m 0,2 h 0.90625 7.1875002 0.90625 c 1.939695,0 3.5,1.561254 3.5,3.5 v 11.34375 a 1.0001,1.0001 0 0 0 0,0.09375 V 30.5 a 1.0001,1.0001 0 0 0 0,0.09375 c -0.04535,0.518438 -0.466814,0.90625 -1,0.90625 -0.56503,0 -1,-0.43497 -1,-1 v -0.09375 a 1.0001,1.0001 0 0 0 0,-0.09375 V 21.5 h -2 v 8.8125 a 1.0001,1.0001 0 0 0 0,0.125 V 30.5 43 c 0,0.840812 -0.659188,1.5 -1.5,1.5 -0.788782,0 -1.425181,-0.577771 -1.5,-1.34375 -0.0049,-0.05053 0,-0.10422 0,-0.15625 V 31.5 H 9.5019548 V 43 43.15625 c -0.074819,0.765979 -0.711218,1.34375 -1.5,1.34375 -0.840812,0 -1.5,-0.659188 -1.5,-1.5 V 21.5 h -2 v 8.84375 a 1.0001,1.0001 0 0 0 0,0.09375 V 30.5 c 0,0.56503 -0.43497,1 -1,1 -0.543801,0 -0.968957,-0.40352 -1,-0.9375 a 1.0001,1.0001 0 0 0 0,-0.0625 1.0001,1.0001 0 0 0 0,-0.1875 V 19 c 0,-1.938746 1.560305,-3.5 3.5,-3.5 z"
              fill={`url(#${id})`} />
        </svg>
      )
    }
  }

  export default RateStar;
  
  
  
  