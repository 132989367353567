import axios from 'axios';

export const axiosInstance = axios.create({
   //baseURL: 'http://localhost:5000/',
   baseURL: 'https://up01hl6v14.execute-api.us-east-1.amazonaws.com/latest/',
});

export default class API {
    static getAuthStr() {
        const TOKEN = localStorage.getItem('jwtToken');
        return 'Bearer '.concat(TOKEN);
    }

    static auth = async (apiMethod, data, onSuccess, onError) => {
        try {
            const result = await axiosInstance.post(apiMethod, data);
            onSuccess(result.data);
        } catch (e) {
            onError(e);
        }
    }

    static get = async (apiMethod, onSuccess, onError) => {
        try {
            const result = await axiosInstance.get(apiMethod);
            onSuccess(result.data)
        } catch (e) {
            console.log(e);
        }
    }

    static getData = (apiMethod, callBackFunc, onError) => {
        axiosInstance.get(apiMethod, { headers: { Authorization: API.getAuthStr() } }).then(response => callBackFunc(response.data))
            .catch(error => {
                if (error.response.status === 401) {
                    onError();
                }
                console.log(`[GET DATA] URL : ${apiMethod} ;  REASON: ${error}`)
            });
    }

    static postData = (apiMethod, data, callBackFunc, callBackErrFunc) => {
        //axiosInstance.post(apiMethod, data, { headers: { Authorization: API.getAuthStr() } })
        axiosInstance.post(apiMethod, data)
            .then((response) => {
                const res = JSON.stringify(response.data);
                callBackFunc(res);
            })
            .catch(reason => {
                console.log(`[POST DATA] URL : ${apiMethod} ;  REASON: ${reason}`);
                callBackErrFunc(reason);
            });
    }

    static deleteData = (apiMethod, data, callBackFunc) => {
        axiosInstance.delete(apiMethod, data)
            .then((response) => {
                const res = JSON.stringify(response.data);
                callBackFunc(res);
            })
            .catch(reason => {
                console.log(`[DELETE DATA] URL : ${apiMethod} ;  REASON: ${reason}`);
                //callBackErrFunc(reason);
            });
    }
}