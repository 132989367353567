import React, { Component } from 'react';
import { Menu } from 'antd';
import CustomAuto from '../common/CustomAuto';

class RightMenu extends Component {
  handleClick = () => {
    this.props.click();
  }

  render() {
    const { mode } = this.props;
    return (
			<Menu style={{ float: 'left' }} mode={mode}>
          <CustomAuto click={this.handleClick} menuMode={mode} />
      </Menu>
    );
  }
}

export default RightMenu;