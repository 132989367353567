import React, { Component } from 'react';
import { Row, Col, List, Avatar, Icon, Button, Skeleton } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import renderHTML from 'react-render-html';
import PageTitle from './common/PageTitle';

import Api from '../api';

const IconText = ({ type, text }) => (
    <span>
        <Icon type={type} style={{ marginRight: 8 }} />
        {text}
    </span>
);

class Therapy extends Component {
    constructor(props) {
        super(props);
        const treatmentType = this.props.match.params.treatmentType === 'Psoriasis' ? 'Psoriasis' : 'Psoriatic Arthritis'
        this.state = {
            loading: true,
            therapies: [],
            therapyType: this.props.match.params.treatmentType,
            therapyId: this.props.match.params.treatment,
            treatmentType,
            treatment: [
                { category: 'Psoriasis', svg: 'cream.svg', type: 'Topical', description: 'Topical therapy is the most common treatment option utilized by dermatologists. Several categories of topical therapies are available that your Advanced Dermatology provider can take advantage of to treat your skin. Below is a list of several common classes of topical therapies and there uses.' },
                { category: 'Psoriasis', svg: 'sun-beams.svg', type: 'Phototherapy', description: 'Phototherapy or light therapy, involves exposing the skin to ultraviolet light on a regular basis and under medical supervision. Treatments are done in a doctor\'s office or psoriasis clinic or at home with phototherapy unit. The key to success with light therapy is consistency.' },
                { category: 'Psoriasis', svg: 'systemic.svg', type: 'Oral Systemic', description: 'Systemic medications are prescription drugs that work throughout the body. They are usually used for individuals with moderate to severe psoriasis and psoriatic arthritis. Systemic medications are also used in those who are not responsive or are unable to take topical medications or UV light therapy.' },
                { category: 'Psoriasis', svg: 'biologics.svg', type: 'Biologic', description: 'Biological products include a wide range of products such as vaccines, blood and blood components, allergenics, somatic cells, gene therapy, tissues, and recombinant therapeutic proteins. Biologics can be composed of sugars, proteins, or nucleic acids or complex combinations of these substances, or may be living entities such as cells and tissues. Biologics are isolated from a variety of natural sources - human, animal, or microorganism - and may be produced by biotechnology methods and other cutting-edge technologies. Gene-based and cellular biologics, for example, often are at the forefront of biomedical research, and may be used to treat a variety of medical conditions for which no other treatments are available.' },
                { category: 'psoriaticArthritis', svg: 'anti-inflammation.svg', type: 'Anti-inflammation', description: 'Psoriasis and psoriatic arthritis are chronic inflammatory diseases. Nonsteroidal anti-inflammatory drugs (NSAIDs) alleviate pain by counteracting the cyclooxygenase (COX) enzyme. On its own, COX enzyme synthesizes prostaglandins, creating inflammation. In whole, the NSAIDs prevent the prostaglandins from ever being synthesized, reducing or eliminating the pain.' },
                { category: 'psoriaticArthritis', svg: 'systemic.svg', type: 'Oral Systemic', description: 'Systemic medications are prescription drugs that work throughout the body. They are usually used for individuals with moderate to severe psoriasis and psoriatic arthritis. Systemic medications are also used in those who are not responsive or are unable to take topical medications or UV light therapy.' },
                { category: 'psoriaticArthritis', svg: 'biologics.svg', type: 'Biologic', description: 'Biological products include a wide range of products such as vaccines, blood and blood components, allergenics, somatic cells, gene therapy, tissues, and recombinant therapeutic proteins. Biologics can be composed of sugars, proteins, or nucleic acids or complex combinations of these substances, or may be living entities such as cells and tissues. Biologics are isolated from a variety of natural sources - human, animal, or microorganism - and may be produced by biotechnology methods and other cutting-edge technologies. Gene-based and cellular biologics, for example, often are at the forefront of biomedical research, and may be used to treat a variety of medical conditions for which no other treatments are available.' },
                { category: 'psoriaticArthritis', svg: 'more.svg', type: 'Other', description: 'Uncommon treatments for specific cases of psoriatic arthritis.' },
            ]
        }
    }
    componentDidMount() {
        Api.get(`therapy/${this.state.treatmentType}/${this.state.therapyId}`, (data) => {
            this.setState({ therapies: data, loading: false });
        });
    }

    renderText = (text) => renderHTML(draftToHtml(JSON.parse(text)))

    renderHeader = () => {
        console.log('item', this.state);
        const item = this.state.treatment.find(x=> x.category === this.props.match.params.treatmentType && x.type === this.props.match.params.treatment );
        console.log('item', item);
        return (
            <div className="container800 center">
                <Row>
                    
                    <Col >
                    <div className="homeIconContainer">
                                <img className='mb-0' alt='Treatment Education' src={require("../icons/" + item.svg)}/>
                            </div>
                        <h1 className="pageTitle daInfoBorder mt-1">{item.type === 'Oral Systemic' ? 'Oral/Systemics' : item.type}</h1>
                    </Col>
                    <p>{item.description}</p>
                </Row>
            </div>
        )
    }

    render() {
        const treatment = this.props.match.params.treatment === 'Oral Systemic' ? 'Oral/Systemics' : this.props.match.params.treatment;
        return (
            <div className="center treatmentList">
            <PageTitle title={'TREATMENT EDUCATION'} subTitle={`${this.state.treatmentType} ${treatment}`} />
            <Row>
                <Col>
                <Button size={'large'} onClick={() => this.props.history.push(`/TreatmentEdu`)}>Back to Treatment Edu.</Button>
                </Col>
            </Row>
            
                {this.renderHeader()}
                    <Col >
                        <List
                            className={'striped'}
                            loading={this.state.loading}
                            itemLayout="vertical"
                            size="small"
                            pagination={{
                                onChange: (page) => {
                                    console.log(page);
                                },
                                pageSize: 100,
                                hideOnSinglePage: true
                            }}
                            //dataSource={listData}
                            dataSource={this.state.therapies}
                            footer={<div></div>}
                            renderItem={item => (
                                
                                <List.Item
                                    key={item.title}
                                    className={'p-1'}
                                    actions={[
                                        <Button size={'large'} onClick={() => this.props.history.push(`/Treatment/${item.Slug}`)} type='primary'>View</Button>,
                                        //<IconText type="like-o" text="156" />, 
                                        //<IconText type="message" text="2" />
                                    ]}
                                //extra={<img width={272} alt="logo" src="https://img.medscapestatic.com/pi/features/drugdirectory/octupdate/MYN04861.jpg" />}
                                >
                                <Skeleton loading={this.state.loading} active rows={10} title>
                                    <List.Item.Meta
                                        title={<strong><Link to={`/Treatment/${item.Slug}`}>{item.DisplayName}</Link></strong>}
                                        description={this.renderText(item.WhatIsDrug)}
                                    />
                                    {item.content}
                                    </Skeleton>
                                </List.Item>
                                
                            )}
                        />
                    </Col>

            </div>
        )
    }
}

export default withRouter(Therapy);
