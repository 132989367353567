export const treatmentPreferenceData = [
    { 
        id: 'switch',
        question: 'Are you thinking about starting or switching therapies today?',
        options: [
            { value: 'Yes', text: 'Yes'},
            { value: 'No', text: 'No'},
        ],
        type: 'radio'
    },
    { 
        id: 'top3',
        question: 'At this time, what (top 3) treatments are you leaning towards for your psoriasis or psoriatic arthritis?',
        options: [
            { value: 'FREE TEXT', text: 'FREE TEXT'},
            { value: `I'm not sure`, text: `I'm not sure`},
            { value: 'Not answered', text: 'Not answered'}
        ],
        type: 'free'
    },
    { 
        id: 'howFar',
        question: 'How far along are you with this decision?',
        options: [
            { value: 'Not yet thought about all the options', text: 'Not yet thought about all the options'},
            { value: 'Considering the different options', text: 'Considering the different options'},
            { value: 'Close to choosing an option', text: 'Close to choosing an option'},
            { value: 'Starting or switching a therapy', text: 'Starting or switching a therapy'},
            { value: 'Not answered', text: 'Not answered'},
        ],
        type: 'radio'
    },
    { 
        id: 'DAHelpful',
        question: 'Was completing the shared decision tool questionnaire helpful for you (either: coming up with questions to discuss with your doctor or helping you to make a decision)?',
        options: [
            { value: 'Yes', text: 'Yes'},
            { value: 'No', text: 'No'},
        ],
        type: 'radio'
    },
    { 
        id: 'websiteHelpful',
        question: 'Was the website content helpful?',
        options: [
            { value: 'Yes', text: 'Yes'},
            { value: 'No', text: 'No'},
        ],
        type: 'radio'
    },
];

export const otherData = [
    { 
        question: 'Concerns or Questions',
        options: ''
    }
];

export const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

export const scale = [...Array(11).keys()];

export const valuesData = () => {
 const scale = [...Array(11).keys()];
 return  [  
    { 
        id: 'jointPain',
        question: 'Managing your Joint Pain?',
        options: scale
    },
    { 
        id: 'psoriasis',
        question: 'Decrease psoriasis skin affected areas?',
        options: scale
    },
    { 
        id: 'itch',
        question: 'Managing itch?',
        options: scale
    },
    { 
        id: 'flare',
        question: 'Decrease flares?',
        options: scale
    },
    { 
        id: 'activity',
        question: 'Increase mobility and physical activity?',
        options: scale
    },
    { 
        id: 'qol',
        question: 'Improve quality of life or lifestyle?',
        options: scale
    },
    { 
        id: 'fatigue',
        question: 'Managing your fatigue?',
        options: scale
    },
    { 
        id: 'workHome',
        question: 'Function better at work or at home?',
        options: scale
    },
];
} 