import React, { Component } from 'react';
import { Row, Col } from 'antd';
import PageTitle from './common/PageTitle';
import BreadcrumbMaker from './common/BreadcrumbMaker';

class PracticalTips extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    renderBreadcrumbs = () => {
        const arr = [
            { route: '/', name: 'Home' },
            //{ route: '', name: 'Learn More' },
            { route: '', name: 'Practical Tips' }
        ];
        return BreadcrumbMaker(arr);
    }
    render() {
        return (
            <div >
                {this.renderBreadcrumbs()}
                <PageTitle title={'Practical Tips'} subTitle={``} />
                {/* <div  className="container800 center mt-2"> */}
                <div className="tipsIntro ">
                <div className='abs'>
                    <div className='tipIntroImgWrapper'>
                    <img className=' mb-0 tipIntroImg' alt='Practical Tips Intro' src={require("../icons/practicalTips/tipInfo.svg")}/>

                    </div>
                    </div>
                    <div className="container800 center pt-2 tipsIntroText">
                        <Row>
                            <Col>
                                <h2>SEEKING PROFESSIONAL HELP</h2>
                                <h2>Finding a provider and scheduling an appointment</h2>
                                <p>Generally, most health coverage plans give you a better rate if you select an “in-network” provider. This means the health care provider has a contract with your health plan. While you may be able to see other providers outside of your plan, you will be able to see an “in-network” doctor or health care provider at a lower cost. When you are ready to make an appointment with a health care provider, please follow these simple steps:</p>
                                <ul>
                                    <li>Look at your health plan’s provider directory. The provider directory is a list of all of the health care providers and hospitals that have “coverage” or who have contracts under your specific health plan.</li>
                                    <li>You can find information about a provider directory or a specific provider by calling your health plan’s customer service number (generally located on the back of your health plan card) or by visiting the health plan’s website.</li>
                                    <li>When you call to schedule a doctor’s appointment, tell them you would like to schedule a new patient appointment. At this time, the office may also confirm your health plan coverage and let you know if it is currently accepted. After it is verified, you may have to wait some time to get an appointment to see someone. However, if you are not doing well, you may be able to see someone sooner.</li>
                                    <li>Finally, ask if you need to bring anything to your appointment, for example, medical records, any current medications, or recent lab work.</li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </div>

                <Row>
                <Col className={'mb-md-1'} xs={{ span: 24, offset: 0}} sm={{ span: 24, offset: 0}} md={{ span: 3, offset: 0}} lg={{ span: 3, offset: 0}} xl={{ span: 2, offset: 0}} xxl={{ span: 1, offset: 0}}>
                </Col>
                <Col className={'mb-md-1 mt-2 tipsBottom'} xs={{ span: 24, offset: 0}} sm={{ span: 24, offset: 0}} md={{ span: 15, offset: 0}} lg={{ span: 15, offset: 0}} >
                    <Row>
                        <Col>
                        <h2 className='text-center'>How to Give a Subcutaneous Injection</h2>
                        <div className="tip">
                            
                            <h3><img className='mb-0 number' alt='Part one' src={require("../icons/practicalTips/number-1.svg")}/>Before the Injection</h3>
                            <p>Some medications such as Enbrel®  must be injected. These medications come with a kit and a device to inject the medication. Some patients however prefer not to use the kit but to do the injection themselves; much like an insulin injection would be done.</p>
                            <ol>
                                <li>If your medication is refrigerated, remove the vials from the refrigerator 30 minutes before administration so it can reach room temperature. (You must not warm the syringe in any other way.)</li>
                                <li>Check the expiration date on the syringe carton and label. If the expiration date has passed, you must not use the syringe. If the tamper evident seals are missing or broken, your patient should contact a pharmacist.</li>
                                <li>Select a clean, dry, flat work area.</li>
                                <li>Gather your supplies:<br />
                                    – Medication<br />
                                    – Alcohol pads or cotton balls and bottle of rubbing alcohol<br />
                                    – Puncture-proof disposal container*<br />
                                    – Bandaids
                                </li>
                                <li>Wash your hands well (with soap and warm water) before preparing your medication.</li>
                            </ol>
                            <p><img className='mb-0' alt='Light bulb' width='50px' src={require("../icons/practicalTips/tips-light-bulb.svg")}/><em>*TIP: </em> The container can be a coffee can or leak-proof, closable milk jug. Find out the proper way to dispose of biological waste in your area.</p>
                        </div>
                        <div className="tip tip2">
                            
                            <h3><img className='mb-0 number' alt='Part one' src={require("../icons/practicalTips/number-2.svg")}/>Preparing the Injection</h3>
                            <p>Your doctor and/or clinic nurse will instruct you on preparing your particular dose of medication.</p>
                        </div>
                        <div className="tip">
                            <div className='mt-2'></div>
                            
                            <h3><img className='mb-0 number' alt='Part one' src={require("../icons/practicalTips/number-3.svg")}/>Giving Yourself the Injection</h3>
                            <div className='text-center'>
                            <img className='mb-0 injectIcons' alt='Select Injection Site' width='160px' src={require("../icons/practicalTips/select-injection-site-with-text.svg")}/>
                            <img className='mb-0 injectIcons' alt='Hold syringe like a dart' width='160px' src={require("../icons/practicalTips/hold-syringe-like-dart-with-text.svg")}/>
                            <img className='mb-0 injectIcons' alt='Pinch 2 inches of skin' width='160px' src={require("../icons/practicalTips/pinch-2-inches-of-skin-with-text.svg")}/>
                            <img className='mb-0 injectIcons' alt='Insert syringe' width='160px' src={require("../icons/practicalTips/90-45-with-text.svg")}/>
                            <img className='mb-0 injectIcons' alt='Dispose safely' width='160px' src={require("../icons/practicalTips/dispose-of-safely-with-text.svg")}/>
                            </div>
                            <ol>
                                <li>If you are very thin do not use the abdomen as an injection site. If you choose the stomach site, avoid the 2 inches around the belly button (navel). Avoid areas where skin is tender, bruised, red or hard, or where scars or stretch marks are present.
                                    <ul>
                                        <li>outer surface of the upper arm</li>
                                        <li>top of thighs</li>
                                        <li>buttocks</li>
                                        <li>abdomen, except the navel or waistline</li>
                                    </ul></li>
                                <li>If you are very thin do not use the abdomen as an injection site. If you choose the stomach site, avoid the 2 inches around the belly button (navel). Avoid areas where skin is tender, bruised, red or hard, or where scars or stretch marks are present.</li>
                                <li>Do not use the same site for injections each time.</li>
                                <li>Rotate your injection sites in a regular pattern. You should be at least 1 inch away from the last injection site. Jot down on your calendar where you gave you last shot. This will help prevent giving the shot in the same place too soon.</li>
                                <li>Select a site and cleanse the area (about 2 inches) with a fresh alcohol pad, or cotton ball soaked in alcohol.</li>
                                <li>Wait for the site to dry.</li>
                                <li>Remove the needle cap.</li>
                                <li>Pinch a 2-inch fold of skin between your thumb and index finger.</li>
                                <li>Hold the syringe the way you would a pencil or dart. Insert the needle at a 45 to 90 degree angle to the pinched-up skin. The needle should be completely covered by skin. If you do this quickly, you will feel very little discomfort.</li>
                                <li>Hold the syringe with one hand. With the other, pull back the plunger to check for blood. If you see blood in the solution in the syringe, do not inject. Withdraw the needle and start again at a new site.</li>
                                <li>If you do not see blood, slowly push the plunger to inject the medication. Press the plunger all the way down.</li>
                                <li>Remove the needle from the skin and gently hold an alcohol pad on the injection site. Do not rub.</li>
                                <li>If there is bleeding, apply a bandage.</li>
                                <li>Immediately put the syringe and needle into the disposal container. To avoid a needle-stick injury, you must not try to recap the needle. You must not reuse any injection supplies.</li>
                            </ol>
                        </div>
                        </Col>
                        </Row>
                    </Col>
                    <Col className={'mb-md-1'} xs={{ span: 24, offset: 0}} sm={{ span: 24, offset: 0}} md={{ span: 6, offset: 0}} lg={{ span: 6, offset: 0}} >
                        <h2>&nbsp;</h2>
                        <div className='stepFour'>
                        
                        <img className=' mb-0 tipIntroImg' alt='Practical Tips Intro' src={require("../icons/practicalTips/syringe-safe-waste.svg")}/>
                        <h3><img className='mb-0 number' alt='Part four' src={require("../icons/practicalTips/number-4.svg")}/>Disposing of the syringe</h3>
                        <p>You should put used syringes with needle attached in an FDA-cleared sharps disposal container right away after use.</p>
                        <h3>You must not throw away (dispose of) loose syringes and needles in household trash.</h3>
                        <p>If you do not have an FDA-cleared sharps disposal container, you patient may use a household container that is:</p>
                        <ul>
                            <li>Made of a heavy-duty plastic</li>
                            <li>Can be closed with a tight-fitting, puncture-resistant lid without sharps being able to come out</li>
                            <li>Upright and stable during use</li>
                            <li>Leak-resistant</li>
                            <li>Properly labeled to warn of hazardous waste inside the container</li>
                        </ul>
                        <p>When your sharps disposal container is almost full, you will need to follow local community guidelines for the right way to dispose of the sharps disposal container. There may be state or local laws about how your patient should throw away used needles and syringes. For more information about safe sharps disposal and for specific information about sharps disposal in the state you live in, you can visit the FDA&#39;s website at <a href='http://www.fda.gov/safesharpsdisposal' target='_blank'>http://www.fda.gov/safesharpsdisposal</a>.</p>
                        <p><strong>You must not dispose of used sharps disposal container in the household trash unless local community guidelines permit this. You must not recycle the used sharps disposal container.</strong></p>
                        <img className=' mb-0 tipIntroImg' alt='Practical Tips Intro' src={require("../icons/practicalTips/no-trash-no-recycle.svg")}/>
                        <p>For more information on syringe disposal:<br />
                        <a href='http://www.fda.gov/safesharpsdisposal' target='_blank'>http://www.fda.gov/safesharpsdisposal</a></p>
                        </div>
                    </Col>
                </Row>
            </div>
            // </div>
        );
    }
}

export default PracticalTips;