import React from 'react';
import { Row, Col, Card, Icon, Anchor } from 'antd';
import BreadcrumbMaker from './common/BreadcrumbMaker';
import PageTitle from './common/PageTitle';

const { Link } = Anchor;
const { Meta } = Card;

const renderBreadcrumbs = () => {
    const arr = [
        { route: '/', name: 'Home' },
        //{ route: '', name: 'Learn More' },
        { route: '', name: 'Advocacy' }
    ];
    return BreadcrumbMaker(arr);
}

const advocacyGroups = [
    { id: 'npf', name: 'National Psoriasis Foundation', color: '#b1d8ee' },
    { id: 'acr', name: 'American College of Rheumatology', color: '#f37667' },
    { id: 'cj', name: 'Creaky Joints', color: '#e5e2eb' },
    { id: 'aad', name: 'American Academy of Dermatology', color: '#19acde' },
    { id: 'naf', name: 'National Arthritis Foundation', color: '#7cc06a' },
    { id: 'niams', name: 'National Institute of Arthritis and Musculoskeletal and Skin Diseases (NIAMS)', color: '#b1d8ee' },
    { id: 'tsc', name: 'TalkPsoriasis Support Community', color: '#f37667' },
];

const renderIcons = () => {
        return (
            <Row gutter={8}>
                {
                    advocacyGroups.map((group, i) => {
                        let backgroundColor = group.color;
                        return (
                            <div key={`icon${i}`}>
                                <Col key={group.id} xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 6, offset: 0 }}  >
                                    <Anchor affix={false}>
                                        <Link href={`#${group.id}`} title={
                                            <div
                                                style={{ backgroundColor, height: '100px', width: '100%', whiteSpace: 'normal',display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}
                                                
                                                className={'iconCard text-center'}
                                            >
                                                <p className={''} style={{ display: 'inline-block', verticalAlign: 'middle', margin: 0 }}>{group.name}</p>
                                            </div>}>
                                        </Link>
                                    </Anchor>
                                </Col>
                            </div>
                        )
                    })
                }
            </Row>
        )
    
}

const Advocacy = () => {
    return (
        <div >
            {renderBreadcrumbs()}
            <PageTitle title={'Advocacy'} subTitle={``} />
            {renderIcons()}
            <div  className="container800 center">
            
            <Row>
                <Col >
                    <h2 className='mt-1'>National Resources</h2>
                    <p>The following advocacy groups have information for people that are connected by a psoriasis and/or psoriatic arthritis diagnosis. These listings provide education, support, and also build a community through a shared experience.</p>
                    <Card id='npf' hoverable bordered={false}  className="mt-1">
                        <h3 >National Psoriasis Foundation</h3>
                        <img className='mb-1' alt='National Psoriasis Foundation' width='200px' src={require("../images/advocacy/national-psoriasis-foundation-logo.png")} />
                        <p>
                            <Icon type="link" />: <a href="https://www.psoriasis.org" target="_blank">www.psoriasis.org</a><br />
                            <Icon type="home" />: Suite 300 6600 SW 92nd Avenue, Portland, OR 97223<br />
                            <Icon type="phone" />: 1-800-723-9166 (free call)<br />
                            <Icon type="mail" />: education@psoriasis.org<br />
                            Text: 503-410-7766<br />
                            Monday-Friday, 10:30am – 7:30pm EST<br />
                            7:30am-4:30pm PST
                    </p>
                    </Card>
                    <Card id='acr' hoverable bordered={false} className="mt-1">
                    <h3 >American College of Rheumatology</h3>
                        <img className='mb-1' alt='American College of Rheumatology' width='200px' src={require("../images/advocacy/acr-logo.png")} />
                        <p>
                            The American College of Rheumatology (ACR) offers a variety of trusted educational information about arthritis and other rheumatic diseases for patients and caregivers.<br />
                            The ACR is unable to respond to individual questions about treatment, proposed treatment, conditions or problems, fees, diagnoses, or requests for research. If you need a referral to a rheumatologist or want information about a variety of rheumatic diseases and treatment procedures, visit our Patients and Caregivers section or search our Find a Rheumatologist directory.
                    </p>
                        <p>
                            <Icon type="link" />: <a rel="noopener noreferrer" href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Diseases-Conditions/Psoriatic-Arthritis" target="_blank">https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Diseases-Conditions/Psoriatic-Arthritis</a>
                        </p>
                    </Card>
                    <Card id='cj' hoverable bordered={false} className="mt-1">
                    <h3 >Creaky Joints</h3>
                        <img className='mb-1' alt='Creaky Joints' width='200px' src={require("../images/advocacy/creaky-joints-logo.webp")} />
                        <p>
                            Online resource for arthritis information, education, research and advocacy
                    </p>
                        <p>
                            <Icon type="link" />: <a rel="noopener noreferrer" href="https://www.creakyjoints.org" target="_blank">www.creakyjoints.org</a><br />

                            <Icon type="home" />: 515 N. Midland Ave<br />
                            Upper Nyack, NY 1096<br />
                            <Icon type="phone" />: 845-348-0400
                    </p>
                    </Card>
                    <Card id='aad' hoverable bordered={false} className="mt-1">
                    <h3 >American Academy of Dermatology</h3>
                        <img className='mb-1' alt='American Academy of Dermatology' width='300px' src={require("../images/advocacy/aard-logo.svg")} />
                        <p>
                            The American Academy of Dermatology (AAD) is the largest representative dermatology group in in the United States. Patients can find resources on skin, hair and nail care on this website.
                    </p>
                        <p>
                            <Icon type="link" />: <a rel="noopener noreferrer" href="https://www.aad.org/contact-us" target="_blank">https://www.aad.org/contact-us</a><br />
                            <Icon type="home" />: P.O. Box 1968, Des Plaines, IL 60017<br />
                            <Icon type="phone" />: AAD Public Information Center: (888) 462-DERM (3376)<br />
                            <Icon type="phone" />: AAD Member Resource Center: (866) 503-SKIN (7546)<br />
                            <Icon type="phone" />: Outside the United States: (847) 240-1280<br />
                            Fax: (847) 240-1859<br /><br />
                            Locations:<br />
                            9500 W Bryn Mawr Avenue, Ste 500,<br />
                            Rosemont, IL 60018-5216<br />
                            United States<br /><br />

                            1445 New York Avenue, NW<br />
                            Suite 800<br />
                            Washington, D.C. 20005<br />
                            United States<br />

                        </p>
                    </Card>
                    <Card id='naf' hoverable bordered={false} className="mt-1">
                    <h3 >National Arthritis Foundation</h3>
                        <img className='mb-1' alt='National Arthritis Foundation' width='200px' src={require("../images/advocacy/arthritis-foundation-logo.jpg")} />
                        <p>
                            <Icon type="link" />: <a rel="noopener noreferrer" href="https://www.arthritis.org" target="_blank">www.arthritis.org</a><br />
                            <Icon type="home" />: P.O. Box 7669, Atlanta, GA 30357-0669<br />
                            <Icon type="phone" />: 1-800-568-4045 (free call)<br />
                            Magazine: Arthritis Today
                    </p>
                    </Card>
                    <Card id='niams' hoverable bordered={false} className="mt-1">
                    <h3 >National Institute of Arthritis and Musculoskeletal and Skin Diseases (NIAMS)</h3>
                        <img className='mb-1' alt='National Institute of Arthritis and Musculoskeletal and Skin Diseases (NIAMS)' width='200px' src={require("../images/advocacy/niams-logo.png")} />

                        <p>
                            <Icon type="home" />: National Institutes of Health (NIH) 1 AMS Circle, Bethesda, MD 20892-3675<br />
                            <Icon type="phone" />: 1-877-226-4267 (free call)<br />
                            <Icon type="link" />: <a rel="noopener noreferrer" href="https://www.niams.nih.gov" target="_blank">www.niams.nih.gov</a>
                        </p>
                    </Card>

                    <Card id='tsc' hoverable bordered={false} className="mt-1">
                    <h3 >TalkPsoriasis Support Community</h3>
                        <img className='mb-1' alt='TalkPsoriasis Support Community' width='200px' src={require("../images/advocacy/national-psoriasis-foundation-logo.png")} />
                        <p>
                            Online support community for those impacted by psoriasis or psoriatic arthritis. This community brings together the National Psoriasis Foundation in partnership with Inspire, an online community and health clearinghouse.<br />
                        </p>
                        <p>
                            <Icon type="link" />: <a rel="noopener noreferrer" href="https://www.psoriasis.org/talk-psoriasis" target="_blank">https://www.psoriasis.org/talk-psoriasis</a>
                        </p>
                    </Card>
                </Col>
            </Row>
            </div>
        </div>

    )
}

export default Advocacy;
