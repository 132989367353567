import React, { Component } from 'react';
import { Row, Col, List, Icon, Button, Collapse, Anchor, Card, Skeleton } from 'antd';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import renderHTML from 'react-render-html';
import PersonSvg from '../icons/person.svg';
import PersonSvg2 from '../icons/personOutline.svg';
import PersonSvg3 from '../icons/person-fill.svg';
import { ReactComponent as Person } from '../icons/person-fill.svg';
import Api from '../api';
import RateStar from './common/RateStar';
import PageTitle from './common/PageTitle';

const reactStringReplace = require('react-string-replace');
const queryString = require('query-string');
const Panel = Collapse.Panel;
const { Link } = Anchor;
const { Meta } = Card;

const IconText = ({ type, text }) => (
    <span>
        <Icon type={type} style={{ marginRight: 8 }} />
        {text}
    </span>
);

RateStar.id = 0
  
RateStar.defaultProps = {
    strokeWidth: 2,
    stroke: '#ffa200',
    rate: 0,
    backgroundColor: '#999',
    frontColor: '#ffa200',
  }


class Treatment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            therapy: '',
            treatment: this.props.match.params.treatment,
            textAreas: [
                { id: 'WhatIsDrug', section: 'ABOUT THE MEDICATION', header: 'What is [Drug]?' },
                { id: 'HowDrugWorks', section: 'ABOUT THE MEDICATION', header: 'How does it work?' },
                { id: 'HowToTake', section: 'ABOUT THE MEDICATION', header: 'How do I take this medication?' },
                { id: 'Storage', section: 'ABOUT THE MEDICATION', header: 'How do I store the medication?' },
                { id: 'HowLongUntilEffect', section: 'ABOUT THE MEDICATION', header: 'How long does it take for this medicine to start working?' },
                { id: 'DoseAndFrequency', section: 'ABOUT THE MEDICATION', header: 'What dose of medication will my physician prescribe? How often will I take it?' },
                { id: 'FDAApproval', section: 'ABOUT THE MEDICATION', header: 'Has it been approved by the U.S. Food and Drug Administration for psoriasis or psoriatic arthritis? ' },
                { id: 'Tuberculosis', section: 'IS THIS MEDICATION SAFE FOR ME?', header: 'Positive Tuberculosis Test' },
                { id: 'Cancer', section: 'IS THIS MEDICATION SAFE FOR ME?', header: 'History of Cancer' },
                { id: 'DoNotUsePopulation', section: 'IS THIS MEDICATION SAFE FOR ME?', header: 'The following populations should NOT use this medication' },
                { id: 'Alcohol', section: 'HOW WILL THIS MEDICATION AFFECT MY LIFESTYLE?', header: 'Alcohol' },
                { id: 'FoodInteraction', section: 'HOW WILL THIS MEDICATION AFFECT MY LIFESTYLE?', header: 'Are there any food interactions with this medication?' },
                { id: 'FamilyPlanning', section: 'HOW WILL THIS MEDICATION AFFECT MY LIFESTYLE?', header: 'Does this medication affect family planning?' },
                { id: 'Pregnancy', section: 'HOW WILL THIS MEDICATION AFFECT MY LIFESTYLE?', header: 'Does this medication affect pregnancy?' },
                { id: 'Breastfeeding', section: 'HOW WILL THIS MEDICATION AFFECT MY LIFESTYLE?', header: 'Does this medication affect breastfeeding?' },
                { id: 'StepsBeforeTaking', section: 'NEXT STEPS AND ROUTINE LABWORK', header: 'What are some things I need to do before taking this medication? ' },
                { id: 'TestsDuring', section: 'NEXT STEPS AND ROUTINE LABWORK', header: 'What tests might be done while taking this medication? ' },
                { id: 'UseWithOtherMeds', section: 'UNDERSTANDING HOW THIS TREATMENT MAY OR MAY NOT WORK WITH OTHER MEDICATIONS', header: 'Can this medication be used with other medications?' },
                // { id: 'RiskX', section: 'UNDERSTANDING HOW THIS TREATMENT MAY OR MAY NOT WORK WITH OTHER MEDICATIONS', header: 'Risk X (Avoid combination)' },
                // { id: 'RiskC', section: 'UNDERSTANDING HOW THIS TREATMENT MAY OR MAY NOT WORK WITH OTHER MEDICATIONS', header: 'Risk C (Monitor therapy)' },
                // { id: 'RiskD', section: 'UNDERSTANDING HOW THIS TREATMENT MAY OR MAY NOT WORK WITH OTHER MEDICATIONS', header: 'Risk D (Consider therapy modification)' },
                { id: 'SuccessToPlacebo', section: 'WHAT ARE THE CHANCES OF THIS MEDICATION WORKING?', header: 'What are the success rates compared to other treatments or placebo for PSORIASIS?' },
                { id: 'SuccessToMeds', section: 'WHAT ARE THE CHANCES OF THIS MEDICATION WORKING?', header: 'What are the success rates compared to other treatments or placebo for PSORIATIC ARTHRITIS?' },
                { id: 'SideEffects', section: 'UNDERSTANDING THE SIDE EFFECTS OF THIS MEDICATION', header: 'What are the side effects of being on this medication?' },
                //{ id: 'SideEffectsGT10', section: 'UNDERSTANDING THE SIDE EFFECTS OF THIS MEDICATION', header: '>10%' },
                //{ id: 'SideEffects1to10', section: 'UNDERSTANDING THE SIDE EFFECTS OF THIS MEDICATION', header: '1-10%' },
                //{ id: 'SideEffectsLT1', section: 'UNDERSTANDING THE SIDE EFFECTS OF THIS MEDICATION', header: '<1%' },
                //{ id: 'SideEffectsUndefined', section: 'UNDERSTANDING THE SIDE EFFECTS OF THIS MEDICATION', header: 'Frequency undefined' },
                { id: 'AdverseEffects', section: 'UNDERSTANDING THE WARNINGS/PRECAUTIONS FOR THIS MEDICATION', header: 'Adverse effects' },
                { id: 'DiseaseRelated', section: 'UNDERSTANDING THE WARNINGS/PRECAUTIONS FOR THIS MEDICATION', header: 'Disease-related concerns' },
                { id: 'ConcurrentIssues', section: 'UNDERSTANDING THE WARNINGS/PRECAUTIONS FOR THIS MEDICATION', header: 'Concurrent Drug-therapy issues' },
                { id: 'DosageIssues', section: 'UNDERSTANDING THE WARNINGS/PRECAUTIONS FOR THIS MEDICATION', header: 'Dosage-form issues' },
                { id: 'SimilarDrugs', section: 'MORE INFORMATION', header: 'What are other drugs that are similar to this one?' },
                { id: 'Reference', section: 'MORE INFORMATION', header: 'References' },
                { id: 'Other', section: 'MORE INFORMATION', header: 'Other' },
            ],
            icons: [
                { id: 'TypeBiologic', name: 'Biologic',  anchor: 'HowDrugWorks', icon: 'biologics.svg' },
                { id: 'Topical', name: 'Topical',  anchor: 'HowToTake', icon: 'cream.svg' },
                { id: 'TypePhototherapy', name: 'Phototherapy',  anchor: 'HowDrugWorks', icon: 'sun-beams.svg' },
                { id: 'Oral', name: 'Oral/Systemics',  anchor: 'HowToTake', icon: 'systemic.svg' },
                { id: 'ForPsO', name: 'Psoriasis',  anchor: 'WhatIsDrug', icon: 'psoriasis-skin-knee-icon.svg' },
                { id: 'ForPsA', name: 'Psoriatic Arthritis',  anchor: 'WhatIsDrug', icon: 'back-pain.svg' },
                { id: 'AutoInjection', name: 'Pen/Autoinjector',  anchor: 'HowToTake', icon: 'auto-injector.svg' },
                { id: 'Injection', name: 'Needle/Prefilled Syringe',  anchor: 'HowToTake', icon: 'pre-filled-syringe.svg' },
                { id: 'Intravenous', name: 'Intravenous Infusion',  anchor: 'HowToTake', icon: 'infusion.svg' },
                { id: 'Mouth', name: 'By Mouth',  anchor: 'HowToTake', icon: 'mouth2.svg' },
                { id: 'Clock', name: 'Clock',  anchor: 'HowLongUntilEffect', icon: 'clock.svg' },
                { id: 'Travel', name: 'Travel Tips',  anchor: 'WhatIsDrug', icon: 'travel.svg' },
                { id: 'cold', name: 'Cold',  anchor: 'WhatIsDrug', icon: 'cold1.svg' },
                { id: 'recycle', name: 'Recycle',  anchor: 'WhatIsDrug', icon: 'recycle.svg' },
                { id: 'safety', name: 'Safety',  anchor: 'WhatIsDrug', icon: 'safety.svg' },
                { id: 'trash', name: 'Trash',  anchor: 'WhatIsDrug', icon: 'trash.svg' },
                { id: 'tuberculosis', name: 'Tuberculosis',  anchor: 'WhatIsDrug', icon: 'tuberculosis.svg' },
                { id: 'tuberculosisWarning', name: 'Tuberculosis Warning',  anchor: 'WhatIsDrug', icon: 'tuberculosisWarning.svg' },
                { id: 'cancer2', name: 'History of Cancer',  anchor: 'WhatIsDrug', icon: 'cancer2-ribbon.svg' },
                { id: 'cancer2Warning', name: 'History of Cancer Warning',  anchor: 'WhatIsDrug', icon: 'cancer2-ribbonWarning.svg' },
                { id: 'Alcohol', name: 'Alcohol',  anchor: 'Alcohol', icon: 'alcohol.svg' },
                { id: 'NoAlcohol', name: 'No Alcohol',  anchor: 'Alcohol', icon: 'no-alcohol.svg' },
                { id: 'Food', name: 'Food',  anchor: 'FoodInteraction', icon: 'food.svg' },
                { id: 'NoFood', name: 'No Food',  anchor: 'FoodInteraction', icon: 'foodNo.svg' },
                { id: 'FamilyPlanning', name: 'Family Planning',  anchor: 'Breastfeeding', icon: 'family.svg' },
                { id: 'NoFamilyPlanning', name: 'No Family Planning',  anchor: 'Breastfeeding', icon: 'familyNo.svg' },
                { id: 'FamilyPlanningWarning', name: 'Family Planning Warning',  anchor: 'Breastfeeding', icon: 'familyWarning.svg' },
                { id: 'Pregnancy', name: 'Pregnancy',  anchor: 'Pregnancy', icon: 'pregnant.svg' },
                { id: 'PregnancyWarning', name: 'Pregnancy Warning',  anchor: 'Pregnancy', icon: 'pregnantWarning.svg' },
                { id: 'NoPregnancy', name: 'No Pregnancy',  anchor: 'Pregnancy', icon: 'pregnantNo.svg' },
                { id: 'BreastFeeding', name: 'Breast Feeding',  anchor: 'Breastfeeding', icon: 'breastfeeding.svg' },
                { id: 'BreastFeedingWarning', name: 'Breast Feeding Warning',  anchor: 'Breastfeeding', icon: 'breastfeedingWarning.svg' },
                { id: 'NoBreastFeeding', name: 'No Breast Feeding',  anchor: 'Breastfeeding', icon: 'breastfeedingNo.svg' },
                { id: 'Labs', name: 'Labs',  anchor: 'StepsBeforeTaking', icon: 'erlenmeyer.svg' },
                { id: 'DrugInteractions', name: 'Drug Interactions',  anchor: 'HowToTake', icon: 'pills.svg' },
                { id: 'Efficacy', name: 'Efficacy',  anchor: 'Efficacy', icon: 'pieChart.svg' },
                { id: 'SideEffects', name: 'Side Effects',  anchor: 'SideEffects', icon: 'side-effects.svg' },
                { id: 'latexAllergy', name: 'Latex Allergy',  anchor: 'WhatIsDrug', icon: 'latex-allergy.svg' },
                { id: 'Infection', name: 'Infection',  anchor: 'WhatIsDrug', icon: 'biology-virus.svg' },
                { id: 'InfectionWarning', name: 'Infection Warning',  anchor: 'WhatIsDrug', icon: 'biology-virusWarning.svg' },
                { id: 'Finance', name: 'Financial Aid',  anchor: 'WhatIsDrug', icon: 'financial-assistance.svg' },
            ],
        }
    }

    componentDidMount() {
        Api.get(`drug/${this.state.treatment}`, (data) => {
            const therapy = data[0];
            if (therapy.Icons) {
                therapy.Icons = JSON.parse(therapy.Icons);
                //console.log(therapy.Icons);
            }
            therapy.TherapyFor = therapy.TherapyFor.indexOf(',') === -1 ? [therapy.TherapyFor] : therapy.TherapyFor.split(',');
            //data.Icons = data.Icons.indexOf(',') === -1 ? [data.Icons] : data.Icons.split(',');
            //therapy.loading = false;
            this.setState({ therapy, loading: false });
        });
    }

    toggleAccordion = (e, link) => {
        let item = document.querySelector(`${link.href}`);
        if (item) {
            !item.classList.contains('ant-collapse-item-active') && item.classList.add('ant-collapse-item-active');
            let children = item.children;
            children[0].getAttribute('aria-expanded') === 'false' && children[0].setAttribute('aria-expanded', 'true');
            if (children[1].classList.contains('ant-collapse-content-inactive')) {
                children[1].classList.remove('ant-collapse-content-inactive');
                children[1].classList.add('ant-collapse-content-active');
            }
        }
    }



    renderIcons = () => {
        let icons = this.state.icons;
        const iconsArr = this.state.therapy.Icons ? Object.keys(this.state.therapy.Icons) : [];
        if (this.state.therapy) {
            return (
                <Row gutter={8}>
                    {
                        (this.state.therapy.Icons) ?
                            //iconsArr.map((icon, i) => {
                            this.state.icons.map((icon, i) => {

                                if (iconsArr.includes(icon.id)) {
                                    let icon2 = this.state.therapy.Icons[icon.id];
                                    let clickIcon = i === 0 ? <h4>Click on an icon to learn more</h4> : '';

                                    return (
                                        <div key={`icon${i}`}>
                                            {clickIcon}
                                            <Col key={icon.id} xs={{ span: 8, offset: 0 }} sm={{ span: 6, offset: 0 }} md={{ span: 4, offset: 0 }}  >
                                                <Anchor onClick={(e, link) => this.toggleAccordion(e.target, link)} affix={false}>
                                                    <Link href={`#${icon2.anchor}`} title={
                                                        <Card
                                                            bordered={false}
                                                            
                                                            cover={<img className='' alt='' height='110px' src={require(`../icons/${icon.icon}`)} />}
                                                            className={'iconCard text-center'}
                                                        >
                                                            <Meta
                                                                title={icon.name}
                                                            />
                                                        </Card>}>
                                                    </Link>
                                                </Anchor>
                                            </Col>
                                        </div>
                                    )
                                }
                            })
                            : ''
                    }
                </Row>
            )
        }
    }

    

    renderEfficacyRate = (rate, id, efficacyOrder) => {
        if (rate) {
            let rateTitle = id + 'RateTitle';
            rateTitle = efficacyOrder > 1 ? rateTitle + efficacyOrder : rateTitle;
            rateTitle = <span className="rate"><br/>{this.state.therapy[rateTitle]}</span>;
            let rateArr = [];
            const dividend = Math.floor(rate / 10);
            const remainder = rate - dividend * 10;
            rate < 10 && rateArr.push(rate);
            for (let i = 0; i < 10; i++) {
                if (i < dividend) {
                    rateArr.push(100);
                }
                if (i === dividend - 1) {
                    rateArr.push(remainder * 10);
                }
                if (i > dividend) {
                    rateArr.push(0);
                }
            }
            return (<div>
                {rateArr.map((itemRate, index) => (
                    <div key={`rateItem${index}`} className="vertical">
                        <RateStar
                            key={index}
                            size={60}
                            rate={itemRate}
                            frontColor="#1990FC"
                            backgroundColor="#dfdfdf"
                        />
                    </div>
                ))}
                <span className="rate"><strong>{rate}%</strong></span>
                <em>{rateTitle}</em>
            </div>);
        }
    }

    renderText = (text) => renderHTML(draftToHtml(JSON.parse(text)))

    callback = (key) => {
        if(key.indexOf('SuccessToPlacebo') !== -1 ) {
        }
        if(key === 'SuccessToMeds' ) {
        }
    }

    hasText = (data) => {
        let parsedText = JSON.parse(data);
        let htmlText = draftToHtml(parsedText);
        return htmlText.replace(/(<([^>]+)>)/ig,"").trim();
    }

    renderSections = () => {
        const sections = this.state.textAreas.map(item => item.section);
        const sectionsUnique = Array.from(new Set(sections));
        let sectionHasData = [];
        let toRender =  sectionsUnique.map((sectionU, i) => {
            return (
                <div key={'section' + i} id={sectionU} className='treatmentSection my-1'>
                <Row className="container800 center">
                    <Col>
                    <h3 >{sectionU}</h3>
                    <Collapse bordered={false} onChange={this.callback}>
                    {
                        this.state.textAreas.map(subSection => {
                            if (subSection.section === sectionU && this.state.therapy[subSection.id]) {
                                
                                if(this.hasText(this.state.therapy[subSection.id])){
                                    sectionHasData.push(sectionU);
                                    subSection.header = subSection.header.replace('[Infographic]', '');

                                    return (<Panel forceRender key={subSection.id} id={subSection.id} header={<strong>{subSection.id === 'WhatIsDrug' ? subSection.header.replace('[Drug]', this.state.therapy.DisplayName) : subSection.header}</strong>} >
                                            {
                                                this.renderPanel(subSection.id)
                                            }
                                        </Panel>)
                                }
                            }
                        })
                    }
                    
                    </Collapse>
                    </Col>
                    </Row>
                </div>
            )
        })

        sectionHasData = Array.from(new Set(sectionHasData));
        const sectionsToHide = sectionsUnique.filter(x => !sectionHasData.includes(x));
        if(sectionsToHide.length) {
            toRender = toRender.filter(el => !sectionsToHide.includes(el.props.id));
        }
        //console.log('tohide', sectionsToHide);
        return toRender;

    }

    renderPanel = (id) => {
        if(id === 'SuccessToPlacebo' || id === 'SuccessToMeds') {
            //console.log(this.state.therapy[id + 'Rate'], this.state.therapy[id + 'Rate'], this.state.therapy[id + 'Rate3'])
            
            return (
                <Card>
                    <Row>
                        <Col sm={{ span: 24, offset: 0}} md={{ span: 8, offset: 0}}>
                        {this.renderEfficacyRate(this.state.therapy[id + 'Rate'], id, 1)}
                        {this.renderEfficacyRate(this.state.therapy[id + 'Rate2'], id, 2)}
                        {this.renderEfficacyRate(this.state.therapy[id + 'Rate3'], id, 3)}
                        {this.renderEfficacyRate(this.state.therapy[id + 'Rate4'], id, 4)}
                        </Col>
                        <Col sm={{ span: 24, offset: 2}} md={{ span: 16, offset: 0}}>
                            {
                                this.renderText(this.state.therapy[id])
                            }
                        </Col>
                    </Row>                   
                </Card>)
        } else if(id === 'UseWithOtherMeds') {
            return (
                <div>
                    {this.renderText(this.state.therapy[id])}
                    {this.renderSubNav()}
                </div>
            )
        } else {
            return this.renderText(this.state.therapy[id]);
        }
    }

    checkParams = () => {
        const values = queryString.parse(this.props.location.search.toLowerCase())

        if(this.state.therapy !== ""){
            if(values.risk) {
                return this.renderRisk(values.risk);
            }
            if(values.sideeffect) {
                return this.renderSideEffect(values.sideeffect);
            }
            return (
                <div>
                    <div className="px-1">
                        {this.renderIcons()}
                    </div>
                    <div >
                        {this.renderSections()}
                    </div>
                </div>
            );
        }        
    }

    riskTitle = (risk) => {
        let title = '';
        switch (risk) {
            case 'x':
                title = 'Avoid Combination';
                break;
            case 'c':
                title = 'Monitor Therapy';
                break;
            case 'd':
                title = 'Consider Therapy Modification';
                break;
            default:
                title = 'Risk';
        }
        return title;
    }

    sideEffectsTitle = (sideEffect) => {
        let title = '';
        switch (sideEffect) {
            case 'gt10':
                title = 'Side Effects >10%';
                break;
            case '1to10':
                title = 'Side Effects 1-10%';
                break;
            case 'lt1':
                title = 'Side Effects <1%';
                break;
            case 'undefined':
                title = 'Side Effects Undefined';
                break;
            default:
                title = 'Side Effects';
        }
        return title;
    }

    renderSubNav = () => {
        const risks = [
            { path: 'risk=x', key: 'RiskX',  name: 'Avoid Combination'}, { path: 'risk=c', key: 'RiskC', name: 'Monitor Therapy'}, { path: 'risk=d', key: 'RiskD', name: 'Consider Therapy Modification'}
        ];

        let links = risks.map(s => {
            if(this.state.therapy[s.key] && this.hasText(this.state.therapy[s.key])){
                return s;
            }
        });
        
        links = links.filter(Boolean);
        return links.length ? <List
            className={'bg-white'}
            header={<div>Risks</div>}
            bordered
            dataSource={links}
            renderItem={item => (
                <List.Item>
                    <RouterLink to={`./${this.state.therapy.Slug}?${item.path}`}>{item.name}</RouterLink>
                </List.Item>
            )}
        /> : '';
    }

    renderRisk = (risk) => {
        const riskId = `Risk${risk.toUpperCase()}`;
        const risks = [
            { path: 'risk=x', key: 'RiskX',  name: 'Avoid Combination'}, { path: 'risk=c', key: 'RiskC', name: 'Monitor Therapy'}, { path: 'risk=d', key: 'RiskD', name: 'Consider Therapy Modification'}
        ];

        let links = risks.map(s => {
            if(this.state.therapy[s.key] && this.hasText(this.state.therapy[s.key])){
                return s;
            }
        });
        
        links = links.filter(Boolean);

        return (
            <div>
                <Row>
                    <Col>
                    
                        <Button size={'large'} type="default" onClick={() => this.props.history.push(`/treatment/${this.state.therapy.Slug}`)}>
                            <Icon type="left" />
                            Return to {this.state.therapy.DisplayName}
                        </Button>
                        <h2 className='mt-1'>{this.riskTitle(risk)}</h2>
                        {this.renderText(this.state.therapy[riskId])}
                        <List
                            header={<div>Risks</div>}
                            bordered
                            dataSource={links}
                            renderItem={item => (
                                <List.Item>
                                    <RouterLink to={`./${this.state.therapy.Slug}?${item.path}`}>{item.name}</RouterLink>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    renderSideEffect = (sideEffect) => {
        const sideEffects = [
            { value: 'gt10', key: 'SideEffectsGT10', path: 'sideeffect=gt10', name: 'Side Effects >10%'}, 
            { value: '1to10', key: 'SideEffects1to10', path: 'sideeffect=1to10', name: 'Side Effects 1-10%'}, 
            { value: 'lt1', key: 'SideEffectsLT1', path: 'sideeffect=lt1', name: 'Side Effects <1%'},
            { value: 'undefined', key: 'SideEffectsUndefined', path: 'sideeffect=undefined', name: 'Side Effects Undefined'}
        ];
        let links = sideEffects.map(s => {
            if(this.state.therapy[s.key] && this.hasText(this.state.therapy[s.key])){
                return s;
            }
        });
        
        links = links.filter(Boolean);
        return (
            <div>
                <Row>
                    <Col>
                        <Button size={'large'} type="default" onClick={() => this.props.history.push(`/treatment/${this.state.therapy.Slug}`)}>
                            <Icon type="left" />
                            Return to {this.state.therapy.DisplayName}
                        </Button>
                        <h2 className='mt-1'>{this.sideEffectsTitle(sideEffect)}</h2>
                        {this.renderText(this.state.therapy[sideEffects.find(s => s.value === sideEffect).key])}
                        <List
                            header={<div>Side Effects</div>}
                            bordered
                            dataSource={links}
                            renderItem={item => (
                                <List.Item>
                                    <RouterLink to={`./${this.state.therapy.Slug}?${item.path}`}>{item.name}</RouterLink>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        console.log(this.state);
        return (
            <div className="therapy">
                <PageTitle title={'TREATMENT EDUCATION'} subTitle={`${this.state.therapy.DisplayName}`} />

                {this.state.loading ? 
                    <Row><Col><Skeleton active avatar paragraph={{ rows: 10 }} /></Col></Row> 
                    : this.checkParams()
                }
            </div>
        )
    }
}

export default withRouter(Treatment);
