import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Menu } from 'antd';

const SubMenu = Menu.SubMenu;

class LeftMenu extends Component {

  handleClick = (e) => {
    if(e.key !== 'flare') {
      this.setState({
        current: e.key,
      });
      this.props.history.push(`/${e.key}`);
      this.props.click();
    }
  }
  
  render() {
    return (
			<Menu onClick={this.handleClick} mode={this.props.mode} >
      	<Menu.Item key="decision">
          <a >Decision Aid</a>
        </Menu.Item>
        <Menu.Item key="treatmentedu">
          <a >Treatment</a>
        </Menu.Item>
        <SubMenu title={<span>Learn More</span>}>
            <Menu.Item key="advocacy">Advocacy</Menu.Item>
            <Menu.Item key="healthtips">Health Tips</Menu.Item>
            <Menu.Item key="practicaltips">Practical Tips</Menu.Item>
            <Menu.Item key="wordstoknow">Words to Know</Menu.Item>
            <Menu.Item key="flare">
              <a href={process.env.PUBLIC_URL + '/flare-handout.pdf'} target="_blank">Flare Handout</a>
            </Menu.Item>
            {/* <Menu.Item key="faq">FAQ</Menu.Item> */}
        </SubMenu>
        
      </Menu>
    );
  }
}

export default withRouter(LeftMenu);