import React from 'react';
import { Row, Col } from 'antd';

const PageTitle = ({title, subTitle}) => {
    return (
        <Row>
            <Col>
                <h1 style={{ textTransform: 'uppercase' }}>{title}</h1>
                <hr style={{ margin: '0 -16px', borderTop: '10px solid #1a3955' }}/>
                {
                    subTitle && <h2 style={{ textTransform: 'uppercase' }}><strong>{subTitle}</strong></h2>
                }
                
            </Col>
        </Row>
    )
}

export default PageTitle;