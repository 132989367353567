import * as actionTypes from '../types';

const initialState = {
    jwtToken: null,
    email: null,
    expirationDate: null,
    error: null,
    loading: false,
    role: null,
};

const reducer = (state = initialState, action) => {
    const { payload, type } = action;

    switch (type) {
        case actionTypes.AUTH_INIT:
            return {
                ...state,
                error: null,
                loading: false,
            }
        case actionTypes.AUTH_START:
            return {
                ...state,
                error: null,
                loading: true,
            }
        case actionTypes.AUTH_SUCCESS:
            return {
                error: null,
                loading: false,
                jwtToken: payload.jwtToken,
                email: payload.email,
                expirationDate: payload.expirationDate,
                role: payload.role,
            }
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case actionTypes.AUTH_LOGOUT:
            return {
                ...initialState
            }
        default:
            return state;
    }
}

export default reducer;