import React from 'react';
import { Breadcrumb, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

const BreadcrumbMaker = (arr) => {
    return (
        <Row>
            <Col>
                <Breadcrumb separator=">">
                    {arr.map(item => {
                        return (
                            <Breadcrumb.Item key={item.name}>
                                {item.route ? <Link to={item.route}>{item.name}</Link> : item.name}
                            </Breadcrumb.Item>)
                    })}
                </Breadcrumb>
            </Col>
        </Row>

    )
}

export default BreadcrumbMaker;