import React, { Component } from 'react';
import { Row, Col, List, Button, Icon, Spin, Popover, Typography, Alert } from 'antd';
import { Link } from 'react-router-dom';

import Api from '../../api';
import html2canvas from 'html2canvas';
const reactStringReplace = require('react-string-replace');
const { Paragraph, Text } = Typography;


const tnfInhibitors = ['Enbrel (Etanercept)', 'Humira (Adalimumab)', 'Remicade (Infliximab)', 'Cimzia (Certolizumab Pegol)', 'Simponi (Golimumab)'];
const oralSystemics = ['Arava (Leflunomide)', 'Rheumatrex (Methotrexate)', 'Azulfidine (Sulfasalazine)', 'Azathioprine (Imuran)', 'Plaquenil (Hydroxychloroquine)', 'mycophenolate mofetil', 'cellcept'];
const IL1223 = ['Stelara (Ustekinumab)', 'Tremfya (Guselkumab)'];
const IL17 = ['Cosentyx (Secukinumab)', 'Siliq (Brodalumab)', 'Taltz (Ixekizumab)'];
const tCellInhibitor = ['Orencia (Abatacept)'];
const PDE4 = ['Otezla (Apremilast)'];
const JAK = ['Xeljanz (Tofacitinib)']; //xeljanz

class Results extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            passed: false,
            daEmpty: false,
            focus: [],
            consider: [],
            priority: [],
            caution: [],
            adjust: [],
            avoid: [],
            answers: JSON.parse(localStorage.getItem('decisionAid')),
            therapies: [],
            comorbidities: [],
            oralSystemics: [],
            biologics: [],
            biologicSystemicsToFilter: [],
            filteredComorbidities: [],
            domains: [],
            filteredDomains: [],
            defaults: [],
            filteredDefaults: [],
            pregnancyCaution: [],
            pregnancyAvoid: [],
            breastfeedAvoid: [],
            breastfeedCaution: [],
            injectionAvoid: [],
            latexAvoid: [],
            recommendedSpecial: [],
            othersAll: [],
            warnings: []
        };
    }
    componentDidMount = () => {
            // get list of drugs
        if(this.state.answers){
            Api.get('therapy/all', (therapies) => {
                Api.get('/admin/comorbiditydomain/all', (data2) => {
                    Api.get('/admin/other/all', (othersAll) => {                
                        therapies = therapies.map(d => {
                            d.Icons = d.Icons ? JSON.parse(d.Icons) : [];
                            return d;
                        });
                        //console.log('data2', data2);
                        data2.forEach(d2 => {
                            d2.Warnings = JSON.parse(d2.Warnings);
                            if(d2.Warnings){
                                d2.Warnings = d2.Warnings.filter(Boolean);
                            }
                        });

                        let comorbidities = data2.filter(c => c.ComorbidityType === 'Comorbidity');
                        let domains = data2.filter(d => d.ComorbidityType === 'Domain');
                        let defaults = data2.filter(d => d.ComorbidityType === 'Default');
                        let femalePregnancy = data2.filter(d => d.ComorbidityType === 'DAFilter' && d.Name === 'Female and Pregnancy');
                        let femaleBreastfeeding = data2.filter(d => d.ComorbidityType === 'DAFilter' && d.Name === 'Female and Breastfeeding');
                        let maleFertility = data2.filter(d => d.ComorbidityType === 'DAFilter' && d.Name === 'Male and Fertility');
                        let scalpAffected = data2.filter(d => d.ComorbidityType === 'DAFilter' && d.Name === 'Scalp affected');
                        let oralSystemics = therapies.filter(d => d.TherapyType === 'Oral Systemic');
                        let biologics = therapies.filter(d => d.TherapyType === 'Biologic');
                        
                        let prevCurrBiologics = [];
                        let prevCurrOralSystemics = [];
                        
                        if(this.state.answers.previousMedication.length){
                            prevCurrBiologics = prevCurrBiologics.concat(
                                this.state.answers.previousMedication.filter(d => biologics.map(x => x.Name).includes(d))
                            );
                            prevCurrOralSystemics = prevCurrOralSystemics.concat(
                                this.state.answers.previousMedication.filter(d => oralSystemics.map(x => x.Name).includes(d))
                            );
                        }
                        if(this.state.answers.currentMedications.length){
                            prevCurrBiologics = prevCurrBiologics.concat(
                                this.state.answers.currentMedications.filter(d => biologics.map(x => x.Name).includes(d))
                            );
                            prevCurrOralSystemics = prevCurrOralSystemics.concat(
                                this.state.answers.currentMedications.filter(d => oralSystemics.map(x => x.Name).includes(d))
                            );
                        }
                        let biologicSystemicsToFilter = this.unique(prevCurrBiologics.concat(prevCurrOralSystemics));                
                        let filteredComorbidities = this.filterByComorbidities(comorbidities);
                        let filteredDomains = this.filterByDomains(domains);
                        let injectionAvoid = this.filterByInjection(therapies);
                        let latexAvoid = this.filterByLatex(therapies);

                        // let femaleFamilyPlanning = {
                        //     pregnancyAvoid: [],
                        //     pregnancyCaution: [],
                        //     breastfeedAvoid: [],
                        //     breastfeedCaution: []
                        // }

                        // if(this.state.answers.familyPlanning.indexOf('Female') > -1){
                        //     femaleFamilyPlanning = this.filterByIconsFemale(therapies);
                        // }

                        // new code
                        let results = {
                            priority: [],
                            consider: [],
                            caution: [],
                            avoid: [],
                            warnings: []
                        }
                        console.log('defaults', defaults);
                        let defaultTherapies = this.getDefaultTherapy(defaults);
                        
                        if(!defaultTherapies.empty){
                            results.priority = defaultTherapies.priority;
                            results.consider = defaultTherapies.consider;
                            results.caution = defaultTherapies.caution;
                            results.avoid = defaultTherapies.avoid;
                            results.warnings = defaultTherapies.warnings;
                            console.log('results', results);
                        
                            results = this.combineByArrString(filteredComorbidities, results);
                            results = this.combineByArrString(filteredDomains, results);
                            if (this.state.answers.familyPlanning.indexOf('Female and pregnancy is in my near future') > -1) {
                                console.log('pregnancy');
                                results = this.combineByArrString(femalePregnancy, results);
                            }
                            if (this.state.answers.breastfeeding.indexOf('Yes') > -1) {
                                console.log('breastfeeding');
                                results = this.combineByArrString(femaleBreastfeeding, results);
                            }
                            if (this.state.answers.familyPlanning.indexOf('born Male and having children is in my near future') > -1) {
                                console.log('male fertile');
                                results = this.combineByArrString(maleFertility, results);
                            }
                            if (this.state.answers.affectedArea.indexOf('Scalp') > -1) {
                                console.log('scalp');
                                results = this.combineByArrString(scalpAffected, results);
                            }
                            // results = this.filterFemaleByFamilyPlanning(results, femaleFamilyPlanning);
                            // results = this.filterMaleByFamilyPlanning(results);

                            // injection
                            if(this.state.answers.injections === 'No way! I am terrified!'){
                                results.caution = results.caution.concat(injectionAvoid.length ? injectionAvoid.map(x => x.Name) : []);
                            }
                            // latex allergy
                            if(this.state.answers.latex === 'Yes'){
                                results.avoid = results.avoid.concat(latexAvoid.length ? latexAvoid.map(x => x.Name) : []);
                            }

                            results = this.uniqueAll(results);
                            let filtered = this.filterPrevCurrentTherapies(results.priority, results.consider, results.caution);
                            results.consider = filtered.filteredConsider;
                            results.priority = filtered.filteredPriority;

                            //let specials = this.checkSpecial();
                            //console.log('specials-', specials);
                            //results = this.combineSpecialLines(results, specials);
                            //console.log('results with specials', results);

                            results = this.uniqueAll(results);
                            results = this.removeCurrentMeds(results);

                            results.priority = this.createList(therapies, results.priority, 'priority', true);
                            results.caution = this.createList(therapies, results.caution, 'caution', false);
                            results.avoid = this.createList(therapies, results.avoid, 'avoid', false);
                            results.consider = this.createList(therapies, results.consider, 'consider', false);
                            //results = this.uniqueAll(results);
                            console.log('all buckets', results);
                            // results.priority = results.priority.map(item => item.DisplayName).filter((value, index, self) => self.indexOf(value) === index);
                            // results.caution = results.caution.map(item => item.DisplayName).filter((value, index, self) => self.indexOf(value) === index);
                            // results.avoid = results.avoid.map(item => item.DisplayName).filter((value, index, self) => self.indexOf(value) === index);
                            // results.consider = results.consider.map(item => item.DisplayName).filter((value, index, self) => self.indexOf(value) === index);
                            //console.log('all buckets', results);
                            this.checkConflicts(results);
                        } else {
                            this.setState({daEmpty: true, passed: true });
                        }
                    });
                });
            });
        } else {
            this.setState({ daEmpty: true, passed: false, loading: false });
        }
    }

    consolidateWarnings = () => {

    }

    // function for dynamic sorting
    compareValues = (key, order='asc') => {
        return function (a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string') ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ?
                b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order == 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    filterByComorbidities = (comorbidities) => comorbidities.filter(e => this.state.answers.comorbidities.includes(e.Name));
    filterByDomains = (domains) => domains.filter(e => this.state.answers.domains.includes(e.Name));
    
    filterIconsArr = (data, filterArr) => {
        const filteredArr = data.filter(e => {
            let selectedIcons = Object.keys(e.Icons);
            let arr = filterArr.filter(p => selectedIcons.includes(p));
            if (arr.length){
                return e;
            }
        });
        return filteredArr;
    }
    // TODO: Remove
    filterByIconsFemale = (data) => {
        const pregnancyAvoidArr = ['NoPregnancy'];
        const pregnancyWarningArr = ['PregnancyWarning'];
        const breastfeedAvoidArr = ['NoBreastFeeding'];
        const breastfeedWarningArr = ['BreastFeedingWarning'];
        let pregnancyAvoid = this.filterIconsArr(data, pregnancyAvoidArr);
        let pregnancyCaution = this.filterIconsArr(data, pregnancyWarningArr);
        let breastfeedAvoid = this.filterIconsArr(data, breastfeedAvoidArr);
        let breastfeedCaution = this.filterIconsArr(data, breastfeedWarningArr);
        //console.log('pregnancyAvoid', pregnancyAvoid,'pregnancyCaution', pregnancyCaution,'breastfeedAvoid', breastfeedAvoid, 'breastfeedCaution', breastfeedCaution);
        return { pregnancyAvoid, pregnancyCaution, breastfeedAvoid, breastfeedCaution };
    }
    filterByInjection = (data) => {
        const injectionArr = ['Injection'];
        let injectionAvoid = this.filterIconsArr(data, injectionArr);

        return injectionAvoid;
    }
    filterByLatex = (data) => {
        const latexArr = ['latexAllergy'];
        let latexAvoid = this.filterIconsArr(data, latexArr);

        return latexAvoid;
    }
    getDefaultTherapy = (defaults) => {
        let defaultTherapies = {
            priority: [],
            consider: [],
            caution: [],
            avoid: [],
            empty: true,
            warnings: []
        };

        const psoriasisType = this.state.answers.psoriasisType;
        const bsa = this.state.answers.surfaceArea;
        //default Psoriasis >5%BSA
        if(psoriasisType && psoriasisType.toLowerCase().indexOf('Psoriasis') && bsa >= 5){
            //console.log(defaults);
            console.log('>5%BSA');
            let psoriasisDefault = defaults.find(d => d.Name.indexOf('>5%BSA') > -1);
            defaultTherapies = {
                priority: psoriasisDefault.Priority ? psoriasisDefault.Priority.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                consider: psoriasisDefault.Recommend ? psoriasisDefault.Recommend.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                caution: psoriasisDefault.Caution ? psoriasisDefault.Caution.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                avoid: psoriasisDefault.ToRemove ? psoriasisDefault.ToRemove.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                empty: false,
                warnings: psoriasisDefault.Warnings
            }
            
            return defaultTherapies;
        }
        
        // default Psoriasis <5%BSA
        if(psoriasisType && psoriasisType.toLowerCase().indexOf('Psoriasis') && bsa < 5){
            console.log('<5%BSA');
            let psoDefault = defaults.find(d => d.Name.indexOf('<5%BSA') > -1);
            defaultTherapies = {
                priority: psoDefault.Priority ? psoDefault.Priority.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                consider: psoDefault.Recommend ? psoDefault.Recommend.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                caution: psoDefault.Caution ? psoDefault.Caution.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                avoid: psoDefault.ToRemove ? psoDefault.ToRemove.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                empty: false,
                warnings: psoDefault.Warnings
            }
            return defaultTherapies;
        }

        if(psoriasisType && psoriasisType.toLowerCase().indexOf('psoriatic arthritis')  && bsa >= 5){
            console.log('PsA and Severe PsO (>=5%)');
            let psaDefault = defaults.find(d => d.Name === 'PsA and Severe PsO (>=5%)');
            defaultTherapies = {
                priority: psaDefault.Priority ? psaDefault.Priority.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                consider: psaDefault.Recommend ? psaDefault.Recommend.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                caution: psaDefault.Caution ? psaDefault.Caution.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                avoid: psaDefault.ToRemove ? psaDefault.ToRemove.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                empty: false,
                warnings: psaDefault.Warnings
            }
            return defaultTherapies;
        }
        if(psoriasisType && psoriasisType.toLowerCase().indexOf('psoriatic arthritis')  && bsa < 5){
            console.log('PsA and Mild Psoriasis (<5%)');
            let psaDefault = defaults.find(d => d.Name === 'PsA and Mild Psoriasis (<5%)');
            defaultTherapies = {
                priority: psaDefault.Priority ? psaDefault.Priority.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                consider: psaDefault.Recommend ? psaDefault.Recommend.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                caution: psaDefault.Caution ? psaDefault.Caution.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                avoid: psaDefault.ToRemove ? psaDefault.ToRemove.split(new RegExp(/,(?=\S)|:/, 'gm')) : [],
                empty: false,
                warnings: psaDefault.Warnings
            }
            return defaultTherapies;
        }
        defaultTherapies.empty = true;
        return defaultTherapies;
    }
    combineByArrString = (arr, results) => {
        arr.forEach(element => {
            results.priority = results.priority.concat(element.Priority ? element.Priority.split(new RegExp(/,(?=\S)|:/, 'gm')) : []);
            results.consider = results.consider.concat(element.Recommend ? element.Recommend.split(new RegExp(/,(?=\S)|:/, 'gm')) : []);
            results.caution = results.caution.concat(element.Caution ? element.Caution.split(new RegExp(/,(?=\S)|:/, 'gm')) : []);
            results.avoid = results.avoid.concat(element.ToRemove ? element.ToRemove.split(new RegExp(/,(?=\S)|:/, 'gm')) : []);
        });
        return results;
    }
    // TODO: Remove
    filterFemaleByFamilyPlanning = (results, femaleFamilyPlanning) => {
        if(this.state.answers.familyPlanning.indexOf('Female') > -1){
            //pregnancy 
            if(this.state.answers.familyPlanning.indexOf('Female and pregnancy is in my near future') > -1){
                results.caution = results.caution.concat(femaleFamilyPlanning.pregnancyCaution.length ? femaleFamilyPlanning.pregnancyCaution.map(x => x.Name) : []);
                results.avoid = results.avoid.concat(femaleFamilyPlanning.pregnancyAvoid.length ? femaleFamilyPlanning.pregnancyAvoid.map(x => x.Name) : []);
            }
            //breastfeeding
            if(this.state.answers.familyPlanning.indexOf('Female and pregnancy is in my near future') > -1){
                results.caution = results.caution.concat(femaleFamilyPlanning.breastfeedCaution.length ? femaleFamilyPlanning.breastfeedCaution.map(x => x.Name) : []);
                results.avoid = results.avoid.concat(femaleFamilyPlanning.breastfeedAvoid.length ? femaleFamilyPlanning.breastfeedAvoid.map(x => x.Name) : []);
            }
        }
        return results;
    }
    // TODO: Remove
    filterMaleByFamilyPlanning = (results) => {
        if(this.state.answers.familyPlanning.indexOf('Male') > -1){
            if(this.state.answers.familyPlanning.indexOf('Male and having children is in my near future') > -1){
                results.caution = results.caution.concat(['Azulfidine (Sulfasalazine)']);
            }
        }
        return results;
    }
    uniqueAll = (results) => {
        results.priority = this.unique(results.priority);
        results.caution = this.unique(results.caution);
        results.avoid = this.unique(results.avoid);
        results.consider = this.unique(results.consider);
        return results;
    }
    // TODO: Remove
    combineSpecialLines = (results, specials) => {
        if(specials.length){
            //console.log('specials', specials);
            specials.forEach(s => {
                if(s.hasOwnProperty('firstLine')){
                    results.consider = s.firstLine.concat(results.consider);
                }
                if(s.hasOwnProperty('secondLine')){
                    results.consider = s.secondLine.concat(results.consider);
                }
                if(s.hasOwnProperty('thirdLine')){
                    results.consider = s.thirdLine.concat(results.consider);
                }
            });
        }
        return results;
    }
    removeCurrentMeds = (results) => {
        results.priority = results.priority.filter(x => !this.state.answers.currentMedications.includes(x.Name));
        results.caution = results.caution.filter(x => !this.state.answers.currentMedications.includes(x.Name));
        results.avoid = results.avoid.filter(x => !this.state.answers.currentMedications.includes(x.Name));
        results.consider = results.consider.filter(x => !this.state.answers.currentMedications.includes(x.Name));
        return results;
    }
    // TODO: Remove
    daAlgorithm = () => {   
        
        let priority = [];
        let consider = [];
        let caution = [];
        let avoid = [];

        let defaultTherapies = this.getDefaultTherapy();
        if(!defaultTherapies.empty){
            priority = defaultTherapies.priority
            consider = defaultTherapies.consider
            caution = defaultTherapies.caution
            avoid = defaultTherapies.avoid

            // combine by comorbidities
            this.state.filteredComorbidities.forEach(element => {
                priority = priority.concat(element.Priority ? element.Priority.split(new RegExp(/,(?=\S)|:/, 'gm')) : []);
                consider = consider.concat(element.Recommend ? element.Recommend.split(new RegExp(/,(?=\S)|:/, 'gm')) : []);
                caution = caution.concat(element.Caution ? element.Caution.split(new RegExp(/,(?=\S)|:/, 'gm')) : []);
                avoid = avoid.concat(element.ToRemove ? element.ToRemove.split(new RegExp(/,(?=\S)|:/, 'gm')) : []);
            });
            // combine by domains
            this.state.filteredDomains.forEach(element => {
                priority = priority.concat(element.Priority ? element.Priority.split(new RegExp(/,(?=\S)|:/, 'gm')) : []);
                consider = consider.concat(element.Recommend ? element.Recommend.split(new RegExp(/,(?=\S)|:/, 'gm')) : []);
                caution = caution.concat(element.Caution ? element.Caution.split(new RegExp(/,(?=\S)|:/, 'gm')) : []);
                avoid = avoid.concat(element.ToRemove ? element.ToRemove.split(new RegExp(/,(?=\S)|:/, 'gm')) : []);
            });
            // filter by female pregnancy, breastfeeding
            if(this.state.answers.familyPlanning.indexOf('Female') > -1){
                //pregnancy 
                if(this.state.answers.familyPlanning.indexOf('Female and pregnancy is in my near future') > -1){
                    caution = caution.concat(this.state.pregnancyCaution.length ? this.state.pregnancyCaution.map(x => x.Name) : []);
                    avoid = avoid.concat(this.state.pregnancyAvoid.length ? this.state.pregnancyAvoid.map(x => x.Name) : []);
                }
                //breastfeeding
                if(this.state.answers.familyPlanning.indexOf('Female and pregnancy is in my near future') > -1){
                    caution = caution.concat(this.state.breastfeedCaution.length ? this.state.breastfeedCaution.map(x => x.Name) : []);
                    avoid = avoid.concat(this.state.breastfeedAvoid.length ? this.state.breastfeedAvoid.map(x => x.Name) : []);
                }
            }
            if(this.state.answers.familyPlanning.indexOf('Male') > -1){
                if(this.state.answers.familyPlanning.indexOf('Male and having children is in my near future') > -1){
                    caution = caution.concat(['Azulfidine (Sulfasalazine)']);
                }
            }
            // injection
            if(this.state.answers.injections === 'No way! I am terrified!'){
                caution = caution.concat(this.state.injectionAvoid.length ? this.state.injectionAvoid.map(x => x.Name) : []);
            }
            // latex allergy
            if(this.state.answers.latex === 'Yes'){
                avoid = avoid.concat(this.state.latexAvoid.length ? this.state.latexAvoid.map(x => x.Name) : []);
            }
            priority = this.unique(priority);
            caution = this.unique(caution);
            avoid = this.unique(avoid);
            consider = this.unique(consider);

            // filter out previous drugs
            let filtered = this.filterPrevCurrentTherapies(priority, consider, caution);
            consider = filtered.filteredConsider;
            priority = filtered.filteredPriority;
            let specials = this.checkSpecial();
            if(specials.length){
                console.log('specials', specials);
                specials.forEach(s => {
                    if(s.hasOwnProperty('firstLine')){
                        consider = s.firstLine.concat(consider);
                    }
                    if(s.hasOwnProperty('secondLine')){
                        consider = s.secondLine.concat(consider);
                    }
                    if(s.hasOwnProperty('thirdLine')){
                        consider = s.thirdLine.concat(consider);
                    }
                });
            }
            priority = this.unique(priority);
            caution = this.unique(caution);
            avoid = this.unique(avoid);
            consider = this.unique(consider);

            // Remove current medications from list
            priority = priority.filter(x => !this.state.answers.currentMedications.includes(x.Name));
            caution = caution.filter(x => !this.state.answers.currentMedications.includes(x.Name));
            avoid = avoid.filter(x => !this.state.answers.currentMedications.includes(x.Name));
            consider = consider.filter(x => !this.state.answers.currentMedications.includes(x.Name));

            this.createList(priority, 'priority', true);
            this.createList(caution, 'caution', false);
            this.createList(avoid, 'avoid', false);
            this.createList(consider, 'consider', false);
            this.checkConflicts();
        } else {
            this.setState({daEmpty: true, passed: true });
        }

    }
    unique = (list) => {
        return [...new Set(list)];
    }

    // filter previous or current therapies
    filterPrevCurrentTherapies = (priority, consider, caution) => {
        let filteredPriority = priority;
        let filteredConsider = consider;
        let toCaution = caution;
        //let filteredCaution = caution;
        if(this.state.biologicSystemicsToFilter.length){
            filteredPriority = priority.filter(e => {
                return this.state.biologicSystemicsToFilter.filter(e2 => {
                    if(e2 == e){
                        toCaution.push(e);
                        return e2;
                    }
                }).length == 0;
            });
    
            filteredConsider = consider.filter(e => {
                return this.state.biologicSystemicsToFilter.filter(e2 => {
                    if(e2 == e){
                        toCaution.push(e2);
                        return e2;
                    }
                }).length == 0;
            });
        }
        return { filteredPriority,  filteredConsider, toCaution };
    }
    // TODO: Remove
    checkSpecial = () => {
        
        // Topical therapy
        let specials = [];

        // 6------- //
        // add chronic plaque only && skin cancer
        if(this.state.answers.familyPlanning.indexOf('Female') > -1 
        //&& this.state.answers.familyPlanning === 'None of the above' 
        && this.state.answers.psoriasisType.indexOf('Psoriatic arthritis') === -1 
        && this.state.answers.comorbidities.indexOf('Non-melanoma skin cancers') > -1
        && this.state.answers.surfaceArea > 4) {
            console.log('PsOPlaqueMFNoChildHxSkinCancer5');
            const PsOPlaqueMFNoChildHxSkinCancer5 = {
                firstLine: ['Corticosteroids (if lipid panel is within appropriate limits)'],
                secondLine: [
                    // UV Available
                    'Phototherapy',
                    'Goeckerman',
                    // No UV
                    'Otezla® (Apremilast)','Siliq™ (Brodalumab)', 'Tremfya® (Guselkumab)','Taltz® (Ixekizumab)', 'Cosentyx® (Secukinumab)', 'Ilumya™ (Tildrakizumab)' 
                ],
                thirdLine: [
                    'Humira (Adalimumab)', 'Cimzia® (Certolizumab Pegol)', 'Enbrel® (Etanercept)', 'Remicade® (Infliximab)', 'Rheumatrex® (Methotrexate)', 
                    // Combo therapies
                    'Rheumatrex® (Methotrexate) + biologic',  'Biologic + UVB phototherapy', 
                    'Otezla® (Apremilast) + UVB phototherapy'
                ],
                warnings: 'Avoid using Cyclosporine and PUVA as they may increase the risk of melanoma and Non-melanoma skin cancer, especially in those with fair skin.'
            }
            specials.push(PsOPlaqueMFNoChildHxSkinCancer5);
        }
        if(this.state.answers.familyPlanning.indexOf('Male') > -1 
        && this.state.answers.psoriasisType.indexOf('Psoriatic arthritis') === -1 
        && this.state.answers.comorbidities.indexOf('Non-melanoma skin cancers') > -1
        && this.state.answers.surfaceArea > 4) {
            console.log('PsOPlaqueMFNoChildHxSkinCancer5');
            const PsOPlaqueMFNoChildHxSkinCancer5 = {
                firstLine: ['Corticosteroids (if lipid panel is within appropriate limits)'],
                secondLine: [
                    // UV Available
                    'Phototherapy', 'Phototherapy + Corticosteroids','Phototherapy + adjuvant topical agents',
                    'Goeckerman',
                    // No UV
                    'Otezla® (Apremilast)','Siliq™ (Brodalumab)', 'Tremfya® (Guselkumab)','Taltz® (Ixekizumab)', 'Cosentyx® (Secukinumab)', 'Ilumya™ (Tildrakizumab)' 
                ],
                thirdLine: [
                    'Humira® (Adalimumab)', 'Cimzia® (Certolizumab Pegol)', 'Enbrel® (Etanercept)', 'Remicade® (Infliximab)', 'Rheumatrex® (Methotrexate)', 
                    // Combo therapies
                    'Rheumatrex® (Methotrexate) + biologic',  'Biologic + phototherapy (only if absolutely necessary)', 
                    'Otezla® (Apremilast) + phototherapy'
                ],
                warnings: 'Avoid using Cyclosporine and PUVA as they may increase the risk of melanoma and Non-melanoma skin cancer, especially in those with fair skin.'
            }
            specials.push(PsOPlaqueMFNoChildHxSkinCancer5);
        }
    
        // 8------- //
        // add scalp psoriasis
        if(this.state.answers.familyPlanning.indexOf('Female and pregnancy is not in my near future') === -1
        && this.state.answers.psoriasisType.indexOf('Psoriatic arthritis') === -1 
        && this.state.answers.affectedArea.indexOf('Scalp')
        ) {
            console.log('PsOScalpMFNoChild');
            const PsOScalpMFNoChild = {
                firstLine: [
                    'Medicated shampoos including Coal Tar, salicylic acid, selenium, topical steroid, zinc or ketoconazole', 'Topical corticosteroids', 
                    'Coal Tar', 'Dovonex® (Calcipotriene)', 'Tazorac® (Tazarotene)', 'Dritho-Creme HP; Zithranol (Anthralin)'
                ],
                secondLine: [
                    'Humira® (Adalimumab)', 'Otezla® (Apremilast)', 'Siliq™ (Brodalumab)', 'Cimzia (Certolizumab Pegol)', 'Cyclosporine', 'Enbrel® (Etanercept)', 'Tremfya® (Guselkumab)',
                    'Remicade® (Infliximab)', 'Taltz® (Ixekizumab)', 'Rheumatrex® (Methotrexate)', 'Cosentyx® (Secukinumab)', 'Corticosteroids', 'UVB Phototherapy', 'Ilumya™ (Tildrakizumab)', 'Stelara® (Ustekinumab)'
                ]
            }
            specials.push(PsOScalpMFNoChild);
    
        }
        if(this.state.answers.familyPlanning.indexOf('Male') > -1
        && this.state.answers.psoriasisType.indexOf('Psoriatic arthritis') === -1 
        && this.state.answers.affectedArea.indexOf('Scalp')
        ) {
            console.log('PsOScalpMFNoChild');
            const PsOScalpMFNoChild = {
                firstLine: [
                    'Medicated shampoos including Coal Tar, salicylic acid, selenium, topical steroid, zinc or ketoconazole Topical steroids', 
                    'Coal Tar', 'Dovonex® (Calcipotriene)', 'Tazorac® (Tazarotene)', 'Coal Tar'
                ],
                secondLine: [
                    'Humira® (Adalimumab)', 'Otezla® (Apremilast)', 'Siliq™ (Brodalumab)', 'Cimzia® (Certolizumab Pegol)', 'Cyclosporine', 'Enbrel® (Etanercept)', 'Tremfya® (Guselkumab)',
                    'Remicade® (Infliximab)', 'Taltz® (Ixekizumab)', 'Rheumatrex® (Methotrexate)', 'Cosentyx® (Secukinumab)', 'Corticosteroids', 'Phototherapy', 'Ilumya™ (Tildrakizumab)', 'Stelara® (Ustekinumab)'
                ]
            }
            specials.push(PsOScalpMFNoChild);
        }
        
        console.log('specials', specials);
        return specials;
    }

    renderDescription = () => {
        return (
            <div>
                <Col sm={{ span: 20, offset: 2}} md={{ span: 24, offset: 0}}>
                <p>Methotrexate is a powerful type of medicine called an antimetabolite that has been prescribed to people living with psoriasis.</p>
                </Col>
                
            </div>
        )
    }

    showMoreBtn = (show, count) => {
        return count > 3 ? (<div className="text-center">
        <Button onClick={() => this.toggleShowAll()}>{ this.state[show] ? 'Show Less' : 'View All'}</Button>
        </div>): ''
    }

    uniqify = (array, key) => array.reduce((prev, curr) => prev.find(a => a[key] === curr[key]) ? prev : prev.push(curr) && prev, []);


    createList = (therapies, list, type, isPriority) => {
        //console.log(therapies, list);
        //console.log(isPriority);
        let newList = [];
        let priorityList = [];
        let show = '';
        let style = '';
        let title = '';
        let specialFocus = '';

        list = list.filter(Boolean)
        list.forEach(l => {
            let found = false;
            therapies.forEach(t => {
                let topical = false;
                let newl = '';
                if(l.toLowerCase().indexOf('topical') > -1){
                    newl = l.toLowerCase().replace('topical', '').trim();
                    topical = true;
                }
                if(l != ''){
                    if(t.DisplayName.toLowerCase().indexOf(l.toLowerCase()) > -1){
                            t.Name = topical ? 'Topical ' + t.DisplayName : t.DisplayName;
                            t.isPriority = isPriority;
                            isPriority ? priorityList.push(t) : newList.push(t);
                            
                            found = true;
                    }
                }
            });
            if(!found){
                let obj = { Name: l, DisplayName: l, slug: '', TherapyFor: '', TherapyType: 'Other', isPriority };
                //obj.isPriority = isPriority ? true : false;
                isPriority ? priorityList.push(obj) : newList.push(obj);
            }
        });

        //newList = this.uniqify(newList, 'Name');
        priorityList.sort(this.compareValues('Name'));
        newList.sort(this.compareValues('Name'));
        return priorityList.concat(newList);

        //this.setState({ [type]: priorityList.concat(newList) });

    }

    renderLink = (item, type) => {
        //console.log('item', item);
        let warningsText = '';
        let warnings = [];
        if(this.state.warnings) {
            warnings = this.state.warnings.filter(w => w.id === item.DisplayName);
        } 
        
        console.log(warnings);
        let style = item.isPriority ? 'priority' : '';
        let link = item.Slug ? <Link className={`text-wrap ${style}`} to={`/treatment/${item.Slug}`}>{item.DisplayName}</Link> : <span className='text-wrap' >{item.DisplayName}</span>;
        if (item.Slug) {
            if (item.isPriority && type === 'consider') {
                link = <div className='priorityItem'><Link className={`text-wrap ${style}`} to={`/treatment/${item.Slug}`}>{item.DisplayName}</Link></div>;
            } else {
                console.log('slug', item, 'warnings', warnings)
                if(warnings && warnings.length){
                    //console.log('found');
                    warnings.forEach(x => {
                        warningsText += x.text;
                    })
                    let warningInfo = <Popover content={<Paragraph>{warningsText}</Paragraph>} title="">
                                &nbsp;<Icon type="info-circle" />
                            </Popover>
                    link = <div ><Link className={`text-wrap ${style}`} to={`/treatment/${item.Slug}`}>{item.DisplayName}</Link>{warningInfo}</div>;
                }else{
                    link = <Link className={`text-wrap ${style}`} to={`/treatment/${item.Slug}`}>{item.DisplayName}</Link>;
                }
                
            }
        } else {
            
            let otherInfo = '';
            if (this.state.othersAll.find(x => x.DisplayName === item.DisplayName)) {
                let description = `This therapy is not specific to Psoriasis or Psoriatic Arthritis but has shown to alleviate symptoms.`;
                otherInfo = <Popover content={<Paragraph>{description}</Paragraph>} title="">
                    &nbsp;<Icon type="info-circle" />
                </Popover>
            }
            if (item.isPriority && type === 'consider') {
                link = <div className='priorityItem'><span className='text-wrap' >{item.DisplayName}</span>{otherInfo}</div>;
            } else {
                let linkText = item.DisplayName;
                link = this.state.therapies.forEach(t => {
                    let txtPos = linkText.indexOf(t.DisplayName);
                    if(txtPos > -1){
                        linkText = reactStringReplace(linkText, t.DisplayName, (match, i) => (<Link key={t.Slug} to={'/treatment/' + t.Slug}>{match}</Link> ))
                    }
                });
                link = <div><span className='text-wrap' >{linkText}</span>{otherInfo}</div>
            }

        }
        return link;
    }
    renderList = (list, type) => {
        let style = '';
        let title = '';
        let priorityTitle = '';
        let svg = '';
        switch (type) {
            case 'consider':
                style = 'result-green';
                title = 'Therapies to consider prioritizing in your list';
                priorityTitle = <div>This symbol&nbsp;&nbsp;&nbsp;<span className='priorityItem'> indicates a priority therapy</span></div>;
                svg = 'therapies-to-consider-blue.svg';
                break;
            case 'caution':
                style = 'result-yellow';
                title = 'Therapies that need further discussion with your healthcare provider before choosing';
                svg = 'therapies-to-discuss-blue.svg';
                break;
            case 'avoid':
                style = 'result-red';
                title = 'Therapies that your healthcare provider may not want to choose';
                svg = 'therapies-to-avoid-blue.svg';
                break;
            default:
                break;
        }
        return list.length ? (
        <Col sm={{ span: 23, offset: 0}} md={{ span: 8, offset: 0}} >
            <div className="homeIconContainer ">
                <img style={{ width: '50%' }} className='mb-0' alt='Treatment Education' src={require("../../icons/" + svg)}/>
            </div>
            <h4 className="pageTitle daInfoBorder pt-1">{title}</h4>
            <div style={{ width: '100%' }} className={`ant-tag ${style} p-1 m-0`}><List
                size={'small'}
                header={ priorityTitle ? <div>
                    {priorityTitle}
                </div> : ''}
                itemLayout="horizontal"
                //dataSource={this.state[show] ? list : list.slice(0, 3)}
                dataSource={list}
                renderItem={(item, i) => { 
                    let link = this.renderLink(item, type);
                    return (
                    //to = item.Slug ? `/drug/${item.Slug}` : ``
                   // i === 0 && type === 'focus' ?
                    
                    // <List.Item>
                    //     <List.Item.Meta title={ <div><h4>First Line</h4>{link}</div> } />
                    // </List.Item>
                   // :
                        <List.Item>
                            <List.Item.Meta title={link} />
                        </List.Item>
                )}}
            />
            {
                //this.showMoreBtn(show, count)
            }
            </div>
        </Col>
        
       ): ''
    }

    checkConflicts = (results) => {
        //console.log('conflicts', this.state);
        let filteredPriority = results.priority.filter(e => {
            return results.avoid.filter(e2 => {
                return e2.Name == e.Name
            }).length == 0;
        });

        let filteredFocus = results.consider.filter(e => {
            return results.avoid.filter(e2 => {
                return e2.Name == e.Name
            }).length == 0;
        });

        let filteredCaution = results.caution.filter(e => {
            return results.avoid.filter(e2 => {
                return e2.Name == e.Name
            }).length == 0;
        });

        filteredFocus = filteredFocus.filter(e => {
            return filteredCaution.filter(e2 => {
                return e2.Name == e.Name
            }).length == 0;
        });

        filteredPriority = filteredPriority.filter(e => {
            return filteredCaution.filter(e2 => {
                e.isPriority = true;
                return e2.Name == e.Name
            }).length == 0;
        });

        filteredFocus = filteredFocus.filter(e => {
            return filteredPriority.filter(e2 => {
                return e2.Name == e.Name
            }).length == 0;
        });
        
        filteredFocus = filteredPriority.concat(filteredFocus);

        this.setState({ warnings: results.warnings, priority: filteredPriority, consider: filteredFocus, caution: filteredCaution, avoid: results.avoid, passed: true, loading: false });
    }
    renderHeader = () => {
        return (
            <Row>
            <Col>
                
                <h1 style={{ textTransform: 'uppercase'}}>Decision Aid Recommendations</h1>
                <hr style={{ margin: '0 -16px', borderTop: '10px solid #1a3955' }}/>
                <div className='container1200 center mt-1'>
                <Row gutter={32}>
                <Col className={'mb-md-1'} xs={{ span: 20, offset: 2}} sm={{ span: 12, offset: 0}} md={{ span: 12, offset: 0}} lg={{ span: 8, offset: 0}} >
                    <img style={{ width: '100%' }} className='mb-0' alt='Treatment Education' src={require("../../icons/therapies-to-discuss-blue.svg")}/>
                    </Col>
                    <Col className={'mb-md-1'} xs={{ span: 20, offset: 2}} sm={{ span: 12, offset: 0}} md={{ span: 12, offset: 0}} lg={{ span: 16, offset: 0}} >
                    <h4 className="text-left">Decision Aid Recommendations</h4>
                    <Alert message={<p style={{ margin: 0}}>Please fill out this <Link to={'/dareview'} target='_blank' >survey</Link> and bring to your provider (as a printout or screenshot)</p>} type="error" />

                    {/* <p>Please fill out this <Link to={'/dareview'} target='_blank' >survey</Link> and bring to your provider (as a printout or screenshot)</p> */}
                    <p className={"mt-1"}>Based on your disease features, psoriasis severity and concomitant conditions, we have compiled a list of potential treatments for you, listed in alphabetical order in each category.  This is meant to help you learn about your potential therapy options.  The computer doesn't know the ins and outs of you in the same way that your physician does.  Please consult your physician about these treatments to see what is best for you. This is meant to help you learn about your potential therapy options. It does not replace the advice or guidance of your physician or health care provider.</p>
                    </Col>
                </Row>
                
                
                </div>
                
            </Col>

        </Row>
        )
    }

    getIcon = (therapyType) => {
        let icon = '';
        switch (therapyType) {
            case 'Biologic':
                icon = `biologics.svg`;
                break;
            case 'Oral Systemic':
                icon = `systemic.svg`;
                break;
            case 'Phototherapy':
                icon = `sun-beams.svg`;
                break;
            case 'Topical':
                icon = `cream.svg`;
                break;
            default:
                break;
        }
        return icon;
    }

    renderResultMatrixList = (rawlist, data) => {
        //console.log('rawlist', rawlist);
        let type = data.name;
        let list = rawlist.filter(r => r.TherapyType === type);
        //console.log('list ' + type + ' ', rawlist, data);
        return list.length ? (
            <div>
            {this.renderMatrixIcon(data)}
            <List
                size={'small'}
                //header={ priorityTitle ? <div>{priorityTitle}</div> : ''}
                itemLayout="horizontal"
                //dataSource={this.state[show] ? list : list.slice(0, 3)}
                dataSource={list}
                renderItem={(item, i) => { 
                    let link = this.renderLink(item, type);
                    return (
                    //to = item.Slug ? `/drug/${item.Slug}` : ``
                   // i === 0 && type === 'focus' ?
                    
                    // <List.Item>
                    //     <List.Item.Meta title={ <div><h4>First Line</h4>{link}</div> } />
                    // </List.Item>
                   // :
                    <List.Item>
                        <List.Item.Meta title={ link } />
                    </List.Item>
                )}}
            />
            </div>
        ): <div>{this.renderMatrixIcon(data)}None</div>
    }

    renderMatrixIcon = (data) => {
        return <div className={'center text-center matrixIcon'}><img  src={require(`../../icons/` + data.icon)} /><p className={'center text-center'}>{data.name}</p></div>
    }

    renderResultMatrix = () => {
        console.log('final priority', this.state.priority);
        console.log('final consider', this.state.consider);
        console.log('final caution', this.state.caution);
        console.log('final avoid', this.state.avoid);
        const types = [{ name: 'Biologic', icon: 'biologics.svg' }, { name: 'Topical', icon: 'cream.svg' }, { name: 'Oral Systemic', icon: 'systemic.svg' },
         { name: 'Phototherapy', icon: 'sun-beams.svg' }, { name: 'Other', icon: 'more.svg' }];
        return !this.state.loading && this.state.passed  ? (
            <div >
                <Row >
                    <Col xs={{ span: 0, offset: 0}} md={{ span: 0, offset: 0}} lg={{ span: 4, offset: 0}} >

                    </Col>
                    {
                        types.map((t, i) => {
                            return (
                                <Col key={i} style={{ padding: '0 5px'}} xs={{ span: 0, offset: 0}} sm={{ span: 0, offset: 0}} md={{ span: 0, offset: 0}} lg={{ span: 4, offset: 0}} >
                                    {this.renderMatrixIcon(t)}
                                </Col>
                            )
                        })
                    }
                </Row>
                <Row id='resultMatrix' className='darConsider' type={'flex'}>
                    <Col className='matrixColTop' xs={{ span: 23, offset: 0}} md={{ span: 8, offset: 0}} lg={{ span: 4, offset: 0}} >
                    <div className="homeIconContainer col-flex-top" style={{ padding: '0 5px'}}>
                        {/* <img style={{ width: '50%', alignSelf: 'center' }} className='mb-0' alt='Treatment Education' src={require("../../icons/therapies-to-consider-blue.svg")}/> */}
                        <h4 className="pageTitle pt-1">Therapies to consider prioritizing in your list</h4>
                    </div>
                    </Col>
                    {
                        types.map((t, i) => {
                            return (
                                <Col key={i} className='matrixColTop' xs={{ span: 23, offset: 0}} md={{ span: 8, offset: 0}} lg={{ span: 4, offset: 0}} >
                                <div className='col-flex'>{this.renderResultMatrixList(this.state.consider, t)}</div>
                                </Col>
                            )
                        })
                    }
                    
                </Row>
                <Row className='darDiscuss' type={'flex'}>
                    <Col className='matrixCol' xs={{ span: 23, offset: 0}} md={{ span: 8, offset: 0}} lg={{ span: 4, offset: 0}} >
                    <div className="homeIconContainer col-flex-top" style={{ padding: '0 5px'}} >
                        {/* <img style={{ width: '50%', alignSelf: 'center' }} className='mb-0' alt='Treatment Education' src={require("../../icons/therapies-to-discuss-blue.svg")}/> */}
                        <h4 className="pageTitle pt-1">Therapies that need further discussion with your healthcare provider before choosing</h4>
                    </div>
                    </Col>
                    {
                        types.map((t, i) => {
                            return (
                                <Col key={i} className='matrixCol' xs={{ span: 23, offset: 0}} md={{ span: 8, offset: 0}} lg={{ span: 4, offset: 0}} >
                                <div className='col-flex'>{this.renderResultMatrixList(this.state.caution, t)}</div>
                                </Col>
                            )
                        })
                    }
                </Row>
                <Row  className='darAvoid' type={'flex'}>
                    <Col className='matrixCol' xs={{ span: 23, offset: 0}} md={{ span: 8, offset: 0}} lg={{ span: 4, offset: 0}} >
                    <div className="homeIconContainer col-flex-top" style={{ padding: '0 5px'}}>
                        {/* <img style={{ width: '50%', alignSelf: 'center' }} className='mb-0' alt='Treatment Education' src={require("../../icons/therapies-to-avoid-blue.svg")}/> */}
                        <h4 className="pageTitle pt-1">Therapies that your healthcare provider may not want to choose</h4>
                    </div>
                    </Col>
                    {
                        types.map((t, i) => {
                            return (
                                <Col key={i} className='matrixCol' xs={{ span: 23, offset: 0}} md={{ span: 8, offset: 0}} lg={{ span: 4, offset: 0}} >
                                <div className='col-flex'>{this.renderResultMatrixList(this.state.avoid, t)}</div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        
            ) : !this.state.daEmpty ?
            (
            <Row gutter={8}>
                <Col className={'p-2'}>
                    <div className="spinnerWrap"><Spin size="large" tip="Loading..." /></div>
                </Col>
            </Row>
            ): '';
    }

    renderError = () => {
        return this.state.daEmpty ? (
            <div className="container800 center">
            <Row>
            <Col className={''}>
            <Alert message={<p style={{ margin: 0}}>We do not have enough information to make recommendations. <Link to='/decision'>Please try the decision aid again.</Link></p>} type="error" />
                {/* <p>We do not have enough information to make recommendations. <Link to='/decision'>Please try the decision aid again.</Link></p> */}
            </Col>
            </Row>
            </div>
        ) : '';
    }

    renderPDF = () => {
        const options = {
            scrollX: 0,
            scrollY: 0,
            //allowTaint: true,
            //foreignObjectRendering: true,
            useCORS:true
        }   
        html2canvas(document.querySelector('#resultMatrix'), options).then(canvas => {
            var img = canvas.toDataURL(); 
            var iframe = "<iframe width='100%' height='100%' src='" + img + "'></iframe>"
            var x = window.open();
            x.document.open();
            x.document.write(iframe);
            x.document.close();
            //window.open(img);
            //document.body.appendChild(canvas);
        });
    }

    renderSurveyLinks = () => {
        return (
            <div style={{ margin: '32px' }} >
                <Row gutter={32} className='home-row' type={'flex'}>
                    <Col sm={{ span: 20, offset: 2 }} md={{ span: 8, offset: 0 }} >
                    <div className='col-flex'>
                        <div className={`ant-btn ant-btn-primary ant-btn-lg ant-btn-block div-btn`} onClick={() => this.props.history.push('/rapid3')} block><div>Survey for joint function</div></div>
                        </div>
                    </Col>
                    <Col sm={{ span: 20, offset: 2 }} md={{ span: 8, offset: 0 }} >
                    <div className='col-flex'>
                        <div className={`ant-btn ant-btn-primary ant-btn-lg ant-btn-block div-btn`} onClick={() => this.props.history.push('/dlqi')} >Survey for how skin affects quality of life</div>
                        </div>
                    </Col>
                    <Col sm={{ span: 20, offset: 2 }} md={{ span: 8, offset: 0 }} >
                    <div className='col-flex'>
                        <div className={`ant-btn ant-btn-primary ant-btn-lg ant-btn-block div-btn`} onClick={() => this.props.history.push('/dlqi')} >Treatment satisfaction questionnaire for medication</div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
 
    render(){  
        console.log('state', this.state);
        return  (
            <div>
            <div className="">
                {this.renderHeader()}
            </div>
            <div className='mx-1 daResultWrapper'>
                
                {this.renderResultMatrix()}
                {this.renderError()}
            </div>
                {/* <Row>
                    <Col>
                        <Link to='/rapid3'>Survey for joint function</Link><br />
                        <Link to='/dlqi'>Survey for how skin affects quality of life</Link><br />
                        <Link to='/dlqi'>Treatment satisfaction questionnaire for medication</Link>
                    </Col>
                </Row> */}
                {
                    //this.renderSurveyLinks()
                    }
            </div>
        )
    }
}

export default Results;

