export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_INIT = 'AUTH_INIT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const FETCH_REPORTS = 'FETCH_REPORTS';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS';
export const ADD_REPORT_MONTHLY_SUCCESS = 'ADD_REPORT_MONTHLY_SUCCESS';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const UPDATE_REPORT_MONTHLY = 'UPDATE_REPORT_MONTHLY';