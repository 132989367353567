import React from 'react';
import { Row, Col  } from 'antd';
import { Link } from 'react-router-dom'
import SocialShare from './SocialShare';

const creativeCommonsUrl = "http://creativecommons.org/licenses/by-nc-nd/4.0/";

const Footer = () => {
    return (
        <footer>
            <Row  style={{ paddingTop: '2em'}}>
                <Col className='text-center' sm={{ span: 24, offset: 0}} md={{ span: 12, offset: 0}}>
                <img className='fullLogo' alt='PIAP Logo' src={require("../../images/logo/gray-logo-full.svg")}/>
                </Col>
                
                <Col className='text-right' sm={{ span: 24, offset: 0}} md={{ span: 12, offset: 0}}>
                    <div className="mr-1">
                    <ul  className="listNoStyle mb-0">
                        <li><Link to={'/contact'}>Contact Us</Link></li>
                        {/* <li><Link to={'/admin'}>Provider Login</Link></li> */}
                    </ul>
                    <ul className="listNoStyle mb-0">
                        <li><a href="https://www.pennmedicine.org/for-patients-and-visitors/patient-information/hipaa-and-privacy">Privacy</a></li>
                        <li><a href="https://www.pennmedicine.org/legal-disclaimer">Legal Disclaimer</a></li>
                    </ul>
                    <p>Penn Inflammatory Arthritis Program &copy; {new Date().getFullYear()}</p>
                    <p><a rel="license" href={creativeCommonsUrl}><img alt="Creative Commons License" src="https://i.creativecommons.org/l/by-nc-nd/4.0/80x15.png" /></a><br/>This work is licensed under a <a href={creativeCommonsUrl}>Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 Internationl License</a></p>
                    </div>
                </Col>
            </Row>
            <Row className="">
                <SocialShare />
            </Row>
            <div style={{ clear: 'both'}} />
        </footer>
    )
}

export default Footer;
