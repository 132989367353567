import React, { Component } from 'react';
import LeftMenu from './LeftMenu'
import RightMenu from './RightMenu'
import { Link } from 'react-router-dom';
import { Drawer, Icon } from 'antd';
//import Logo2 from '../../images/complete-logo.svg';
import Logo from '../common/Logo';

class Navbar extends Component {
	state = {
		current: 'mail',
		visible: false
	}

	showDrawer = () => {
		this.setState({
			visible: true,
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	changeFont = () => {
        let bodyClasses = document.querySelector('body');
        //console.log(bodyClasses);
        if (bodyClasses.classList.contains('font-medium')) {
            //console.log('med');
            bodyClasses.classList.remove('font-medium');
            bodyClasses.classList.add('font-large');
            return;
        }
        if (bodyClasses.classList.contains('font-large')) {
            bodyClasses.classList.remove('font-large');
            return;
        }
        if (!bodyClasses.classList.contains('font-large') && !bodyClasses.classList.contains('font-medium')) {
            bodyClasses.classList.add('font-medium');
            return;
        }
    }

	render() {
		return (
			<nav className="menuBar">
				<div className="logoContainer">
					<Link to={'/'}>
						{/* <img className="logo" src={Logo2} alt="PIAP"/> */}
						<Logo />
					</Link>
				</div>
				<div className="menuCon">
					<div className="leftMenu">
						<LeftMenu click={() => this.onClose()} mode='horizontal' />
					</div>
					<div className="rightMenu">
						<RightMenu  click={() => this.onClose()} mode='horizontal' />
						<Icon type="font-size" style={{ fontSize: '20px', marginTop: '24px', marginLeft: '10px' }} onClick={() => this.changeFont()} />
					</div>
					<a className="barsMenu" href='#' onClick={this.showDrawer}>
						<span onClick={this.showDrawer} >MENU</span>
					</a>

					<Drawer
						placement="right"
						closable={false}
						onClose={this.onClose}
						visible={this.state.visible}
					>
						<RightMenu mode='vertical' click={() => this.onClose()} />
						<div className='clearfix' />
						<Icon type="font-size" style={{ float: 'right', display: 'block', fontSize: '20px', marginTop: '24px', marginRight: '40px' }} onClick={() => this.changeFont()} />
						<LeftMenu mode='inline' click={() => this.onClose()} />
					</Drawer>

				</div>
			</nav>
		);
	}
}

export default Navbar;