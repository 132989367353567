import React from 'react';
import { Collapse, Icon, Row, Col, Input } from 'antd';
import BreadcrumbMaker from './common/BreadcrumbMaker';

const renderBreadcrumbs = () => {
    const arr = [
        { route: '/', name: 'Home' },
        { route: '', name: 'Learn More' },
        { route: '', name: 'FAQ' }
    ];
    return BreadcrumbMaker(arr);
}
const Panel = Collapse.Panel;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const customPanelStyle = {
    background: '#ededed',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};

const FAQ = () => {
    return (
        <div className="container800 center mt-2">
        {renderBreadcrumbs()}
        <Row>
            <Col >
        <h1 className="pageTitle">FAQ</h1>
        <Input style={{ width: '300px', marginBottom: '2em'}} placeholder="Search the FAQ" />
        </Col>
        </Row>
        <Row>
            <Col >
                <Collapse
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                >
                    <Panel header="Where can I find more information on Psoriasis?" key="1" style={customPanelStyle}>
                        <p>{text}</p>
                    </Panel>
                    <Panel header="Where can I find more information on Psoriasis?" key="2" style={customPanelStyle}>
                        <p>{text}</p>
                    </Panel>
                    <Panel header="Where can I find more information on Psoriasis?" key="3" style={customPanelStyle}>
                        <p>{text}</p>
                    </Panel>
                </Collapse>
            </Col>
        </Row>
        
        </div>
    )
}

export default FAQ;
