import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router';
import { Layout, BackTop } from 'antd';

import Footer from './components/common/Footer';
import Home from './components/Home';
import ScrollToTop from './components/common/ScrollToTop';
import Navbar from './components/navbar';
import FAQ from './components/FAQ';
import Decision from './components/Decision';
import Overview from './components/Overview';
import TreatmentEdu from './components/TreatmentEdu';
import Advocacy from './components/Advocacy';
import Glossary from './components/Glossary';
import Morbidity from './components/Morbidity';
import Therapy from './components/Therapy';
import Contact from './components/Contact';
import Results from './components/decision/Results';
import Admin from './components/Admin';
import Resources from './components/Resources';
import DLQI from './components/DLQI';
import Rapid3 from './components/Rapid3';
//import DAReviewPDF from './components/decision/DAReviewPDF';
//import DAReview from './components/decision/DAReview';
import Treatment from './components/Treatment';
import PracticalTips from './components/PracticalTips';

import WrappedLogin from './components/Login';
import WrappedRegisterUser from './components/RegisterUser';

const DAReviewPDF = lazy(() => import('./components/decision/DAReviewPDF'));
const DAReview = lazy(() => import('./components/decision/DAReview'));

const AdminTherapyList = lazy(() => import('./components/admin/AdminTherapyList'));
const AdminTherapy = lazy(() => import('./components/admin/AdminTherapy'));
const AdminContactList = lazy(() => import('./components/admin/AdminContactList'));
const AdminContact = lazy(() => import('./components/admin/AdminContact'));
const AdminCDList = lazy(() => import('./components/admin/AdminCDList'));
const AdminCD = lazy(() => import('./components/admin/AdminCD'));
const AdminOtherList = lazy(() => import('./components/admin/AdminOtherList'));
const AdminOther = lazy(() => import('./components/admin/AdminOther'));

// import AdminTherapyList from './components/admin/AdminTherapyList';
// import AdminTherapy from './components/admin/AdminTherapy';
// import AdminContactList from './components/admin/AdminContactList';
// import AdminContact from './components/admin/AdminContact';

// import AdminCDList from './components/admin/AdminCDList';
// import AdminCD from './components/admin/AdminCD';
// import AdminOtherList from './components/admin/AdminOtherList';
// import AdminOther from './components/admin/AdminOther';
//import AdminComorbidity from './components/admin/AdminComorbidity';


class Router extends Component {
    
    
    render() {
        return (
            <ScrollToTop>
                <Layout>
                <BackTop />
            <Navbar />
            <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/faq' component={FAQ} />
                <Route path='/decision/result' component={Results} />
                <Route path='/decision' key={Math.random()}
                    render={(props) => <Decision mook={Math.random()} {...props} />}
                />
                <Route exact path='/overview' component={Overview} />
                <Route exact path='/TreatmentEdu' component={TreatmentEdu} />
                <Route exact path='/Treatment/:treatment' component={Treatment} />
                <Route exact path='/Treatments/:treatmentType/:treatment?' component={Therapy} />
                <Route exact path='/Advocacy' component={Advocacy} />
                <Route exact path='/WordsToKnow' component={Glossary} />
                <Route exact path='/Rapid3' component={Rapid3} />
                <Route exact path='/DLQI' component={DLQI} />
                <Route exact path='/DAReviewPDF' component={DAReviewPDF} />
                <Route exact path='/DAReview' component={DAReview} />

                <Route exact path='/Therapy' component={Therapy} />
                <Route exact path='/Contact' component={Contact} />
                <Route exact path='/HealthTips' component={Resources} />
                <Route exact path='/PracticalTips' component={PracticalTips} />

                <Route exact path='/Admin/Comorbidity' component={Morbidity} />
                <Route exact path='/Admin' component={Admin} />
                <Route exact path='/Admin/cdlist' component={AdminCDList} />
                <Route exact path='/Admin/cd/:cdId' component={AdminCD} />
                <Route exact path='/Admin/otherlist' component={AdminOtherList} />
                <Route exact path='/Admin/other/:otherId' component={AdminOther} />
                <Route exact path='/Admin/TherapyList' component={AdminTherapyList} />
                <Route exact path='/Admin/Therapy/:therapyId' component={AdminTherapy} />
                <Route exact path='/Admin/ContactList' component={AdminContactList} />
                <Route exact path='/Admin/Contact/:contactId' component={AdminContact} />

                <Route exact path='/register' component={WrappedRegisterUser} />
                <Route exact path='/login' component={WrappedLogin} />
            </Switch>
            </Suspense>
                    <Footer />
                </Layout>
            </ScrollToTop>
        );
    }

};

export default Router;
