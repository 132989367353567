import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import { withRouter } from 'react-router-dom';

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = { 

        };
    }
    render() {
        return (
            <div className="container800 center mt-2">
                <Row>
                    <h1 className="text-center">Admin</h1>
                </Row>
                <Row>
                    <Col>
                        <Card onClick={() => this.props.history.push(`/admin/cdlist`)}>
                            <h2>Comorbidites + Domains</h2>
                        </Card>
                        <Card onClick={() => this.props.history.push(`/admin/contactList`)}>
                            <h2>Contact</h2>
                        </Card>
                        <Card onClick={() => this.props.history.push(`/admin/therapyList`)}>
                            <h2>Therapies</h2>
                        </Card>
                        <Card onClick={() => this.props.history.push(`/admin/otherlist`)}>
                            <h2>Other (Not Linked)</h2>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(Admin);