import React, { Component } from 'react';
import { 
    Button, Row, Col, Radio, Card, Icon 
} from 'antd';
import PageTitle from './common/PageTitle';


const RadioGroup = Radio.Group;
const DLQIQAnswers = ['Very much', 'A lot', 'A little', 'Not at all'];
const DLQIQAnswers2 = ['Very much', 'A lot', 'A little', 'Not at all', 'Not relevant'];
const DLQIa = [
    { id: 'a', question: 'Over the last week, how itchy, sore, painful or stinging has your skin been?', type: 'radio', answers: DLQIQAnswers },
    { id: 'b', question: 'Over the last week, how embarrassed or self conscious have you been because of your skin?', type: 'radio', answers: DLQIQAnswers },
    { id: 'c', question: 'Over the last week, how much has your skin interfered with you going shopping or looking after your home or garden?', type: 'radio', answers: DLQIQAnswers2 },
    { id: 'd', question: 'Over the last week, how much has your skin influenced the clothes you wear?', type: 'radio', answers: DLQIQAnswers2 },
    { id: 'e', question: 'Over the last week, how much has your skin affected any social or leisure activities?', type: 'radio', answers: DLQIQAnswers2 },
    { id: 'f', question: 'Over the last week, how much has your skin made it difficult for you to do any sport?', type: 'radio', answers: DLQIQAnswers2 },
    { id: 'ga', question: 'Over the last week, has your skin prevented you from working or studying?', type: 'radio', answers: ['Yes', 'No'] },
    { id: 'gb', question: 'If "No", over the last week how much has your skin been a problem at work or studying?', type: 'radio', answers: DLQIQAnswers2 },
    { id: 'h', question: 'Over the last week, how much has your skin created problems with your partner or any of your close friends or relatives?', type: 'radio', answers: DLQIQAnswers2 },
    { id: 'i', question: 'Over the last week, how much has your skin caused any sexual difficulties?', type: 'radio', answers: DLQIQAnswers2 },
    { id: 'j', question: 'Over the last week, how much of a problem has the treatment for your skin been, for example by making your home messy, or by taking up time?', type: 'radio', answers: DLQIQAnswers2 },
];

class DLQI extends Component {
    constructor(props){
        super(props);
        this.state = {
            DLQIAnswers: { a: '', b: '', c: '', d: '', e: '', f: '', ga: '', gb: '', h: '', i: '', j: '' },
            submitted: false
        }
    }

    onDLQIChangeRadio = (e) => {
        let DLQIAnswers = this.state.DLQIAnswers;
        DLQIAnswers[e.target.name] = e.target.value;
        this.setState({ DLQIAnswers });
    }

    showTherapy = () => {
        localStorage.setItem('dlqi', JSON.stringify(this.state.DLQIAnswers));
        this.setState({
            DLQIAnswers: { a: '', b: '', c: '', d: '', e: '', f: '', ga: '', gb: '', h: '', i: '', j: '' },
        }, () => {
            this.props.history.push(`/decision/result`);
        });
    }

    renderDLQI = () => {
        return (
            <div>
                <h4>Below are a list of questions about how your psoriasis affects your quality of life which will produce a score that may be helpful in the discussion about therapies with your provider. This validated questionnaire is called Dermatology Life Quality Index (DLQI).</h4>
               {
                DLQIa.map((r, i) => {
                    //let id = r.id;
                    let j = i + 1;
                    let number = i + 1;
                    if(j === 7){
                        number = '7a';
                    }
                    if(j === 8){
                        number = '7b';
                    }
                    if(j > 8){
                        number = j - 1;
                    } 

                    return (
                        <div key={r.id} className={'mt-1'}>
                            <p style={{marginBottom: 0}}>{number}. {r.question}</p>
                        
                            <RadioGroup  name={r.id} onChange={this.onDLQIChangeRadio} buttonStyle='solid' size='large' value={this.state.DLQIAnswers[r.id]} >
                            {
                                r.answers.map( (r2, i) => {
                                    return <Radio className='verticalRadio ml-1' key={i} value={r2}>{r2}</Radio>
                                })
                            }
                            </RadioGroup>
                        </div>
                    )
                })
               } 
            </div>
        )
    }

    getDLQIScore = () => {
        const scores = {
            'Very Much': 3,
            'A lot': 2,
            'A little': 1,
            'Not at all': 0,
            'Not relevant': 0
        };
        
        let total = 0;
        for (var key in this.state.DLQIAnswers) {
            if (this.state.DLQIAnswers.hasOwnProperty(key)) {
                let val = scores[this.state.DLQIAnswers[key]];
                if(val){
                    total += val;
                }
            }
        }
        let text = '';
        switch (true) {
            case (total <= 1):
                text = `No effect at all on patient's life`;
                break;
            case (total >= 2 && total <= 5):
                text = `Small effect on patient's life`;
                break;
            case (total >= 6 && total <= 10):
                text = `Moderate effect on patient's life`;
                break;
            case (total >= 11 && total <= 20):
                text = `Very large effect on patient's life`;
                break;
            case (total >= 21 && total <= 30):
                text = `Extremely large effect on patient's life`;
                break;
            default:
                text = '';
                break;
        }
        return (
            <div className='mt-1'>
                <Row>
                    <Col>
                        <p>Psoriasis Quality of life (DLQI) Score: {total}<br/>{text}</p>
                        <Button size={'large'} className="right mt-2" type="primary" onClick={this.resetForm}>
                        Take the questionnaire again
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
    showResults = () => {
        this.setState({ submitted: true });
    }
    resetForm = () => {
        this.setState({
            DLQIAnswers: { a: '', b: '', c: '', d: '', e: '', f: '', ga: '', gb: '', h: '', i: '', j: '' },
            submitted: false
        });
    }
    
    render(){ 
        return (
            <div>
                <PageTitle title={'DLQI'} subTitle={``} />
                <div  className="container800 center mt-2">
                <Card className="center mt-2 daCard" hoverable bordered>
                    <Row>
                        <Col>
                            {!this.state.submitted && this.renderDLQI()}
                            {!this.state.submitted && <Button size={'large'} className="right mt-2" type="primary" onClick={this.showResults}>
                                Submit<Icon type="right" />
                            </Button>}
                            
                        </Col>
                    </Row>
                    {this.state.submitted && this.getDLQIScore()}
                    </Card>
                </div>
            </div>
        )
    }

}

export default DLQI;
