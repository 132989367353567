import React from 'react';
import { Row, Col } from 'antd';
import PageTitle from './common/PageTitle';
import BreadcrumbMaker from './common/BreadcrumbMaker';

const renderBreadcrumbs = () => {
    const arr = [
        { route: '/', name: 'Home' },
        //{ route: '', name: 'Learn More' },
        { route: '', name: 'Health Tips' }
    ];
    return BreadcrumbMaker(arr);
}

const Resources = () => {
    return (
        <div >
        {renderBreadcrumbs()}
        <PageTitle title={'Health Tips'} subTitle={``} />
        <div  className="container800 center mt-2">
        <Row>
            <Col>
                <p>If you have psoriasis or psoriatic arthritis, you probably have many questions.</p>
                <p><strong>How long will this last?</strong><br/>
                Both psoriasis and psoriatic arthritis are chronic diseases. A chronic disease is with you always. It cannot be cured. BUT . . . chronic diseases can be managed. Both psoriasis and psoriatic arthritis are not simple diseases, you cannot take a pill and make them go away. They can be experienced a bit differently for each person.</p>
                <p><strong>Can anything be done?</strong><br/>The good news is that you can get help to treat and to manage your psoriasis and psoriatic arthritis. There are many types of treatments. Medicine is just one of them. Your doctor may have to try a few medicines before finding the one which works for you.</p>
                
                <h2>Take Care and Take Action</h2>
                <div>
                Experts talk about four basic care and action steps: 
                <ol>
                    <li><strong>Get the facts.</strong><br />You need to know about psoriasis and psoriatic arthritis in order to take care of yourself.</li>
                    <li><strong>Have a medical check-up on a regular basis.</strong><br />psoriasis and psoriatic arthritis change over time and with life events. You can take better care when you note and act on changes.</li>
                    <li><strong>Get support to help you with the emotional side of illness.</strong><br />Illness can be hard on our minds and on our emotions. Managing a disease is hard work and we need to be sure that we have the support we need.</li>
                    <li><strong>Move more and eat better.</strong><br />You will feel better if you are active and eat healthy foods. This helps you build the strength and energy your body needs to handle psoriasis and psoriatic arthritis.</li>
                </ol>
                </div>
                
                <h4>Get the facts about arthritis and about arthritis care.</h4>
                <p>The word “arthritis” comes from the Greek words: “arth” and “itis.” “Arth” means joint and “itis” means inflammation. There are many kinds of arthritis with many causes. Arthritis can be caused by inflammation, injury, or infection. Inflammation can cause swelling, pain and stiffness. Sometimes an inflamed joint can feel hot or look red.</p>
                
                <h2>What is Psoriasis?</h2>
                <p><strong>Psoriasis</strong> is a common and chronic disease. Psoriasis is characterized by symptoms and signs such as itchy plaques of raised red skin covered with thick scales. These symptoms come and go. When the symptoms come back it is called a flare. Psoriasis is usually found on the elbows, knees, and scalp but can often affect the legs, trunk, and nails.</p>

                <h2>What is Psoriatic arthritis?</h2>
                <p><strong>Psoriatic arthritis</strong> is arthritis with psoriasis. Psoriasis causes scaly dry patches to form on the skin. Arthritis causes pain and swelling in many joints. These symptoms come and go. When the symptoms come back it is called a flare.</p>

                <h2>Who is affected by psoriasis and psoriatic arthritis?</h2>
                <p>Anyone can get psoriasis and psoriatic arthritis, even children. It affects men and women of all races and all ethnic groups.</p>
                <p><strong>Psoriasis</strong> is not contagious- you cannot get psoriasis from touching someone with psoriasis or swimming in a pool with someone who has psoriasis. While you cannot “catch” psoriasis from anyone, experts are still figuring out how it exactly happens.</p>
                <p><strong>Psoriatic arthritis </strong> usually begins between the age of 30 and 55. About 10% of people who have psoriasis will get psoriatic arthritis.</p>
                
                <p>Get more information and talk with your doctor or visit a local library to help you get the facts you need about psoriasis and psoriatic arthritis. There are also many national resource groups on the internet that can help you get started.</p>
                
                <h2>Have a regular medical check-up<br/>
                <em>Talk with your doctor</em></h2>
                <p>A doctor is the best source of information for psoriasis and psoriatic arthritis information and you may choose to talk with a doctor who is an expert in the field. A rheumatologist is a doctor who treats people with arthritis. A dermatologist is a doctor and specialist who treats skin problems. When you are living with a long-term illness, you will want to be sure to see your doctor on a regular basis—even when you are feeling well.</p>
                <p>Your doctor and others on your medical team can help you learn about psoriasis and psoriatic arthritis and how to take care of yourself. People who manage complex conditions learn to pay attention to changes in their bodies. You will want to talk with your doctor about any changes and symptoms.</p>
                
                <h4>Make a plan together</h4>
                <p>You and your doctor can work together to find the best way to treat all of your symptoms. Your treatment will depend on your symptoms. Over time, you may see special doctors. For example:</p>
                <p>You might see someone who is trained in the care and treatment of people’s eyes. This person is called an <strong>ophthalmologist</strong>.</p> 
                <p>You might see someone called a <strong>physical therapist</strong> who is trained to help people including those who may be dealing with illness or limited movement, manage their pain.</p>

                <h2>Get support to help with the emotional side of illness.</h2>
                <p>Complex illnesses like psoriasis and psoriatic arthritis can be stressful and hard on your spirit and on your emotions. You might think that your friends, family and co-workers do not understand how you feel.</p>
                <p>You may feel unhappy because of the visibility of your psoriasis or perhaps you can’t move as easily as you did before you had psoriatic arthritis. You are not alone in these feelings. Sadness and anger are common emotions.</p>
                
                <h4><em>People with arthritis suggest some helpful hints:</em></h4>
                <p><strong>Stay involved in social activities.</strong> Keep up to date with your friends. Let them know that you want to be involved even though you may not be able to do everything.</p>
                <p><strong>Be good to yourself.</strong> Take care of your spiritual side. Many people use prayer, relaxation exercises, or meditation.</p>
                <p><strong>Decide what is most important.</strong> Spend your time on those important activities and with those people who mean the most to you.</p>
                <p><strong>More hints...</strong>  <br />
                Consider joining a support group or seeing a counselor. Counseling is help from a professional who will listen to you and help you develop a plan of action.</p>
                <p>Be sure to ask for help when you need it.</p>
                <p>Share how you are feeling with your family and friends. You can help them understand that the disease sometimes flares up and causes problems.</p>
                
                <h2>Move more and eat better</h2>
                <p>In general, you will need to be as strong and healthy as you can. Moving more and making healthy food choices including a variety of whole grains, fruits and vegetables and lean meats will help you.  Exercise will give you more energy for doing the things you need and want to do. Exercise will help you build strong muscles and keep flexible. This will make your daily tasks easier.</p>
                <p>A physical therapist can help you plan an exercise program that works for your lifestyle. You can learn to change your exercise program depending on how you feel each day. Everyone needs to exercise. Exercise can help to improve and maintain your health.</p>
                
                <h4>Eat Better</h4>
                
                <p>There is no magic diet that will cure arthritis. A nutritionist can help you decide on a meal plan that fits your needs and is good for you.</p>
                <p>Staying at a healthy weight will help your joints.</p>
                <p>Living with psoriasis and psoriatic arthritis may look differently for each individual. Together with managing your illness, talking with (involving?) your doctors and family and friends, can also help to support you along the journey.</p>
                
            </Col>
        </Row>
        </div>
        </div>
    )
}

export default Resources;