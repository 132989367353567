import React, { Component } from 'react';
import { Row, Col, Card, Drawer, Button, Icon, Modal } from 'antd';
import { withRouter } from 'react-router-dom';

class TreatmentEdu extends Component {
    state = {
        visible: false,
        placement: 'right',
        category: 'Psoriasis',
        type: 'Topical',
        treatment: [
            { category: 'Psoriasis', type: 'Topical', description: 'Topical therapy is the most common treatment option utilized by dermatologists. Several categories of topical therapies are available that your Advanced Dermatology provider can take advantage of to treat your skin. Below is a list of several common classes of topical therapies and there uses.' },
            { category: 'Psoriasis', type: 'Phototherapy', description: 'Phototherapy or light therapy, involves exposing the skin to ultraviolet light on a regular basis and under medical supervision. Treatments are done in a doctor\'s office or psoriasis clinic or at home with phototherapy unit. The key to success with light therapy is consistency.' },
            { category: 'Psoriasis', type: 'Oral Systemic', description: 'Systemic medications are prescription drugs that work throughout the body. They are usually used for individuals with moderate to severe psoriasis and psoriatic arthritis. Systemic medications are also used in those who are not responsive or are unable to take topical medications or UV light therapy.' },
            { category: 'Psoriasis', type: 'Biologic', description: 'Biological products include a wide range of products such as vaccines, blood and blood components, allergenics, somatic cells, gene therapy, tissues, and recombinant therapeutic proteins. Biologics can be composed of sugars, proteins, or nucleic acids or complex combinations of these substances, or may be living entities such as cells and tissues. Biologics are isolated from a variety of natural sources - human, animal, or microorganism - and may be produced by biotechnology methods and other cutting-edge technologies. Gene-based and cellular biologics, for example, often are at the forefront of biomedical research, and may be used to treat a variety of medical conditions for which no other treatments are available.' },
            { category: 'psoriaticArthritis', type: 'Anti-inflammation', description: 'Psoriasis and psoriatic arthritis are chronic inflammatory diseases. Nonsteroidal anti-inflammatory drugs (NSAIDs) alleviate pain by counteracting the cyclooxygenase (COX) enzyme. On its own, COX enzyme synthesizes prostaglandins, creating inflammation. In whole, the NSAIDs prevent the prostaglandins from ever being synthesized, reducing or eliminating the pain.' },
            { category: 'psoriaticArthritis', type: 'Oral Systemic', description: 'Systemic medications are prescription drugs that work throughout the body. They are usually used for individuals with moderate to severe psoriasis and psoriatic arthritis. Systemic medications are also used in those who are not responsive or are unable to take topical medications or UV light therapy.' },
            { category: 'psoriaticArthritis', type: 'Biologic', description: 'Biological products include a wide range of products such as vaccines, blood and blood components, allergenics, somatic cells, gene therapy, tissues, and recombinant therapeutic proteins. Biologics can be composed of sugars, proteins, or nucleic acids or complex combinations of these substances, or may be living entities such as cells and tissues. Biologics are isolated from a variety of natural sources - human, animal, or microorganism - and may be produced by biotechnology methods and other cutting-edge technologies. Gene-based and cellular biologics, for example, often are at the forefront of biomedical research, and may be used to treat a variety of medical conditions for which no other treatments are available.' },
            { category: 'psoriaticArthritis', type: 'Other', description: 'Uncommon treatments for specific cases of psoriatic arthritis.' },
        ]
    };

    showDrawer = (category, type) => {
        this.setState({
            visible: true,
            category,
            type
        });
    };

    closeDrawer = () => {
        this.setState({
            visible: false,
        });
    };

    onChange = (e) => {
        this.setState({
            placement: e.target.value,
        });
    }
    renderContents = () => {
        const x = this.state.treatment.find(x => x.category === this.state.category && x.type === this.state.type);
        return (
            <div>
                <h2>{x.type}</h2>
                <p>{x.description}</p>
                <div className='text-center'>
                    <Button size={'large'} onClick={() => this.props.history.push(`/Treatments/${this.state.category}/${this.state.type}`)} type='primary'>View Details</Button>
                </div>
            </div>
        )
    }

    psoriasisModal = (title, url) => {
        Modal.info({
            title: title,
            content: (
                <div className={'wrapper '} >
                <div className={'videoWrapper mt-1'}>
                    <iframe src={url} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
            ),
            onOk() {},
            okText: 'Close',
            width: 640
        });
    }


    render() {
    return (
        <div className="treatmentEdu">
        <Row>
            <Col><h1 className="text-uppercase">Treatment Education</h1></Col>
        </Row>
        
        <div className="borderedRow"  >
            <div style={{ margin: '0 32px'}}>
                <Row gutter={32} className='home-row pb-1'>
                    <Col>
                        <h2><strong>Psoriasis</strong></h2>
                        <p><a onClick={() => this.psoriasisModal('Psoriasis Treatment', 'https://player.vimeo.com/video/396841267')}>Learn about psoriasis treatment options</a></p>
                    </Col>
                    <Col className={'mb-md-1'} xs={{ span: 20, offset: 2}} sm={{ span: 12, offset: 0}} md={{ span: 12, offset: 0}} lg={{ span: 6, offset: 0}} >
                            <div className="homeIconContainer">
                                <img onClick={() => this.props.history.push(`/Treatments/Psoriasis/Topical`)} className=' mb-0' alt='Treatment Education' width='50%' src={require("../icons/cream.svg")}/>
                            </div>
                            <h4 className='text-center mt-1 '>Topicals</h4>
                            <div className={'text-center'}>
                                <Button size={'large'} type={"primary"} onClick={() => this.props.history.push(`/Treatments/Psoriasis/Topical`)}>View Details</Button>
                            </div>
                    </Col>
                    <Col className={'mb-md-1'} xs={{ span: 20, offset: 2}} sm={{ span: 12, offset: 0}} md={{ span: 12, offset: 0}} lg={{ span: 6, offset: 0}} >
                            <div className="homeIconContainer">
                                <img onClick={() => this.props.history.push(`/Treatments/Psoriasis/Phototherapy`)} className='mb-0' alt='Treatment Education' width='50%' src={require("../icons/sun-beams.svg")}/>
                            </div>
                            <h4 className='text-center mt-1 '>Phototherapy</h4>
                            <div className={'text-center'}>
                                <Button size={'large'} type={"primary"} onClick={() => this.props.history.push(`/Treatments/Psoriasis/Phototherapy`)}>View Details</Button>
                            </div>
                    </Col>
                    <Col className={'mb-md-1'} xs={{ span: 20, offset: 2}} sm={{ span: 12, offset: 0}} md={{ span: 12, offset: 0}} lg={{ span: 6, offset: 0}} >
                            <div className="homeIconContainer">
                                <img onClick={() => this.props.history.push(`/Treatments/Psoriasis/Oral Systemic`)} className='mb-0' alt='Treatment Education' width='50%' src={require("../icons/systemic.svg")}/>
                            </div>
                            <h4 className='text-center mt-1 '>Oral/Systemics</h4>
                            <div className={'text-center'}>
                                <Button size={'large'} type={"primary"} onClick={() => this.props.history.push(`/Treatments/Psoriasis/Oral Systemic`)}>View Details</Button>
                            </div>
                    </Col>
                    <Col className={'mb-md-1'} xs={{ span: 20, offset: 2}} sm={{ span: 12, offset: 0}} md={{ span: 12, offset: 0}} lg={{ span: 6, offset: 0}} >
                            <div className="homeIconContainer">
                                <img onClick={() => this.props.history.push(`/Treatments/Psoriasis/Biologic`)} className='mb-0' alt='Treatment Education' width='50%' src={require("../icons/biologics.svg")}/>
                            </div>
                            <h4 className='text-center mt-1 '>Biologics</h4>
                            <div className={'text-center'}>
                                <Button size={'large'} type={"primary"} onClick={() => this.props.history.push(`/Treatments/Psoriasis/Biologic`)}>View Details</Button>
                            </div>
                            
                    </Col>
                </Row>
            </div>
        </div>
            <div style={{ margin: '5px 32px'}}>
                <Row gutter={32} className='home-row pb-1'>
                    <Col>
                        <h2><strong>Psoriatic Arthritis</strong></h2>
                        <p><a onClick={() => this.psoriasisModal('Psoriatic Arthritis Treatment', 'https://player.vimeo.com/video/396841247')}>Learn about psoriatic arthritis treatment options</a></p>
                    </Col>
                    <Col className={'mb-md-1'} xs={{ span: 20, offset: 2}} sm={{ span: 12, offset: 0}} md={{ span: 12, offset: 0}} lg={{ span: 6, offset: 0}} >
                        <div className="homeIconContainer">
                            <img onClick={() => this.props.history.push(`/Treatments/psoriaticArthritis/Anti-inflammation`)} className=' mb-0' alt='Treatment Education' width='50%' src={require("../icons/anti-inflammation.svg")}/>
                        </div>
                        <h4 className='text-center mt-1 '>Anti-Inflammation</h4>
                        <div className={'text-center'}>
                            <Button size={'large'} type={"primary"} onClick={() => this.props.history.push(`/Treatments/psoriaticArthritis/Anti-inflammation`)}>View Details</Button>
                        </div>
                    </Col>
                    <Col className={'mb-md-1'} xs={{ span: 20, offset: 2}} sm={{ span: 12, offset: 0}} md={{ span: 12, offset: 0}} lg={{ span: 6, offset: 0}} >
                            <div className="homeIconContainer">
                                <img onClick={() => this.props.history.push(`/Treatments/psoriaticArthritis/Oral Systemic`)} className='mb-0' alt='Treatment Education' width='50%' src={require("../icons/systemic.svg")}/>
                            </div>
                            <h4 className='text-center mt-1 '>Oral/Systemics</h4>
                            <div className={'text-center'}>
                                <Button size={'large'} type={"primary"} onClick={() => this.props.history.push(`/Treatments/psoriaticArthritis/Oral Systemic`)}>View Details</Button>
                            </div>
                            
                    </Col>
                    <Col className={'mb-md-1'} xs={{ span: 20, offset: 2}} sm={{ span: 12, offset: 0}} md={{ span: 12, offset: 0}} lg={{ span: 6, offset: 0}} >
                        <div className="homeIconContainer">
                            <img onClick={() => this.props.history.push(`/Treatments/psoriaticArthritis/Biologic`)} className='mb-0' alt='Treatment Education' width='50%' src={require("../icons/biologics.svg")}/>
                        </div>
                        <h4 className='text-center mt-1 '>Biologics</h4>
                        <div className={'text-center'}>
                            <Button size={'large'} type={"primary"} onClick={() => this.props.history.push(`/Treatments/psoriaticArthritis/Biologic`)}>View Details</Button>
                        </div>
                    </Col>
                    <Col className={'mb-md-1'} xs={{ span: 20, offset: 2}} sm={{ span: 12, offset: 0}} md={{ span: 12, offset: 0}} lg={{ span: 6, offset: 0}} >
                            <div className="homeIconContainer">
                                <img onClick={() => this.props.history.push(`/Treatments/psoriaticArthritis/Other`)} className='mb-0' alt='Treatment Education' width='50%' src={require("../icons/more.svg")}/>
                            </div>
                            <h4 className='text-center mt-1 '>Other</h4>
                            <div className={'text-center'}>
                                <Button size={'large'} type={"primary"} onClick={() => this.props.history.push(`/Treatments/psoriaticArthritis/Other`)}>View Details</Button>
                            </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
    }
}

export default withRouter(TreatmentEdu);
