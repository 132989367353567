import React, { Component } from 'react';
import { Input, Row, Col } from 'antd';
import glossary from '../assets/glossary';
import BreadcrumbMaker from './common/BreadcrumbMaker';
import Acknowledgement from './Acknowledgement';
import PageTitle from './common/PageTitle';

class Glossary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: glossary,
            searchData: '',
            search: '',
        };
    }
    
    renderGlossary = () => {
        if (this.state.data !== null) {
            var filtered = this.state.data.filter(g => g.Term.toLowerCase().includes(this.state.search.toLowerCase()));
            return (
                filtered.map((v, i) => {
                    return (
                        <div key={i}>
                            <dt><strong>{v.Term}</strong> ({v.Pronunciation})</dt>
                            <dd>{v.Definition}</dd>
                        </div>
                    )
                })
            );
        }
    }

    onChangeHandler(e) {
        this.setState({
            search: decodeURI(e.target.value),
        })
    }

    renderBreadcrumbs = () => {
        const arr = [
            { route: '/', name: 'Home' },
            //{ route: '', name: 'Learn More' },
            { route: '', name: 'Words To Know' }
        ];
        return BreadcrumbMaker(arr);
    }

    componentDidMount = () => {

        const input = document.querySelector('#search');
        // el.value = window.location.hash.replace('#', '');
        //el.dispatchEvent(new Event('input'));
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeInputValueSetter.call(input, decodeURI(window.location.hash.replace('#', '')));
        var ev2 = new Event('input', { bubbles: true });
        input.dispatchEvent(ev2);
    }

    render() {
        return (
            <div >
                {this.renderBreadcrumbs()}
                <PageTitle title={'Words To Know'} subTitle={``} />
                <div className="container800 center mt-2">

                    <Row>
                        <Col >
                            <p>The following information is called a glossary. A glossary is made up of words and their meanings. This glossary lists words that people generally use when they talk about arthritis, and specifically, psoriasis and psoriatic arthritis. The words are in A to Z order.</p>
                            <ul>
                                <li>The glossary shows the key word in <strong>bold</strong>; followed by how you would pronounce the word in parentheses ()</li>
                                <li>You will see that some letters are in capital letters. This part of the word is said with a strong beat. This part of the word is said with a strong beat. For example, the word Antibody (AN tee bod ee), when written like this, helps you say the word out loud.</li>
                                <li>Next, the definition of the word is given.</li>
                                <li>Lastly, the word used in a sentence <strong>(**)</strong>. Each sentence begins with the following symbols: **. The names used are made up in these sentence examples.</li>
                            </ul>

                            <p>If you have psoriasis and/or psoriatic arthritis, you probably have many questions.</p>
                            <Input id='search' style={{ width: '300px', marginBottom: '2em' }} onChange={(e) => this.onChangeHandler(e)} placeholder="Search the Glossary" />
                            <dl>
                                {this.renderGlossary()}
                            </dl>
                            <h4>ABBREVIATIONS</h4>
                            <dl>
                                <dt><strong>FDA</strong></dt>
                                <dd>The Food and Drug Administration. The FDA is a government agency that makes rules about food and drugs to keep them safe. </dd>
                                <dt><strong>IV</strong></dt>
                                <dd>Intravenous. IV means into the vein. </dd>
                                <dt><strong>mg</strong></dt>
                                <dd>Milligram. Mg is a unit of weight. It is used as an amount of medicine. </dd>
                                <dt><strong>PsO</strong></dt>
                                <dd>Psoriasis; A dry, scaly, skin rash.</dd>
                                <dt><strong>PsA</strong></dt>
                                <dd>Psoriatic arthritis; Is arthritis with psoriasis.</dd>
                                <dt><strong>SPF</strong></dt>
                                <dd>Skin Protection Factor SPF is represented as a number. This number tells you the strength of a sunscreen product. For example, an SPF of 30 offers good protection. </dd>
                                <dt><strong>UVA and UVB</strong></dt>
                                <dd>Ultraviolet rays A and ultraviolet rays B. UVB Both UVA and UVB rays are parts of sunlight.</dd>

                            </dl>
                            <h4>COMMON TESTS</h4>
                            <dl>
                                <dt><strong>CRP- C-reactive protein</strong></dt>
                                <dd>A blood test that measures the amount of protein that is related to inflammation in your body.</dd>
                                <dt><strong>EKG Electrocardiogram</strong></dt>
                                <dd>An EKG is an electric recording of the heart. </dd>
                                <dt><strong>Hematocrit </strong></dt>
                                <dd>The percent of blood that is made up of red blood cells. </dd>
                                <dt><strong>X-Ray</strong></dt>
                                <dd>A picture of the bones and other parts inside of the body.</dd>
                            </dl>
                        </Col>
                    </Row>
                    {<Acknowledgement />}
                </div>
            </div>
        )
    }
}

export default Glossary;
