import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import PageTitle from './common/PageTitle';

const Overview = () => {
    return (
        
        <div >
        <PageTitle title={'Overview'} subTitle={``} />
        <div  className="container800 center mt-2">
        
        <Row>
            <Col >
            <p>This website is designed to help you learn more about psoriasis and psoriatic treatments. There are a lot of different options to choose from so we have designed a <Link to={'/decision'}>tool</Link> to help you in this process.</p>
            <h2>What is Psoriasis?</h2>
            <p>Psoriasis is a chronic autoimmune condition that causes the rapid buildup of skin cells. This buildup of cells causes scaling on the skin's surface. Inflammation and redness around the scales is fairly common. Typical psoriatic scales are whitish-silver and develop in thick, red patches.Psoriasis is a chronic autoimmune condition that causes the rapid buildup of skin cells. This buildup of cells causes scaling on the skin's surface. Inflammation and redness around the scales is fairly common. Typical psoriatic scales are whitish-silver and develop in thick, red patches.</p>

            <h2>What is Psoriatic Arthritis?</h2>
            <p>Psoriatic arthritis is a form of arthritis that affects some people who have psoriasis — a condition that features red patches of skin topped with silvery scales. Most people develop psoriasis first and are later diagnosed with psoriatic arthritis, but the joint problems can sometimes begin before skin lesions appear.Psoriasis is a chronic autoimmune condition that causes the rapid buildup of skin cells. This buildup of cells causes scaling on the skin's surface. Inflammation and redness around the scales is fairly common. Typical psoriatic scales are whitish-silver and develop in thick, red patches.</p>

            </Col>
        </Row>
        </div>
        </div>
    )
}

export default Overview;
