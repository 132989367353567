import React, { Component } from 'react';
import { 
    Popover, Icon, Card, Checkbox, Button, Row, Col, Progress, Radio, Input, Modal, Typography, InputNumber, Anchor, Spin, Slider 
} from 'antd';
import { withRouter } from 'react-router-dom';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { scale, valuesData } from '../components/decision/DAReviewData';
import Api from '../api';

const { Paragraph, Text } = Typography;

const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;

const severeDomainsQuestions = [
    { id: 'deformities', question: 'Has any physician said that you have "deformities" from your psoriatic arthritis?', type: 'radio', answers: ['Yes', 'No']},
    { id: 'erosions', question: 'On foot or hand X-rays, have you had "eating away of the bone (erosions)"?', type: 'radio', answers: ['Yes', 'No']},
    { id: 'qol', question: 'Have you had a major impairment in your quality of life?', type: 'radio', answers: ['Yes', 'No']},
    { id: 'dailyPrevention', question: 'Does it prevent you from completing your daily activities?', type: 'radio', answers: ['Yes', 'No']},
]
const familyPlanningMale = ['Male and having children is in my near future (This includes: actively trying to have a child OR considering having a child)', 'Male and having children NOT in my near future (This includes: not actively trying to have a child)'];
const familyPlanningFemale = ['I was born Female and pregnancy is in my near future (This includes: currently pregnant OR actively trying to be pregnant OR considering to be pregnant)', 'I was born  Female and pregnancy is not in my near future. (This includes: currently taking birth control or willing to take or it is not physically possible to pregnant)'];
const cards = [
    { id: 'intro', question: `Answer the following questions to guide you through simple steps to help you learn more about medication and treatment options. \n\n Your answers to these questions will be used to come up with a list of treatments that might be best for you.`, type: 'intro',  answers: [''] },
    { id: 'thinking', question: `Are you thinking about…`, type: 'radio', answers: ['Starting therapy for the first time', 'Switching from your current therapy to something different', 'Neither, just curious about this site']},
    { id: 'age', question: 'How old are you?', type: 'radio',  answers: [ 'Less than 18', '18 years or older'] },
    { id: 'psoriasisType', question: 'Has a physician diagnosed you with:', type: 'radio', answers: [ 'Psoriasis', 'Psoriatic arthritis', 'Both Psoriasis and Psoriatic Arthritis', 'I am not sure or I don\'t know'] },
    { id: 'domains', question: 'Do you have the following:', type: 'check',  answers: ['Arthritis not involving the spine (Peripheral arthritis)', 'Arthritis involving the spine (Axial Involvement)', /*'Inflammation where the tendon inserts -e.g., achilles tendonitis, plantar fasciitis (Enthesitis)', */'Swelling of entire finger and/or toes (Dactylitis)', 'Skin involvement'] },
    { id: 'affectedArea', question: ' Is your psoriasis currently affecting (check all that apply):', type: 'check', answers: ['Scalp', 'Nails', 'Face', 'Ears', 'Neck', 'Chest', 'Abdomen', 'Back', 'Buttocks/Gluteal region', 'Genitals', 'Arms', 'Back of hand(s)', 'Palm(s) of hand(s)', 'Legs', 'Sole(s) of foot/feet'] },
    { id: 'surfaceArea', question: 'Currently, what percentage of your skin has the psoriasis rash?', type: 'number', answers: '' },
    { id: 'currentMedications', question: 'Are you CURRENTLY taking any of these medications? Please select all.', type: 'currentMedications', answers: [] },
    { id: 'previousMedication', question: 'What medications have you taken for psoriasis or psoriatic arthritis IN THE PAST?', type: 'previousMedication', answers: [] },
    { id: 'comorbidities', question: 'Do you have the following?', type: 'check', answers: [] },
    { id: 'latex', question: 'Do you have a latex allergy?', type: 'radio', answers: ['Yes', 'No'] },
    { id: 'injections', question: 'How do you feel about needles or injections?', type: 'radio', answers: ['No way! I am terrified!','I could consider learning about using them.','I would consider injections if someone else does it for me.','I am a pro! I have used them for my medications before.']},
    { id: 'familyPlanning', question: 'Therapies can have an impact on family planning. Please answer the  question below about your intentions about starting a family. Are you…', type: 'radio', answers: ['I was born  Female and pregnancy is in my near future (This includes: currently pregnant OR actively trying to be pregnant OR considering to be pregnant)', 'I was born  Female and pregnancy is not in my near future. (This includes: currently taking birth control or willing to take or it is not physically possible to pregnant)', 'I was born  Male and having children is in my near future (This includes: actively trying to have a child OR considering having a child)', 'I was born  Male and having children NOT in my near future (This includes: not actively trying to have a child)'] },    
    { id: 'breastfeeding', question: 'Are you currently breastfeeding or planning to breastfeed?', type: 'radio', answers: ['Yes', 'No']},
    { id: 'healthInsurance', question: 'What is your health insurance status?', type: 'radio', answers: ['Uninsured', 'Commercial insurance', 'Medicaid', 'Medicare', 'Other governmental funded insurance'] },
    { id: 'daReview', question: 'Please rate the level of importance to you to discuss with your doctor in the next visit. \n (0-10 scale - 0 = not at all; 5 = moderately; 10 = extremely)', type: 'daReview', answers: []}
];

class Decision extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            current: 0,
            cards: cards,
            questions: [],
            therapies: [],
            answers: {
                age: '',
                thinking: '',
                psoriasisType: '',
                domains: [],
                affectedArea: [],
                surfaceArea: 0,
                currentMedications: [],
                previousMedication: [],
                latex: '',
                familyPlanning: '',
                breastfeeding: '',
                comorbidities: [],
                healthInsurance: '',
                daReviewAnswers: {
                    jointPain: 0,
                    psoriasis: 0,
                    itch: 0,
                    flare: 0,
                    activity: 0,
                    qol: 0,
                    fatigue: 0,
                    workHome: 0,
                }
            },
            specialCase: [],
            showDomains: false,
            reload: false,
        }
    }

    componentDidMount = () => {
        
        Api.get('therapy/all', (therapies) => {
            let therapiesName = therapies.map(a => a.Name);
            const cards = this.state.cards;
            

            Api.get('/admin/comorbiditydomain/all', (data) => {
                //console.log(data);
                let comorbidities = data.filter(c => c.ComorbidityType === 'Comorbidity').map(c => c.Name);
                let domains = data.filter(d => d.ComorbidityType === 'Domain').map(d => d.Name);
                let newCards = cards.map(c => {
                    if(c.id === 'currentMedications' || c.id === 'previousMedication'){
                        c.answers = therapiesName;
                    }
                    if(c.id === 'comorbidities'){
                        c.answers = comorbidities;
                    }
                    if(c.id === 'domains'){
                        //c.answers = domains;
                    }
                    return c;
                });
                this.setState({ comorbidities, domains, cards: newCards, questions: newCards, loading: false, therapies });
                //console.log(this.state);
            });    
        });
    }

    onChangeCheckbox = (checkedValues) => {
        let answers = this.state.answers;
        answers[this.state.questions[this.state.current].id] = checkedValues;
        this.setState({ answers, reload: true })
    }

    onChangeInputNumber = value => {
        let answers = this.state.answers;
        answers[this.state.questions[this.state.current].id] = value;
        this.setState({ answers, reload: true });
    }

    onChangeRadio = (e) => {
        let answers = this.state.answers;
        answers[this.state.questions[this.state.current].id] = e.target.value;
        let filteredQuestions = this.state.questions;
        const allCards = this.state.cards;

        if(this.state.questions[this.state.current].id === 'psoriasisType' && e.target.value.toLowerCase().includes('psoriatic arthritis')){
            if(!this.state.questions.find(x => x.id === 'domains')){
                filteredQuestions.splice(5, 0, allCards.find(x => x.id === 'domains'));
            }
        }
        if(this.state.questions[this.state.current].id === 'psoriasisType' && e.target.value === 'Psoriasis'){
            filteredQuestions = this.state.questions.filter(x => x.id !== 'domains' && x.id !== 'dailyLife');
            answers.domains = [];
            if(!this.state.questions.find(x => x.id === 'pso')){
                filteredQuestions.splice(5, 0, allCards.find(x => x.id === 'pso'));
            }
        }

        if(this.state.questions[this.state.current].id === 'familyPlanning' && e.target.value.toLowerCase().indexOf(' male') > -1){
            console.log('male');
            filteredQuestions = this.state.questions.filter(x => x.id !== 'breastfeeding');
        }
        if(this.state.questions[this.state.current].id === 'familyPlanning' && e.target.value.toLowerCase().indexOf(' female') > -1){
            console.log('female');
            if(!this.state.questions.find(x => x.id === 'breastfeeding')){
                filteredQuestions.splice((filteredQuestions.findIndex(item => item.id === 'familyPlanning') + 1), 0, allCards.find(x => x.id === 'breastfeeding'));
            }
        }

        this.setState({ answers, questions: filteredQuestions.filter(Boolean), reload: true });

        this.setState(state => {
            const questions = state.questions.map((item, i) => {
                console.log('items', this.state.current, e.target.value);
                if(i === this.state.current){
                    item.value = e.target.value;
                    return item;
                }else{
                    return item;
                }
            });

            return { questions }
        });        
    }

    nextCard = () => { this.setState({ current: this.state.current + 1 }); }

    previousCard = () => { this.setState({ current: this.state.current - 1 }); }

    showTherapy = () => {
        localStorage.setItem('decisionAid', JSON.stringify(this.state.answers));
        localStorage.setItem('dlqi', JSON.stringify(this.state.DLQIAnswers));
        localStorage.setItem('rapid3', JSON.stringify(this.state.rapid3Answers));
        this.setState({
            rapid3Answers: { a: '', b: '', c: '', d: '', e: '', f: '', g: '', h: '', i: '', j: '', k: '', l: '', m: '', pain: 0, wellBeing: 0 },
            DLQIAnswers: { a: '', b: '', c: '', d: '', e: '', f: '', ga: '', gb: '', h: '', i: '', j: '' },
            //answers: defaultAnswers,
             answers: {
                age: '',
                thinking: '',
                psoriasisType: '',
                domains: [],
                affectedArea: [],
                surfaceArea: 0,
                currentMedications: [],
                previousMedication: [],
                latex: '',
                familyPlanning: '',
                breastfeeding: '',
                comorbidities: [],
                healthInsurance: '',
                daReviewAnswers: {
                    jointPain: 0,
                    psoriasis: 0,
                    itch: 0,
                    flare: 0,
                    activity: 0,
                    qol: 0,
                    fatigue: 0,
                    workHome: 0,
                }
             }
        }, () => {
            //this.props.history.push(`/dareview`);
            this.props.history.push(`/decision/result`);
        });        
    }

    setProgress = () => {
        return Math.round((this.state.current + 1 === this.state.questions.length ? this.state.questions.length : this.state.current / this.state.questions.length) * 100)
    }

    renderRadioGroup = () => {
        const questionId = this.state.questions[this.state.current].id;
        return (
            <RadioGroup onChange={this.onChangeRadio} buttonStyle='solid' size='large' value={this.state.answers[questionId]}>
                {
                    this.state.questions[this.state.current].answers.map( (r, i) => {
                        let c = r;
                        if(r === "Commercial insurance") {
                            let description = 'i.e., insurance from a business or your work';
                            c = <Popover content={<Paragraph>{description}</Paragraph>} title="">
                                    {r}&nbsp;<Icon type="info-circle" />
                                </Popover>
                        }
                        return <Radio className='verticalRadio ml-1' key={i} value={r}>{c}</Radio>
                    })
                }
            </RadioGroup>
        )
    }

    getValues = () => {
        const id = this.state.questions[this.state.current].id;
        let values = this.state.answers[id];
        return values;
    }

    renderCheckBoxes = () => {
        if(this.state.cards[this.state.current].id === 'domains'){
            return (
                <div className="checkboxGroup ml-1 mt-2">
                    <Checkbox.Group
                        // options={this.state.questions[this.state.current].answers}
                        onChange={this.onChangeCheckbox}
                        value={this.getValues()}
                        defaultValue={this.getValues()}
    
                    >
                        <Row>
                            <Col span={12}>
                                <Checkbox value="Peripheral arthritis">Arthritis except spinal involvement (Peripheral arthritis)</Checkbox>
                                <img  src={require(`../icons/peripheral-arthritis-icon.svg`)} />
                            </Col>
                            <Col span={12}>
                                <Checkbox value="Axial Disease">Axial Disease (Spinal Involvement)</Checkbox>
                                <img src={require(`../icons/axialDisease.svg`)} />
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col span={12}>
                                <Checkbox value="Enthesitis">Inflammation where the tendon inserts -e.g., achilles tendonitis, plantar fasciitis (Enthesitis)</Checkbox>
                                <img src={require(`../icons/enthesitis.svg`)} />
                            </Col> */}
                            <Col span={12}>
                                <Checkbox value="Dactylitis">Swelling of entire finger and/or toes (Dactylitis)</Checkbox>
                                <img src={require(`../icons/dactylitis-icon.svg`)} />
                            </Col>
                            <Col span={12}>
                                <Checkbox value="Plaque psoriasis">Skin</Checkbox>
                                <img src={require(`../icons/psoriasis-skin-knee-icon.svg`)} />
                            </Col>
                        </Row>
                        
                    </Checkbox.Group>
                </div>
            );
        }else if(this.state.cards[this.state.current].id === 'comorbidities'){
            return (
                <div className="checkboxGroup ml-1 mt-2">
                    <Checkbox.Group
                        // options={this.state.questions[this.state.current].answers}
                        onChange={this.onChangeCheckbox}
                        value={this.getValues()}
                        defaultValue={this.getValues()}
                    >
                        {
                            
                            this.state.questions[this.state.current].answers.map((c, i) => {
                                let value = c;
                                if(c === "Current heavy alcohol intake") {
                                    let description = 'For women, defined as more than 3 drinks on any single day or more than 7 drinks per week. For men, defined as more than 4 drinks on any single day or more than 14 drinks per week.';
                                    c = <Popover content={<Paragraph>{description}</Paragraph>} title="">
                                            {c}&nbsp;<Icon type="info-circle" />
                                        </Popover>
                                }
                                return (
                                    <Row key={`comorb${i}`}>
                                        <Col>
                                            <Checkbox value={value}>{c}</Checkbox>
                                        </Col>
                                    </Row>
                                )
                            })
                        }

                    </Checkbox.Group>
                </div>
            )
        } else {
            return (
                <div className="checkboxGroup ml-1 mt-2">
                    <CheckboxGroup 
                        options={this.state.questions[this.state.current].answers}  
                        onChange={this.onChangeCheckbox} 
                        value={this.getValues()}
                        defaultValue={this.getValues()}    
                    />
                </div>
            );
        }
    }

    renderInput = (type) => {
        return type === 'number' ? <InputNumber defaultValue={this.getValues()} onChange={this.onChangeInputNumber} min={0} max={100} />
        : <Input onChange={this.onChangeInput} placeholder="" />
    }

    renderAnswers = () => {
        const question = this.state.questions[this.state.current];
        if(question.type === 'currentMedications' || question.type === 'previousMedication'){
            return this.renderGroupedTherapies();
        }
        if(question.type === 'check'){
            return this.renderCheckBoxes();
        }
        if(question.type === 'radio'){
            return this.renderRadioGroup();
        }
        if(question.type === 'autocomplete'){
            return this.renderInput('');
        }
        if(question.type === 'number'){
            return this.renderInput('number');
        }
        if(question.type === 'daReview'){
            return this.renderDAReview();
        }
    }

    onSliderChange = (value, id) => {
        let answers = this.state.answers;
        let daReviewAnswers = this.state.answers.daReviewAnswers;
        daReviewAnswers[id] = value;
        answers.daReviewAnswers = daReviewAnswers;
        console.log('change', id, value);
        console.log(answers);
        this.setState({ answers });
    }

    renderDAReview = () => {
        return valuesData().map( (r, i) => {
            return (<div className={'mb-1'} key={r.id}>
            <p style={{marginBottom: 0}}>{r.question}</p>
            
            <Slider min={0} max={10} step={1} onChange={(value) => this.onSliderChange(value, r.id)} name={r.id} marks={scale} value={this.state.answers.daReviewAnswers[r.id]} defaultValue={0} />

            <div className='clearfix'></div>
        </div>)
        })
    }

    // function for dynamic sorting
    compareValues = (key, order='asc') => {
        return function (a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string') ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ?
                b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order == 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    getIcon = (therapyType) => {
        let icon = '';
        switch (therapyType) {
            case 'Biologic':
                icon = `biologics.svg`;
                break;
            case 'Oral Systemic':
                icon = `systemic.svg`;
                break;
            case 'Phototherapy':
                icon = `sun-beams.svg`;
                break;
            case 'Topical':
                icon = `cream.svg`;
                break;
            default:
                break;
        }
        return icon;
    }

    renderCheckCol = (arr, type) => {
        
        return (
            arr.filter(t => t.TherapyType === type).map((therapy, i) => {
                let iconx = '';
                if(i === 0){
                    iconx = this.getIcon(therapy.TherapyType);
                }
                
                return (
                    <div key={i}>
                    <Row index={'mpp' + i}>
                        <Col >
                            {iconx ? <div className={'center text-center'}><img  src={require(`../icons/` + iconx)} /><p className={'center text-center'}>{therapy.TherapyType === 'Phototherapy' ? 'Light Therapy' : therapy.TherapyType}</p></div> : ''}
                        </Col>
                        <Col span={24}>
                            <Checkbox value={therapy.Name}>{therapy.DisplayName}</Checkbox>
                        </Col>
                    </Row>
                    </div>
                )
            })
        )
    }

    renderGroupedTherapies = () => {
        let therapies = this.state.therapies.filter(t => t.TherapyType !== 'Topical');
        therapies.unshift({id: 'Topical', Name: 'Topicals', DisplayName: 'Topicals', TherapyType: 'Topical'});
        therapies = therapies.sort(this.compareValues('TherapyType', 'desc'));
        let x = '';
        let iconx = '';
        return (<div className=" mt-2">
                <Checkbox.Group
                    // options={this.state.questions[this.state.current].answers}
                    onChange={this.onChangeCheckbox}
                    value={this.getValues()}
                    defaultValue={this.getValues()}
                >
                <Row gutter={16}>
                    <Col className={' '} sm={{ span: 24, offset: 0}} md={{ span: 12, offset: 0}} >
                    <div className={'mb-1 py-1 therapyGroup'}>
                    {this.renderCheckCol(therapies, 'Topical')}
                    </div>
                    </Col>
                
                    <Col className={''} sm={{ span: 24, offset: 0}} md={{ span: 12, offset: 0}} >
                    <div className={'mb-1 py-1 therapyGroup'}>
                    {this.renderCheckCol(therapies, 'Phototherapy')}
                    </div>
                    </Col>
               
                    <Col className={''} sm={{ span: 24, offset: 0}} md={{ span: 12, offset: 0}} >
                    <div className={'mb-1 py-1 therapyGroup'}>
                    {this.renderCheckCol(therapies, 'Oral Systemic')}
                    </div>
                    </Col>
                
                    <Col className={''} sm={{ span: 24, offset: 0}} md={{ span: 12, offset: 0}} >
                    <div className={'mb-1 py-1 therapyGroup'}>
                    {this.renderCheckCol(therapies, 'Biologic')}
                    </div>
                    </Col>
                </Row>
                </Checkbox.Group>
            </div>);
    }

    renderCard = () => {
        return (
            <div style={{ position: 'relative'}}>
            {this.state.current === 0 && <img className={'startHere pointer'} onClick={this.nextCard} src={require("../icons/start-here.svg")}/>}
            <Card className="center mt-2 daCard" hoverable bordered>
                <h4 style={{whiteSpace: "pre-wrap"}}>{this.state.questions[this.state.current].question}</h4>
                {this.state.questions[this.state.current].id === 'surfaceArea' && this.renderInfo()}
                {this.state.questions[this.state.current].id === 'age' && this.renderAgeWarning()}
                {this.state.questions[this.state.current].id === 'phototherapy' && this.renderLiteStudy()}
                {this.renderAnswers()}
                <div className="cardNav mt-2 center" >
                {
                    this.state.current > 0 &&
                    <div>
                    <Link className="left" to="test1" smooth={true} duration={500} >
                            <Button size={'large'} className="left" type="default" onClick={this.previousCard}>
                                <Icon type="left" />Go back
                            </Button>
                            </Link>  
                        
                    <div className='left startOver'>
                    {!this.state.loading && this.state.current > 0 && <Button className={'ml-1'} size={'large'} onClick={() => this.setState({ current: 0 })}>Start Over</Button>}
                    </div>
                    </div>
                }
                {
                    this.state.current !== this.state.questions.length -1 ?
                    <Link className="right" to="test1" smooth={true} duration={500} >
                           <Button size={'large'} className="right" type="primary" onClick={this.nextCard}>
                                    Continue<Icon type="right" />
                                </Button>
                    </Link>
                     : 
                    <Button size={'large'} className="right" type="primary" onClick={this.showTherapy}>
                        Get Therapy<Icon type="right" />
                    </Button>
                }
                </div>
            </Card>
            {!this.state.loading && this.state.current > 0 && <Button className={'mt-1 startOverSm'} size={'large'} onClick={() => this.setState({ current: 0 })}>Start Over</Button>}
            </div>
        );
    }

    renderInfo = () => {
        return (<p><a onClick={() => this.info()}>How to measure the percentage of your body affected by psoriasis?</a></p>)
    }
    info = () => {
        Modal.info({
            title: 'Measure Surface Area',
            content: (
            <div>
                <p>Use your hand to determine the surface area affected.</p>
                <img className='' alt='da' style={{ width: '100%'}} src={require("../images/decisionAid/bsaHand.jpeg")}/>
                <img className='' alt='da' style={{ width: '100%'}} src={require("../images/decisionAid/bsaLow.jpeg")}/>
                <img className='' alt='da' style={{ width: '100%'}} src={require("../images/decisionAid/bsa6.jpeg")}/>
            </div>
            ),
            onOk() {},
        });
    }
    renderAgeWarning = () => {
        return this.state.questions[this.state.current].value === 'Less than 18' ?
        <p className="ant-typography-danger">This site is meant for individuals over the age of 18.</p>
        : ''
    }
    renderLiteStudy = () => <p><a href='https://www.thelitestudy.com' target='_blank'>View Lite Study</a></p>
    
    renderIntroText = () => {
        return this.state.current === 0 ?
        (
            <div className="borderedRow mt-1"  >
                <div style={{ margin: '0 32px'}}>
                    <Row gutter={32} className='home-row' >
                        <Col sm={{ span: 20, offset: 2}} md={{ span: 8, offset: 0}} >
                            
                            <div className="homeIconContainer">
                                <img className='homeIcon' alt='Treatment Education' width='50%' src={require("../icons/what-is-a-decision-aid.svg")}/>
                            </div>
                            <h4 className='text-center mt-1 daInfoBorder'>What is a Decision Aid?</h4>
                            <Paragraph><Text strong>A PATIENT DECISION AID</Text>... is a tool for people to talk with their doctor about medication and treatments to help manage your health.</Paragraph>
                        </Col>
                        <Col sm={{ span: 20, offset: 2}} md={{ span: 8, offset: 0}} >
                            
                            <div className="homeIconContainer">
                                <img className='homeIcon' alt='Decision Aid' width='50%' src={require("../icons/what-can-it-do-for-me.svg")}/>
                            </div>
                            <h4 className='text-center mt-1 daInfoBorder'>What can it do for me?</h4>
                            <Paragraph><Text strong>DECISION AIDS DO NOT REPLACE</Text>... your doctor. It’s a tool to help you reach a healthcare decision <Text underline>together</Text>.</Paragraph>
                        </Col>
                        <Col sm={{ span: 20, offset: 2}} md={{ span: 8, offset: 0}} >
                            
                            <div className="homeIconContainer">
                                <img className='homeIcon' alt='Words to Know' width='50%' src={require("../icons/different-treatments.svg")}/>
                            </div>
                            <h4 className='text-center mt-1 daInfoBorder'>Are you curious about treatments?</h4>
                            <Paragraph><Text strong>THIS DECISION AID PROVIDES</Text>... recommendations about treatments for psoriasis and psoriatic arthritis. This information will help you understand your options so you can talk to your doctor about a plan that fits you.</Paragraph>
                        </Col>
                    </Row>
                </div>
                </div>
        ): ''
    }

    introVideo = () => {
        return this.state.current === 0 ? (
            <div className={'wrapper '} >
                <div className={'videoWrapper'}>
                <iframe src="https://player.vimeo.com/video/393351517" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        ) : null;
    }

    render(){
        //console.log(this.state);
        return (
            <div key={this.props.mook} className="container mt-1 decisionAid" > 
                <Row>
                    <Col>
                        <h1 className="">WELCOME TO THE DECISION AID</h1>
                    </Col>
                </Row>
                {this.introVideo()}
                
                <Row>
                    <Col id='da' md={{ span: 16, offset: 4}} lg={{ span: 16, offset: 4}} className="mt-2">
                    <Element name="test1" >
                    <Progress strokeColor={'#7cc06a'} percent={this.setProgress()} status="active" />
                    </Element>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 18, offset: 3}} lg={{ span: 16, offset: 4}} >
                        { this.state.loading ? 
                            <Card className="center mt-2 p-2" hoverable bordered>
                        <div className="spinnerWrap"><Spin size="large" tip="Loading..." /></div>
                        </Card>
                         : this.renderCard() 
                         }
                    </Col>
                </Row>
                {this.renderIntroText()}
            </div>
        )
    }
}

export default withRouter(Decision);
