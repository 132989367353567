import React, { Component } from 'react';
import PageTitle from './common/PageTitle';
import {
    Form, Input, Button, message
} from 'antd';
import Recaptcha from 'react-recaptcha';
import Api from '../api';
const success = () => message.success('Contact message sent.');
const error = () => message.error('There was an error with the contact form.');
// psapso.decisionaid@gmail.com

let recaptchaInstance;

const { TextArea } = Input;

class Contact extends Component {
    constructor(props){
        super(props);
        //this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        submitted: false
    };

    callback = () => {
        console.log('Done!');
    };
    verifyCallback = (response) => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values);
                // submit to api
                const endpoint = 'contact';
                Api.postData(endpoint, values, (res) => {
                    success();
                    this.setState({ submitted: true });
                },
                    () => error()
                );
            } else {
                this.resetRecaptcha();
            }
        });
    };
    executeCaptcha = () => recaptchaInstance.execute();
    resetRecaptcha = () => recaptchaInstance.reset();

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            id: 'contactForm'
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 10,
                },
            },
        };

        return (
            <div>
            <PageTitle title={'Contact Us'} subTitle={``} />
            
            <div className="container800 center mt-2">
            { !this.state.submitted ? 
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item
                    label="Name"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Please input your name.', whitespace: true }]
                    })(
                        <Input type="text" />
                    )}
                </Form.Item>
                
                <Form.Item
                    label="E-mail"
                >
                    {getFieldDecorator('email', {
                        rules: [{
                            type: 'email', message: 'The input is not valid E-mail!',
                        }, {
                            required: true, message: 'Please input your E-mail!',
                        }],
                    })(
                        <Input />
                    )}
                </Form.Item>

                <Form.Item
                    label="Description"
                >
                    {getFieldDecorator('description', {
                        rules: [{ required: true, message: 'Please input your reason for contacting us.', whitespace: true }],
                    })(
                        <TextArea rows={4} />
                    )}
                </Form.Item>
                
                <Form.Item {...tailFormItemLayout}>
                        
                </Form.Item>
                <div className="mt-2 text-center">
                    {/* <Button type="primary" htmlType="submit">Submit</Button> */}
                    <Button size={'large'} onClick={this.executeCaptcha}>Submit</Button>
                    <Recaptcha
                        ref={e => recaptchaInstance = e}
                        sitekey="6LfcKZsUAAAAAOMZ4Mc6p5f1zi5kZ2jPikGX50no"
                        size="invisible"
                        verifyCallback={this.verifyCallback}
                    />
                    
                    </div>
            </Form>
            : <h4>Thank you for contacting us. We will get back to you after review.</h4>
            }
            </div>
            </div>
        );
    }
}

const WrappedRegistrationForm = Form.create({ name: 'register' })(Contact);
export default WrappedRegistrationForm;