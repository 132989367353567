const tnfInhibitors = ['Etanercept', 'Enbrel', 'Adalimumab', 'Humira', 'Infliximab', 'Remicade', 'Certolizumab Pegol', 'Cimzia', 'Golimumab', 'Simponi'];
const oralSystemics = ['Leflunomide', 'Arava', 'Methotrexate', 'Rheumatrix', 'Sulfasalazine', 'Azulfidine', 'Azathioprine', 'Imuran', 'Hydroxychloroquine', 'Plaquenil', 'Mycophenolate mofetil', 'CellCept'];
const IL1223 = ['Ustekinumab', 'Stelara', 'Guselkumab', 'Tremfya'];
const IL17 = ['Secukinumab', 'Cosentyx', 'Brodalumab', 'Siliq', 'Ixekizumab', 'Taltz'];
const tCellInhibitor = ['Abatacept', 'Orencia'];
const PDE4 = ['Apremilast', 'Otezia'];
const JAK = ['Tofacitinib', 'Xeljanz']; //xeljanz



const morbidities = [
    { morbidity: 'Chronic obstructive pulmonary disease', remove: ['abatacept'], NSAIDs: 'C', CS: '?', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'NI', Adalimumab: 'NI', Infliximab: 'NI', CZP: 'NI', Golimumab: 'NI', Ustekinumab: 'NI', Apremilast: 'NI' },
    { morbidity: 'Congestive heart failure', remove: tnfInhibitors,  NSAIDs: 'C', CS: 'C', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'C', Adalimumab: 'C', Infliximab: 'C', CZP: 'C', Golimumab: 'C', Ustekinumab: 'NI', Apremilast: 'NI' },
    { morbidity: 'Prior history of venous thrombotic embolism (blood clots in your leg)', remove: JAK, NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Current heavy alcohol intake', remove: ['methotrexate, arava'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Alcoholic hepatitis', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Depression', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'NI', Adalimumab: 'NI', Infliximab: 'NI', CZP: 'NI', Golimumab: 'NI', Ustekinumab: 'NI', Apremilast: '?' },
    { morbidity: 'Fatigue + Mobility', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Cirrhosis', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Liver disease', remove: ['abatacept'], NSAIDs: 'C', CS: 'NI', HCQ: 'NI', SSZ: 'C', MTX: 'C', LEF: 'C', CSA: 'NI', Etan: 'NI', Adalimumab: 'NI', Infliximab: 'NI', CZP: 'NI', Golimumab: 'NI', Ustekinumab: 'NI', Apremilast: 'NI' },
    { morbidity: 'Heart failure', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Hypercholesterolemia', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Tuberculosis', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Recurrent vaginal yeast infection', NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'C', Adalimumab: 'C', Infliximab: 'C', CZP: 'C', Golimumab: 'C', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Crohn\'s disease', remove: ['abatacept'], NSAIDs: '?', CS: 'NI', HCQ: 'NI', SSZ: 'A', MTX: 'OL', LEF: 'NI', CSA: 'NI', Etan: 'NI', Adalimumab: 'A', Infliximab: 'A', CZP: 'A', Golimumab: 'NI', Ustekinumab: 'NI', Apremilast: 'NI' },
    { morbidity: 'Ulcerative colitis', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Kidney disease', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Malignancy within 5 years', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Mouth ulcers', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Multiple sclerosis', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Obesity', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'C', LEF: 'NI', CSA: 'NI', Etan: 'NI', Adalimumab: 'NI', Infliximab: 'NI', CZP: 'NI', Golimumab: 'NI', Ustekinumab: 'NI', Apremilast: 'NI' },
    { morbidity: 'Pain', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Pregnancy/Family planning', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Uveitis', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    //{ morbidity: 'Latex allergy', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Hospitalized for infection for past year', remove: ['abatacept'], NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },

    //{ morbidity: 'Metabolic syndrome', NSAIDs: 'NI', CS: 'C', HCQ: 'NI', SSZ: 'NI', MTX: 'C', LEF: 'NI', CSA: 'NI', Etan: 'NI', Adalimumab: 'NI', Infliximab: 'NI', CZP: 'NI', Golimumab: 'NI', Ustekinumab: 'NI', Apremilast: 'NI' },
    //{ morbidity: 'Diabetes', NSAIDs: 'NI', CS: 'C', HCQ: 'NI', SSZ: 'NI', MTX: 'C', LEF: 'NI', CSA: 'NI', Etan: 'NI', Adalimumab: 'NI', Infliximab: 'NI', CZP: 'NI', Golimumab: 'NI', Ustekinumab: 'NI', Apremilast: 'NI' },
    //{ morbidity: 'Osteoporosis', NSAIDs: 'NI', CS: 'C', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'NI', Adalimumab: 'NI', Infliximab: 'NI', CZP: 'NI', Golimumab: 'NI', Ustekinumab: 'NI', Apremilast: 'NI' },
    //{ morbidity: 'Malignancy', NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'C', Adalimumab: 'C', Infliximab: 'C', CZP: 'C', Golimumab: 'C', Ustekinumab: '?', Apremilast: 'NI' },
    //{ morbidity: 'Chronic kidney disease', NSAIDs: 'C', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'C', LEF: '?', CSA: 'SM', Etan: 'NI', Adalimumab: 'NI', Infliximab: 'NI', CZP: 'NI', Golimumab: 'NI', Ustekinumab: 'NI', Apremilast: 'NI' },
    //{ morbidity: 'Chronic hepatitis B', NSAIDs: 'C', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'C', LEF: 'C', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    //{ morbidity: 'Chronic hepatitis C', NSAIDs: 'C', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'C', LEF: 'C', CSA: 'NI', Etan: '?/P', Adalimumab: '?', Infliximab: '?', CZP: '?', Golimumab: '?', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'HIV', NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    //{ morbidity: 'Hypertension', NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    { morbidity: 'Non-melanoma skin cancers', NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    //{ morbidity: 'Thrombic embolism', NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
    //{ morbidity: 'Hospitalized for infection for past year', NSAIDs: 'NI', CS: 'NI', HCQ: 'NI', SSZ: 'NI', MTX: 'NI', LEF: 'NI', CSA: 'NI', Etan: 'SM', Adalimumab: 'SM', Infliximab: 'SM', CZP: 'SM', Golimumab: 'SM', Ustekinumab: '?', Apremilast: 'NI' },
]

export default morbidities;