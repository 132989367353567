import React, { Component } from 'react';
import { 
    Slider, Button, Row, Col, Radio, Icon, Card 
} from 'antd';
import PageTitle from './common/PageTitle';
const RadioGroup = Radio.Group;

const rapid3QAnswers = ['Without any difficulty', 'With some difficulty', 'With much difficulty', 'Unable to do'];
const rapid3Marks = { 0: 0, 0.5:0.5, 1.0:1.0, 1.5:1.5, 2.0:2.0, 2.5:2.5, 3.0:3.0, 3.5:3.5, 4.0:4.0, 4.5:4.5,
    5.0:5.0, 5.5:5.5, 6.0:6.0, 6.5: 6.5, 7.0:7.0, 7.5:7.5, 8.0:8.0, 8.5:8.5, 9.0:9.0, 9.5:9.5, 10:10 };
const rapid3 = [
    { id: 'a', question: 'Dress yourself, including tying shoelaces and doing buttons?', type: 'radio', answers: rapid3QAnswers },
    { id: 'b', question: 'Get in and out of bed?', type: 'radio', answers: rapid3QAnswers },
    { id: 'c', question: 'Lift a full cup or glass to your mouth?', type: 'radio', answers: rapid3QAnswers },
    { id: 'd', question: 'Walk outdoors on flat ground?', type: 'radio', answers: rapid3QAnswers },
    { id: 'e', question: 'Wash and dry your entire body?', type: 'radio', answers: rapid3QAnswers },
    { id: 'f', question: 'Bend down to pick up clothing from the floor?', type: 'radio', answers: rapid3QAnswers },
    { id: 'g', question: 'Turn regular faucets on and off?', type: 'radio', answers: rapid3QAnswers },
    { id: 'h', question: 'Get in and out of a car, bus, train, or airplane?', type: 'radio', answers: rapid3QAnswers },
    { id: 'i', question: 'Walk two miles or three kilometers, if you wish?', type: 'radio', answers: rapid3QAnswers },
    { id: 'j', question: 'Participate in recreational activities and sports as you would like, if you wish?', type: 'radio', answers: rapid3QAnswers },
    { id: 'k', question: 'Get a good night’s sleep?', type: 'radio', answers: rapid3QAnswers },
    { id: 'l', question: 'Deal with feelings of anxiety or being nervous?', type: 'radio', answers: rapid3QAnswers },
    { id: 'm', question: 'Deal with feelings of depression or feeling blue?', type: 'radio', answers: rapid3QAnswers },
    { id: 'pain', question: 'How much pain have you had because of your condition OVER THE PAST WEEK? Please indicate below how severe your pain has been:', type: 'slider', answers: rapid3QAnswers },
    { id: 'wellBeing', question: 'Considering all the ways in which illness and health conditions may affect you at this time, please indicate below how you are doing:', type: 'slider', answers: rapid3QAnswers },
];

class Rapid3 extends Component {
    constructor(props){
        super(props);
        this.state = {
            rapid3Answers: { a: '', b: '', c: '', d: '', e: '', f: '', g: '', h: '', i: '', j: '', k: '', l: '', m: '', pain: 0, wellBeing: 0 },
            submitted: false
        }
    }

    onR3ChangeRadio = (e) => {
        let rapid3Answers = this.state.rapid3Answers;
        rapid3Answers[e.target.name] = e.target.value;
        this.setState({ rapid3Answers });
    }

    onSliderChange = (value, id) => {
        let rapid3Answers = this.state.rapid3Answers;
        rapid3Answers[id] = value;
        //console.log('change', id, value)
        this.setState({ rapid3Answers });
    }

    showTherapy = () => {
        localStorage.setItem('rapid3', JSON.stringify(this.state.rapid3Answers));
        this.setState({
            rapid3Answers: { a: '', b: '', c: '', d: '', e: '', f: '', g: '', h: '', i: '', j: '', k: '', l: '', m: '', pain: 0, wellBeing: 0 },

        }, () => {
            this.props.history.push(`/decision/result`);
        });
    }
    
    getRapid3Score = () => {
        const scores = {
            'Without any difficulty': 0,
            'With some difficulty': 1,
            'With much difficulty': 2,
            'Unable to do': 3,
        };
        
        let total1 = 0;
        let grandTotal = 0;
        let i = 0;
        for (var key in this.state.rapid3Answers) {
            if (this.state.rapid3Answers.hasOwnProperty(key)) {
                let val = scores[this.state.rapid3Answers[key]];
                if(i < 10 && val){
                    total1 += val;
                }
                if(this.state.rapid3Answers[key] && (key === 'pain' || key === 'wellBeing')) {
                    grandTotal += this.state.rapid3Answers[key];
                }
                
            }
            i++;
        }
        let tots = grandTotal;
        grandTotal = total1/3 + grandTotal;
        let text = '';
        switch (true) {
            case (grandTotal <= 3):
                text = `Near remission`;
                break;
            case (grandTotal >= 4 && grandTotal <= 6):
                text = `Low severity`;
                break;
            case (grandTotal >= 7 && grandTotal <= 12):
                text = `Moderate severity`;
                break;
            case (grandTotal >= 13 && grandTotal <= 30):
                text = `High severity`;
                break;
            
            default:
                text = '';
                break;
        }
        grandTotal = Math.round( (tots + total1/3) * 10 ) / 10; 

        return (
            <div className='mt-1'>
                <Row>
                    <Col>
                        <p>Psoriatic Arthritis Quatity of life (RAPID3) Score: {grandTotal}<br/>{text}</p>
                        <Button size={'large'} className="right mt-2" type="primary" onClick={this.resetForm}>
                        Take the questionnaire again
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }

    renderRapid3 = () => {
        return (
            <div>
            <h4>Below are a list of questions about how your psoriatic arthritis affects your life which will produce a score that may be helpful in the discussion about therapies with your provider. This validated questionnaire is called RAPID3.</h4>
               {
                rapid3.map(r => {
                    //console.log(r);
                    //return r.question;
                    //let id = r.id;
                    return r.type === 'radio' ? (
                        <div key={r.id}>
                            <p style={{marginBottom: 0}}>{r.id}. {r.question}</p>
                        
                        <RadioGroup className={'mb-1 mt-0'} name={r.id} onChange={this.onR3ChangeRadio} buttonStyle='solid' size='large' value={this.state.rapid3Answers[r.id]} >
                        {
                            r.answers.map( (r2, i) => {
                                return <Radio className='verticalRadio ml-1' key={i} value={r2}>{r2}</Radio>
                            })
                        }
                        </RadioGroup>
                        </div>
                    ) :
                    (
                        <div className={'mb-1'} key={r.id}>
                            <p style={{marginBottom: 0}}>{r.question}</p>
                            
                            <Slider min={0} max={10} step={0.5} onChange={(value) => this.onSliderChange(value, r.id)} name={r.id} marks={rapid3Marks} value={this.state.rapid3Answers[r.id]} defaultValue={0} />
                            {
                                r.question.indexOf('pain') > -1 ? <div><span className='left'>0 = No Pain</span> <span className='right'>10 = Terrible Pain</span></div> : 
                                <div><span className='left'>0 = Very Well</span> <span className='right'>10 = Very Poorly</span></div>
                            }
                            <div className='clearfix'></div>
                        </div>
                    )
                })
               } 
            </div>
        )
        
    }
    showResults = () => {
        this.setState({ submitted: true });
    }
    resetForm = () => {
        this.setState({
            rapid3Answers: { a: '', b: '', c: '', d: '', e: '', f: '', g: '', h: '', i: '', j: '', k: '', l: '', m: '', pain: 0, wellBeing: 0 },
            submitted: false
        });
    }
    
    render(){ 
        return (
            <div >
                <PageTitle title={'Rapid 3'} subTitle={``} />
                <div  className="container800 center mt-2">
                <Card className="center mt-2 daCard" hoverable bordered>
                    <Row>
                        <Col>
                            {!this.state.submitted && this.renderRapid3()}
                            {!this.state.submitted && <Button size={'large'} className="right mt-2" type="primary" onClick={this.showResults}>
                                Submit<Icon type="right" />
                            </Button>}
                        </Col>
                    </Row>
                    {this.state.submitted && this.getRapid3Score()}
                    </Card>
                </div>
            </div>
        )
    }

}

export default Rapid3;
