import React, { Component } from 'react';
import { Input, AutoComplete, Button, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
const Search = Input.Search;
const dataSource = ['Psoriasis', 'Treatment',];

const pages = [
    { name: 'FAQ', path: '/faq' },
    { name: 'Decision Aid Results', path: '/decision/result' },
    { name: 'Decision Aid', path: '/decision' },
    { name: 'Overview', path: '/overview' },
    { name: 'Treatment Education', path: '/TreatmentEdu' },
    { name: 'Advocacy', path: '/Advocacy' },
    { name: 'Words To Know', path: '/WordsToKnow' },
    { name: 'Rapid3', path: '/Rapid3' },
    { name: 'DLQI', path: '/DLQI' },
    { name: 'Contact', path: '/Contact' },
    { name: 'Health Tips', path: '/HealthTips' },
    { name: 'Practical Tips', path: '/PracticalTips' },
    { name: 'Psoriasis - Topical', path: '/Treatments/Psoriasis/Topical' },
    { name: 'Psoriasis - Phototherapy', path: '/Treatments/Psoriasis/Phototherapy' },
    { name: 'Psoriasis - Oral Systemic', path: '/Treatments/Psoriasis/Oral Systemic' },
    { name: 'Psoriasis - Biologic', path: '/Treatments/Psoriasis/Biologic' },
    { name: 'Psoriatic Arthritis - Anti-inflammation', path: '/Treatments/psoriaticArthritis/Anti-inflammation' },
    { name: 'Psoriatic Arthritis - Oral Systemic', path: '/Treatments/psoriaticArthritis/Oral Systemic' },
    { name: 'Psoriatic Arthritis - Biologic', path: '/Treatments/psoriaticArthritis/Biologic' },
    { name: 'Psoriatic Arthritis - Other', path: '/Treatments/psoriaticArthritis/Other' },
];
let data = pages.map(x => x.name).sort();

class CustomAuto extends Component {
    constructor(props){
        super(props);
        this.state = {
            search: '',
            data: data,
        }
    }
    getMenuStyle = (mode) => {
        return { paddingLeft: mode === 'vertical' ? '16px' : '0' }
      }
    onSelect = (value, option) => {
        if(value){
            let result = data.find(x => x.toLowerCase().indexOf(value.toLowerCase()) > -1);
            if(result){
                this.props.history.push(pages.find(p => p.name === result).path);
                this.setState({ search: ''});
            }
        }
    }
    onChange = value => this.setState({ search: value }, this.filterSearchResults);
    filterSearchResults = () => {
        var x = this.state.search ? data.filter(x => x.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) : data;
        return x;
    }

    render() {
        console.log('auto search');
        return (
            <div style={this.getMenuStyle(this.props.mode)}>
            <div className="global-search-wrapper" style={{ paddingTop: 10 }}>  
                <AutoComplete 
                    value={this.state.search} 
                    dataSource={this.filterSearchResults()} 
                    onSelect={this.onSelect} 
                    onSearch={this.onChange}
                    //onChange={this.filterSearchResults}
                >
                    <Input  suffix={( <Icon type="search" /> )} />
                </AutoComplete>
            </div>
            </div>
        )
    }
}

export default withRouter(CustomAuto);