import React, { Component } from 'react';
import { Row, Col } from 'antd';
//import BreadcrumbMaker from './common/BreadcrumbMaker';


//const { Paragraph, Text } = Typography;

class Acknowledgement extends Component {
    state = {}
    
    render() {
        return (
            <div className="container800 center mt-2">
                <Row>
                    <Col>
                        <h1 className="pageTitle">Acknowledgement</h1>
                    </Col>
                </Row>
                <Row className="acknowledgement">
                    <Col>
                        <p>
                            <em>
                                Thank you to Dr. Rima Rudd, Senior Lecturer on Society, Human Development and Health at the Harvard School of Public Health for permission to reproduce and modify the information in the “Plain Talk About Arthritis and Keys” booklet for the purposes of this decision aid.
                        </em>
                        </p>

                        <dl>
                            <dt>We would like to acknowledge the involvement of the following people in the production of the original booklet, <strong><em>“Plain Talk About Arthritis and Key Words”;</em></strong></dt>
                            <dd>Rima E. Rudd, ScD<br />
                                Emily K. Zobel, ScM,<br />
                                Victoria Gall, PT, MEd<br />
                                Simha Ravven, AB <br />
                                Lawren H. Daltroy, DrPH (1948–2003)</dd>
                            <dt><em>Contributors:</em></dt>
                            <dd>Lynn Rasmussen, EdM, MSc<br />
                                Jennie B. Epstein, BA<br />
                            </dd>
                            <dt><em>Reviewers:</em></dt>
                            <dd>Nancy Shadick, MD, MPH<br />
                                Elizabeth Benito-Garcia, MD, MPH<br />
                                Victoria Purcell-Gates, EdD<br />
                            </dd>
                            <dt><em>Designer:</em></dt>
                            <dd>Suzi Wojdyslawski</dd>
                            <dt><em>Layout and Printing:</em></dt>
                            <dd>Puritan Press, Inc.</dd>
                        </dl>
                        <p>Glossary terms from:
<a href='https://cdn1.sph.harvard.edu/wp-content/uploads/sites/135/2012/09/arthritisglossary.pdf' target='_blank' rel="noopener noreferrer">https://cdn1.sph.harvard.edu/wp-content/uploads/sites/135/2012/09/arthritisglossary.pdf</a>
                        </p>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Acknowledgement;