import React, { Component } from 'react';
import { Row, Col, Table, Input, Button, Icon, AutoComplete } from 'antd';
import Highlighter from 'react-highlight-words';

import Morbidites from '../assets/Morbidities';

class Morbidity extends Component {
    constructor(props){
        super(props);
        this.onSelect = this.onSelect.bind(this);
        this.handleASearch = this.handleASearch.bind(this);
    }
    state = {
        searchText: '',
        m: Morbidites,
        a: Morbidites.map(x => {return x.morbidity}),
        onlym: Morbidites.map(x => {return x.morbidity}),
        fm: [],
    };

    getColumns = () => {
        const keys = Object.keys(Morbidites[0]);
        let columns = [];
        keys.forEach(el => {
            el === 'morbidity' ? columns.push({ title: (
                <div style={{ textAlign: 'center' }}>
                    {/* <Input
                    ref={ele => this.searchInput = ele}
                    placeholder="Search"
                    value={this.state.searchText}
                    onChange={this.onInputChange}
                    onPressEnter={this.onSearch}
                /> */}
                <AutoComplete 
                dataSource={this.state.onlym}
                style={{ width: 200 }}
                onSelect={this.onSelect}
                onSearch={this.handleASearch}
                placeholder="Search"
                />
                </div>
            ), dataIndex: el, key: el, ...this.getColumnSearchProps('morbidity')}) : columns.push({ title: el, dataIndex: el, key: el });
        });
        return columns;
    }
    onInputChange = (e) => {
        console.log('change');
        this.setState({ m: Morbidites.filter(m => m.morbidity.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ? m : '' )})
        this.setState({ searchText: e.target.value });
    }
    onSelect(value) {
        console.log('onSelect', value);
        this.setState({ m: Morbidites.filter(m => m.morbidity.toLowerCase().indexOf(value.toLowerCase()) > -1 ? m : '' )})
        this.setState({ searchText: value });
      }
    handleASearch = (value) => {
        this.setState({ onlym: Morbidites.map(x => {return x.morbidity}).filter(m => m.toLowerCase().indexOf(value.toLowerCase()) > -1 ? m : '' )})
    }
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    }

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: '' });
    }
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
          setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => { this.searchInput = node; }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: (text) => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ),
      })

    render(){
        console.log(this.state);
    return (
        <div className="center mt-2">
        <Row>
            <Col >
            <h1 className="pageTitle">Morbidites</h1>
            </Col>
        </Row>
        <Row>
            <Col >
            
            <Table 
                columns={this.getColumns()} 
                dataSource={this.state.m} 
                size="small" 
                pagination={{defaultPageSize:20, hideOnSinglePage: true}} 

            />
            </Col>
        </Row>
        
        </div>
    )
    }
}

export default Morbidity;
