import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, message } from 'antd';
import Recaptcha from 'react-recaptcha';

import PageTitle from './common/PageTitle';
import Api from '../api';
const success = () => message.success('Successfully updated.');
const error = () => message.error('There was an error updating this item.');


let recaptchaInstance;

class Login extends Component {
    handleSubmit = e => {
      e.preventDefault();
    };
    callback = () => {
        console.log('Done!!!!');
    };
    verifyCallback = (response) => {
        console.log(response);
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                // submit to api
                const endpoint = 'login';
                Api.postData(endpoint, values, (res) => {
                    success();
                   
                },
                    () => error()
                );
            } else {
                this.resetRecaptcha();
            }
        });
    };
    executeCaptcha = () => {
        console.log('execute');
        recaptchaInstance.execute();
    };
    resetRecaptcha = () => {
        recaptchaInstance.reset();
    };
  
    render() {
      const { getFieldDecorator } = this.props.form;
      return (
          <div>
        <PageTitle title={'Login'} subTitle={``} />
        <div  className="container800 center mt-2">
        <Row>
            <Col>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'Please input your username.' }],
            })(
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Username"
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please input your Password.' }],
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Password"
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox>Remember me</Checkbox>)}
            <br/><a className="login-form-forgot" href="">
              Forgot password
            </a><br/>
            <Button type="primary" onClick={this.executeCaptcha}>
              Log in
            </Button><br/>
            Or <a href="">register now!</a>
          </Form.Item>
        </Form>
        <Recaptcha
            ref={e => recaptchaInstance = e}
            sitekey="6LfcKZsUAAAAAOMZ4Mc6p5f1zi5kZ2jPikGX50no"
            //render="explicit"
            size="invisible"
            //onloadCallback={this.callback}
            verifyCallback={this.verifyCallback}
            
        />
        </Col>
        </Row>
        </div>
        </div>
      );
    }
  }
  
  const WrappedLogin = Form.create({ name: 'normal_login' })(Login);
  export default WrappedLogin;