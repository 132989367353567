import React, { Component } from 'react';
import { Row, Col, Button, Layout, Carousel } from 'antd';
import Background from '../images/dermatologistCream.jpg';
import { withRouter } from 'react-router-dom'

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            register: 1,
            loginModal: false,
            email: "",
            password: "",
        }
    }

    renderCarousel = () => {
        return (
            <Carousel >
                <div>
                    <Col span={24} style={{
                        height: '500px', marginBottom: '50px', backgroundImage: `url(${Background})`, backgroundSize: 'cover',
                        overflow: 'hidden',
                    }}>
                        <div className='overlay'>
                            <div className='heroTextContainer'>
                                <img className='fullLogo' alt='PIAP Logo' src={require("../images/logo/color-logo-full.svg")} />
                            </div>
                        </div>
                    </Col>
                </div>
            </Carousel>
        );
    }

    render() {
        return (
            <Layout>
                {this.renderCarousel()}
                <div className={'wrapper '} >
                    <div className={'videoWrapper mt-1'}>
                        <iframe title="How to use this site" src="https://player.vimeo.com/video/393351525" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </div>
                </div>
                <div style={{ margin: '16px 32px' }} >
                    <Row gutter={32} className='home-row' type={'flex'}>
                        <Col sm={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }} >
                            <div className='homeInfoContainer '>
                                <div className='homeInfoContainer border-white homeInfo'>
                                    <h2>What is Psoriasis?</h2>
                                    <p>Psoriasis is a chronic autoimmune condition that causes the rapid buildup of skin cells. This buildup of cells causes scaling on the skin's surface. Inflammation and redness around the scales is fairly common. Typical psoriatic scales are whitish-silver and develop in thick, red patches.</p>
                                </div>
                            </div>
                        </Col>

                        <Col sm={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }} >
                            <div className='homeInfoContainer '>
                                <div className='homeInfoContainer border-white homeInfo'>
                                    <h2>What is Psoriatic Arthritis?</h2>
                                    <p>Psoriatic arthritis is a form of arthritis that affects some people who have psoriasis. Most people develop psoriasis first and are later diagnosed with psoriatic arthritis, but the joint problems can sometimes begin before skin lesions appear.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>


                {/* <div style={{ margin: '0 32px' }} >
                    <Row gutter={32} className='home-row' type={'flex'}>
                        <Col sm={{ span: 20, offset: 2 }} md={{ span: 8, offset: 0 }} >
                            <div className='col-flex'>
                                <h2 className='text-center mt-1'>Treatments</h2>
                                <div className="homeIconContainer">
                                    <img onClick={() => this.props.history.push('/treatmentedu')} className='homeIcon pointer' alt='Treatment Education' width='50%' src={require("../icons/bandage.svg")} />
                                </div>
                                <p>Learn more about specific drugs and the treatment involved.</p>
                                <Button size={'large'} type={'primary'} onClick={() => this.props.history.push('/treatmentedu')} block>Learn About Treatment</Button>
                            </div>

                        </Col>
                        <Col sm={{ span: 20, offset: 2 }} md={{ span: 8, offset: 0 }} >
                            <div className='col-flex'>
                                <h2 className='text-center mt-1'>Decision Aid</h2>
                                <div className="homeIconContainer">
                                    <img onClick={() => this.props.history.push('/decision')} className='homeIcon pointer' alt='Decision Aid' width='50%' src={require("../icons/decisionAid.svg")} />
                                </div>
                                <p>Determine the best options for treating your Psoriasis or Psoriatic Arthritis</p>
                                <Button size={'large'} type={'primary'} onClick={() => this.props.history.push('/decision')} block>Find a treatment option</Button>
                            </div>
                        </Col>
                        <Col sm={{ span: 20, offset: 2 }} md={{ span: 8, offset: 0 }} >
                            <div className='col-flex'>
                                <h2 className='text-center mt-1'>Words to Know</h2>
                                <div className="homeIconContainer">
                                    <img onClick={() => this.props.history.push('/wordstoknow')} className='homeIcon pointer' alt='Words to Know' width='50%' src={require("../icons/question.svg")} />
                                </div>
                                <p>There are many terms to learn. Get acquainted with the topic</p>
                                <Button size={'large'} type={'primary'} onClick={() => this.props.history.push('/wordstoknow')} block>Learn More</Button>
                            </div>
                        </Col>
                    </Row>
                </div> */}

            </Layout>
        );
    }
};

export default withRouter(Home);
